import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { GET_CLOCK_FORMAT } from '@shared';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'date' })
export class ExtendedDatePipe extends DatePipe implements PipeTransform {
  readonly customFormats24 = {
    shortTime: 'H:mm',
    short: 'M/d/yy, H:mm',
    medium: 'MMM d, y, H:mm:ss',
    long: 'MMMM d, y, H:mm:ss z',
    full: 'EEEE, MMMM d, y, H:mm:ss zzzz',
    shortDate: 'M/d/yy',
    mediumDate: 'MMM d, y',
    longDate: 'MMMM d, y',
    fullDate: 'EEEE, MMMM d, y',
    mediumTime: 'H:mm:ss',
    longTime: 'H:mm:ss z',
    fullTime: 'H:mm:ss zzzz',
  };

  constructor(
    @Inject(LOCALE_ID) locale: string,
    @Inject(GET_CLOCK_FORMAT) private clockFormat: () => 12 | 24,
  ) {
    super(locale);
  }

  transform(value: null | undefined | Date | string | number, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number, format = 'mediumDate', timezone?: string, locale?: string): string {
    if (!value) { return ''; }
    if (value instanceof Date && isNaN(value.getTime())) { return ''; }

    if (this.clockFormat() === 24) {
      format = this.customFormats24[format] || (format)?.replace(/h/g, 'H').replace(/a/g, '').trim();
    }

    return super.transform(value, format, timezone, locale);
  }
}
