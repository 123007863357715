import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { isOwner } from 'src/app/common/menu-def.provider';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class FirstLoginGuard implements CanActivate, CanActivateChild {
  releaseDate = new Date('2020-10-09T18:30:00.000Z');

  constructor(
    private store: Store,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const company = this.store.selectSnapshot(AuthState.company);

    const isNewCompany = new Date(company.companyCreatedAt) > this.releaseDate;
    if (isNewCompany && isOwner(company) && !company?.companySettings?.custom?.completedOnBoarding) {
      if (company?.splitTest?.some(st => st.name === 'icp1' && st.value)) {
        return this.router.createUrlTree(['/onboarding2'], {queryParams: route.queryParams});
      }

      return this.router.createUrlTree(['/onboarding'], { queryParams: route.queryParams });
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }
}
