import { ScrollingModule } from '@angular/cdk/scrolling';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, DomSanitizer, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  API_URL,
  APP_NAME,
  APP_URL,
  DATA_INTEGRITY_APIKEY,
  DATA_INTEGRITY_APIURL,
  DEFAULT_API_VERSION,
  defaultIndexedDbServiceProvider,
  exposeStoreInitProvider,
  GET_CLOCK_FORMAT,
  GOOGLE_CLIENT_ID,
  SOCKET_API_ROOT,
  SOCKET_HOST,
  SOCKET_USE_AUTH_HEADER,
  socketInitProvider,
  SocketService,
  USE_WEB_SOCKET
} from '@shared';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { menuProvider } from 'src/app/common/menu-def.provider';
import { CompanyDateAdapterModule } from 'src/app/services/company-date-adapter';
import { environment } from 'src/environments/environment';
import { ErrorComponent } from '../components/error/error.component';
import { HeaderComponent } from '../components/header/header.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { StarterSidebarComponent } from '../components/starter-sidebar/starter-sidebar.component';
import { TrialWidgetComponent } from '../components/starter-sidebar/trial-widget/trial-widget.component';
import { CommonSnackBarComponent } from '../dialogs/common-snackbar/common-snack-bar';
import { ProgressDialogComponent } from '../dialogs/progress-dialog/component';
import { WarningDialogComponent } from '../dialogs/warning-dialog/warning-dialog.component';
import { socketHandlerInitProvider } from '../initializers/socket-handler.init';
import { translateInitProvider } from '../initializers/translate.init';
import { SharedModule } from '../modules/shared.module';
import { BrandingService } from '../services/branding.service';
import { sentryProviders } from '../services/error.handler';
import { httpEncoderInterceptorProvider } from '../services/http-encode-interceptor.service';
import { httpGzipInterceptorProvider } from '../services/http-gzip-interceptor.service';
import { httpInterceptorProvider } from '../services/http-interceptor.service';
import { NoopSocketService } from '../services/noop-socket.service';
import { TimeService } from '../services/time';
import { AuthState } from '../store/auth/auth.state';
import { NgxsStoreModule } from '../store/store.module';
import { AppComponent } from './app.component';
import { getAllowSocket, getApiUrl, getSocketUrl, getSocketUseAuthHeader, getUseSocketApi } from './app.constants';
import { AppRoutingModule } from './app.routing';
import { createTranslateLoader, InjectableTranslateMessageFormatCompiler } from './translate';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    HeaderComponent,
    SidenavComponent,
    WarningDialogComponent,
    ProgressDialogComponent,
    CommonSnackBarComponent,
    LoadingComponent,
    StarterSidebarComponent,
    TrialWidgetComponent,
  ],
  imports: [
    NgxsStoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSidenavModule,
    MatExpansionModule,
    CompanyDateAdapterModule,
    ScrollingModule,
    SharedModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, APP_BASE_HREF],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: InjectableTranslateMessageFormatCompiler,
      },
    }),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    { provide: APP_URL, useFactory: BrandingService.appUrlFactory, deps: [BrandingService] },
    { provide: SOCKET_API_ROOT, useValue: environment.socketApiRoot },
    { provide: SOCKET_HOST, useFactory: getSocketUrl, deps: [BrandingService] },
    { provide: API_URL, useFactory: getApiUrl, deps: [BrandingService] },
    { provide: APP_NAME, useValue: environment.appName },
    { provide: GOOGLE_CLIENT_ID, useValue: environment.googleClientId },
    { provide: DATA_INTEGRITY_APIURL, useValue: environment.dataIntegrityApiUrl },
    { provide: DATA_INTEGRITY_APIKEY, useValue: environment.dataIntegrityApiKey },
    { provide: USE_WEB_SOCKET, useFactory: getUseSocketApi },
    { provide: SOCKET_USE_AUTH_HEADER, useFactory: getSocketUseAuthHeader },
    { provide: DEFAULT_API_VERSION, useValue: environment.defaultApiVersion },
    { provide: GET_CLOCK_FORMAT, useFactory: AuthState.clockFormatFactory, deps: [Store] },
    ...sentryProviders,
    { provide: APP_INITIALIZER, useFactory: BrandingService.initialize, deps: [BrandingService], multi: true },
    ...(!environment.production ? [exposeStoreInitProvider] : []),
    translateInitProvider,
    httpInterceptorProvider,
    httpEncoderInterceptorProvider,
    httpGzipInterceptorProvider,
    defaultIndexedDbServiceProvider,
    { provide: SocketService, useClass: getAllowSocket() ? SocketService : NoopSocketService },
    socketInitProvider,
    socketHandlerInitProvider,
    { provide: APP_INITIALIZER, useFactory: () => () => null, deps: [TimeService], multi: true }, // Initialize TimeService
    menuProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    @Inject(APP_BASE_HREF) baseUrl: string,
  ) {
    matIconRegistry.addSvgIcon(
      `warning_outline`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/warning.svg`),
    );

    matIconRegistry.addSvgIcon(
      `settings_applications`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/settings_applications.svg`),
    );

    matIconRegistry.addSvgIcon(
      `delete_outline`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/delete.svg`),
    );

    matIconRegistry.addSvgIcon(
      `help_outline`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/help_outline.svg`),
    );

    matIconRegistry.addSvgIcon(
      `notifications_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/notifications_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `announcement_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/announcement_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `info_towtone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/info_towtone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `warning_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/warning_twotone.svg`),
    );

    matIconRegistry.addSvgIcon(
      `credit_card_twotone`,
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/images/common/credit_card_twotone.svg`),
    );
  }
}
