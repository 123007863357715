import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ERROR_URL } from 'src/app/app/app.constants';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class BasicPricingGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(ERROR_URL) private errorUrl,
  ) { }

  async canActivate() {
    const company = this.store.selectSnapshot(AuthState.company);

    if (company.pricingPlan?.includes('basic')) {
      return this.router.createUrlTree([this.errorUrl, 'upgrade']);
    }

    return true;
  }

  canActivateChild() {
    return this.canActivate();
  }

  canLoad() {
    return this.canActivate().then(x => x === true);
  }
}
