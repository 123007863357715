<button mat-button (click)="exportOptions()">
  <mat-icon>cloud_download</mat-icon>
  <span fxHide.lt-sm style="margin-left: 12px;" *ngIf="!hideLabel" class="export-text">
    <ng-container *ngIf="page">
      {{ 'export.button' | translate }}
    </ng-container>
    <ng-container *ngIf="!page">
      {{ 'export.export' | translate }}
    </ng-container>
  </span>
  <div class="full-cover" [matTooltip]="'export.button' | translate"></div>
</button>
