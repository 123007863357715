<ng-template #onboardingDialog>
  <div class="cc-offer">
    <mat-dialog-content fxLayout="column">
      <div class="onboarding-wrap">
        <app-onboarding-header [stepCount]="stepNumber" [progress]="progressBarCount"></app-onboarding-header>
        <shared-wizard class="wizard" #wizard [activeId]="storedConfig?.currentStep" [showArrows]="false"
          fxLayout="column" style="width: 100%; height: 100%; overflow: auto" (completed)="completeOnboarding()"
          (activeIdChange)="onStepChange($event)" [showStepper]="false">

          <ng-template [id]="companyInfo2Step" wizardStep [stepperIndex]="0" [nextCallback]="setAdditionalCompanyInfo"
            *ngIf="!isPartnerAccount">
            <div *ngIf="capterraConversion" style="width: 0px;height: 0px;">
              <img
                src="https://ct.capterra.com/capterra_tracker.gif?vid=2091278&vkey=83e9b214c0b3c9339bc704690cd14c07" />
            </div>
            <form [formGroup]="companyInfoForm2">
              <div fxLayout="column" fxLayoutAlign="stretch" class="pain-points-cont">
                <div>
                  <h3>{{ 'onboarding.companyInfo2Step.title' | translate }}</h3>
                  <h4> {{ 'onboarding.companyInfo2Step.subtitle' | translate }} </h4>
                </div>
                <mat-radio-group class="option-buttons pain-points" color="primary" formControlName="painPoint"
                  style="margin-bottom: 16px;" (change)="onStepperSelectionChange()">
                  <div fxLayout="row wrap" class="pain-points-cont">
                    <mat-radio-button *ngFor="let painpoint of painPoints;" value="{{painpoint.value}}"
                      (click)="setPainPoint()">
                      <div>
                        <div class="list-title">
                          <div class="icon-wrapper" [ngStyle]="{'background-color': painpoint.color}"><img
                              src="/assets/images/onboarding/st-{{painpoint.key}}.svg" />&nbsp;</div>
                          <div class="pinpoint">{{painpoint.value}}</div>
                        </div>
                        <div class="lists">
                          <p>
                            <mat-icon class="check-icon">check</mat-icon><span>{{ 'onboarding.companyInfo2Step.'+
                              painpoint.key +'.item1' | translate }}</span>
                          </p>
                          <p>
                            <mat-icon class="check-icon">check</mat-icon><span>{{ 'onboarding.companyInfo2Step.'+
                              painpoint.key +'.item2' | translate }}</span>
                          </p>
                          <p>
                            <mat-icon class="check-icon">check</mat-icon><span>{{ 'onboarding.companyInfo2Step.'+
                              painpoint.key +'.item3' | translate }}</span>
                          </p>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
                <mat-error *ngIf="painPointEmpty" class="onboarding-error-message">{{ 'onboarding.pleaseSelectOption' |
                  translate }}</mat-error>
              </div>
            </form>
          </ng-template>

          <ng-template [id]="companyInfoStep" wizardStep [stepperIndex]="1">
            <form [formGroup]="companyInfoForm1">
              <div fxLayout="column" fxLayoutAlign="center center" class="company-size-cont">
                <h3>{{ 'onboarding.companyInfoStep.whatsTheCompanySize' |
                  translate }}</h3>
                <mat-radio-group class="option-buttons company-size-options" color="primary"
                  formControlName="companySize" (change)="setEmptyCompanySize($event)">
                  <div fxLayout="row wrap" class="option-container">
                    <mat-radio-button value="1-25">1 to 25</mat-radio-button>
                    <mat-radio-button value="26-50">26 to 50</mat-radio-button>
                    <mat-radio-button value="51-100">51 to 100</mat-radio-button>
                    <mat-radio-button value="101-500">101 to 500</mat-radio-button>
                    <mat-radio-button value="501-1000">501 to 1000</mat-radio-button>
                    <mat-radio-button value="1001+">1001 or more</mat-radio-button>
                  </div>
                  <div fxLayout="row wrap" class="option-container-mobile">
                    <mat-radio-button value="1-25">1-25</mat-radio-button>
                    <mat-radio-button value="26-50">26-50</mat-radio-button>
                    <mat-radio-button value="51-100">51-100</mat-radio-button>
                    <mat-radio-button value="101-500">101-500</mat-radio-button>
                    <mat-radio-button value="501-1000">501-1000</mat-radio-button>
                    <mat-radio-button value="1001+">1001+</mat-radio-button>
                  </div>
                  <div fxLayoutAlign="center center">
                    <mat-error *ngIf="companySizeEmpty" class="onboarding-error-message">{{
                      'onboarding.pleaseSelectOption'
                      | translate }}</mat-error>
                  </div>
                </mat-radio-group>
              </div>
            </form>
            <app-onboarding-footer [company]="true"></app-onboarding-footer>
          </ng-template>

          <ng-template [id]="trackingModeStep" wizardStep [stepperIndex]="2">
            <div fxLayout="column" fxLayoutAlign="stretch" class="track-mode-cont">
              <h3>{{ 'onboarding.trackingModeStep.whoOwnsTheComputer' | translate }}</h3>
              <h4> {{ 'onboarding.trackingModeStep.whoOwnsDescription' | translate }} </h4>
              <mat-radio-group class="option-buttons track-mode-group" [(ngModel)]="computerOwner"
                (ngModelChange)="selectTrackMode($event, wizard)" fxLayout="row wrap">
                <mat-radio-button [trackClick]="{page: 'Ownership Selected Option', ownership: 'Company' }"
                  value="company" class="team-selection">
                  <div>
                    <div class="title">{{ 'onboarding.trackingModeStep.company' | translate }}</div>
                    <div class="sub-title">{{ 'onboarding.trackingModeStep.companyOwns' | translate }}</div>
                  </div>
                </mat-radio-button>
                <mat-radio-button [trackClick]="{ page: 'Ownership Selected Option', ownership: 'Team Members' }"
                  value="employees" class="team-selection">
                  <div>
                    <div class="title">{{ 'onboarding.trackingModeStep.teamMembers' | translate }}</div>
                    <div class="sub-title">{{ 'onboarding.trackingModeStep.teamMemberOwns' | translate }}</div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
              <div class="col-md-12"></div>
              <app-onboarding-footer [showLink]="false"></app-onboarding-footer>

            </div>
          </ng-template>
          <ng-template [id]="trackingModeStep2" wizardStep [stepperIndex]="3" [nextCallback]="setTrackingMode">
            <div fxLayout="column" fxLayoutAlign="stretch" class="track-mode-cont">
              <h3>Which tracking method suits you best?</h3>
              <h4>
                {{'onboarding.trackingModeStep.chooseHowToTrack' | translate }}</h4>
              <mat-radio-group class="option-buttons track-mode-group" fxLayout="row wrap">
                <mat-radio-button (click)="selectTrackOption('visible', wizard)"
                  [trackClick]="{ page: 'Tracking Type Selected Option', trackingtype: 'Interactive' }" value="visible"
                  class="team-selection">
                  <div>
                    <div class="title">{{ 'onboarding.trackingModeStep.visible' | translate }}</div>
                    <div class="sub-title">{{ 'onboarding.trackingModeStep.visibleDescription' | translate }}</div>
                  </div>
                </mat-radio-button>
                <mat-radio-button (click)="selectTrackOption('silent', wizard)"
                  [trackClick]="{ page: 'Tracking Type Selected Option', trackingtype: 'Silent' }" value="silent"
                  class="team-selection">
                  <div>
                    <div class="title">{{ 'onboarding.trackingModeStep.silent' | translate }}</div>
                    <div class="sub-title">{{ 'onboarding.trackingModeStep.silentDescription' | translate }}</div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
              <div class="col-md-12"></div>
              <app-onboarding-footer [showLink]="true"></app-onboarding-footer>

            </div>
          </ng-template>
          <ng-template [id]="sendInvitesStep" wizardStep [stepperIndex]="4"
            *ngIf="(trackingMode$ | async) === 'visible'">
            <div fxLayout="column" fxLayoutAlign="center center" class="invites">

              <div fxLayout="column" class="invite-main-cont" style="width: 100%">
                <form [formGroup]="inviteForm" style="width: 100%">
                  <h3 class="title-header">{{ 'onboarding.sendInvitesStep.inviteYourTeam' | translate }}</h3>
                  <h4>{{ 'onboarding.sendInvitesStep.inviteYourTeamDescription' | translate }}</h4>
                  <div class="invite-container" *ngFor="let fc of getInviteControls(); let i = index;">
                    <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap="16px">
                      <div fxFlex="50%">
                        <mat-form-field class="invite-email-field" formArrayName="emails" appearance="fill">
                          <mat-label>
                            {{ 'onboarding.sendInvitesStep.teamMemberEmail' | translate }}
                          </mat-label>
                          <input no-val-on-focus matInput name="email_{{i}}" [formControl]="fc" type="email"
                            (change)="checkDuplicateEntry()" emailField (searchValueUpdated)="ValidateEmails($event)"
                            [EmailFieldValueOutputDelay]="2000" (click)="fireEngagementTracking(i)" (paste)="fireEngagementTracking(i)" (focus)="fireEngagementTracking(i)">
                          <mat-icon matPrefix>person</mat-icon>
                          <mat-error *ngIf="fc.errors && !noEmailError">{{ 'onboarding.sendInvitesStep.emailError644' |
                            translate }}
                          </mat-error>
                          <mat-error>{{ fc.getError('serverErrorMessage') }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div fxFlex="45%" class="level-options-container">
                        <div class="access-label-wrapper">
                          <mat-label>Access level</mat-label>
                        </div>
                        <mat-button-toggle-group class="access-level-options" value="manager"
                          [formControl]="getInviteControlsByFieldName('access_level')[i]">
                          <mat-button-toggle value='admin'>{{'onboarding.sendInvitesStep.admin' | translate}}
                          </mat-button-toggle>
                          <mat-button-toggle value='manager'>{{'onboarding.sendInvitesStep.manager' | translate}}
                          </mat-button-toggle>
                          <mat-button-toggle value='user' [checked]="true">{{'onboarding.sendInvitesStep.user' |
                            translate}}
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                    </div>
                    <hr class="invitehr" *ngIf="i + 1 < getInviteControls().length ">
                  </div>

                  <div class="add-more-employees" (click)="addInviteField()" color="primary">
                    {{'onboarding.sendInvitesStep.addMoreEmployees' | translate}}
                  </div>
                  <div fxLayout="column" class="invite-skip-buttons">
                    <button [disabled]="" mat-raised-button color="primary" fxFlexAlign="center"
                      (click)="sendInvites()" class="onboarding-buttons" class="invite-button">
                      <div *ngIf="!sendingInvite">{{ 'onboarding.sendInvitesStep.inviteYourTeamB' | translate |
                        uppercase
                        }}</div>
                      <div *ngIf="sendingInvite">
                        <mat-spinner diameter="24" class="loader"></mat-spinner>
                      </div>
                    </button>
                    <div class="invite-skip-step-wrapper">
                      <span class="invite-skip-step" (click)="skipInvite()">{{ 'onboarding.sendInvitesStep.skipB' |
                        translate | uppercase }}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div style="clear:both;"></div>
            </div>
          </ng-template>
        </shared-wizard>

      </div>
    </mat-dialog-content>
  </div>

</ng-template>
