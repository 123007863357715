import { Directive, HostListener, Input, Output } from '@angular/core';
import { debounce, Subject, tap, timer } from 'rxjs';

@Directive({
  selector: '[clickTracking]',
})
export class ClickTrackingDirective {
  @Input() inputFormField = 400;

  private inputObservable$ = new Subject();

  @Output() trackField = this.inputObservable$.asObservable().pipe(
    debounce(() => timer(this.inputFormField)),
    tap(() => console.log()),
  );

  @HostListener('click', ['$event.target'])
  clicked(e: unknown) {
    this.inputObservable$.next(e);
  }
}
