import { Injectable } from '@angular/core';
import { ApiLimitParams, ApiService, PagingService } from '@shared';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Break, BreakType } from 'src/models';

export interface GetBreaksOptions extends ApiLimitParams {
  filter?: string;
  'filter[name]'?: string;
  'filter[id]'?: string;
}


@Injectable({ providedIn: 'root' })
export class BreakService {
  constructor(private api: ApiService, private paging: PagingService) { }

  async getBreak(id: string): Promise<Break> {
    const response = await lastValueFrom(this.api.request<{ data: Break }>('get', `breaks/${id}`));
    return response.data;
  }

  getBreaks(options?: GetBreaksOptions) {
    const limit = options && options.limit || 1000;
    return this.paging.getPagedResult<Break>(page =>
      this.api.request('get', `breaks`, { sort: 'name', ...options, page, limit }), limit);
  }

  createBreak(name: string, type: BreakType) {
    console.log(name, type);
    return lastValueFrom(
      this.api.request<{ data: Break }>('post', 'breaks', { name, type })
        .pipe(map(x => x.data)));
  }

  editBreak(id: string, break$: Partial<Break>) {
    return lastValueFrom(this.api.request('put', `breaks/${id}`, break$));
  }

  deleteBreak(id: string) {
    return lastValueFrom(this.api.request('delete', `breaks/${id}`));
  }

  archiveBreak(id: string) {
    return lastValueFrom(this.api.request('delete', `breaks/${id}`));
  }
}
