import { Injectable, NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, State } from '@ngxs/store';
import { NgxsSyncStorageSwitchPluginModule, storeReadyAppInitializer } from '@shared';
import { environment } from 'src/environments/environment';
import { AuthState } from './auth/auth.state';
import { LoadingState } from './loading/loading.state';
import { ManagerState } from './manager/manager.state';
import { OnboardingState } from './onboarding/onboarding.state';
import { PreferencesState } from './preferences/preferences.state';
import { ProgressState } from './progress/progress.state';
import { SharedState } from './shared/shared.state';


export function identity(x) { return x; }

// This dummy state is used to fire an update state event, which is required for app to initialize
@State<Record<string, never>>({ name: 'dummy' })
@Injectable()
export class DummyState { }

@NgModule({
  imports: [
    NgxsModule.forRoot(
      [AuthState, LoadingState, ProgressState, SharedState, PreferencesState, OnboardingState, ManagerState],
      { developmentMode: !environment.production },
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Time Doctor 2',
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [
        'auth.user', 'auth.token', 'auth.company', 'auth.usersToRemember', 'auth.adminLogin',
        'preferences', 'onboarding', 'timelineReport', 'activitySummary',
        'projectsReport', 'exportOptions', 'invite.params',
        'manageUsers.silentBannerDismissed', 'manageUsers.visibleColumns', 'editTime.timezone',
        'payroll.timezone', 'addManagers.params', 'hoursTracked', 'csv', 'connectivity',
        'schedules', 'attendance', 'webAndAppBanner', 'auth.isEuMember',
      ],
    }),
    NgxsSyncStorageSwitchPluginModule.forRoot({
      key: ['dashboard', 'productivity', 'screencasts'],
    }),
    NgxsModule.forFeature([DummyState]),
  ],
  exports: [
    NgxsModule,
  ],
  providers: [
    storeReadyAppInitializer,
  ],
})
export class NgxsStoreModule { }
