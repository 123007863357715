import { formatDate } from '@angular/common';
import { ClockPipe } from '@shared';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { timezoneMap } from 'src/app/components/timezone-select/timezones';
import { User, Worklog } from 'src/models';
import { TrackingContextType } from '../tracking/context';

export function transformTimezoneSelection(val: Partial<TrackingContextType>) {
  const timezone = timezoneMap.get(val.newValue);
  const props = {
    name: val.name,
    location: val.location,
    selectedOption: { value: timezone?.display },
    selectedTimeZone: 'UTC ' + timezone?.offsetFormat,
    selectedLocation: timezone?.name,
  };
  return props;
}

export function transformUserRadioSelection(val: Partial<TrackingContextType>) {
  let selectedUser = { name: '' };
  val.userData.subscribe(user => { selectedUser = user; });
  const props = {
    name: 'User Selection Pulldown',
    location: 'Header',
    selectedOption: { value: selectedUser.name },
  };
  return props;
}

export function transformUserLazySelection(val: Partial<TrackingContextType>) {
  const selection = val.newValue;
  const numberSelected = getNumberOfSelected(val).toString();
  const namesOfGroupSelected = selection.type === 'groups' ? getSelectedGroupNames(val) : getSelectedUsersNames(val);
  const props = {
    name: 'Group or User Selection Pulldown',
    location: 'Header',
    selectedOption: { value: selection.type[0].toUpperCase() + selection.type.substr(1) },
    numberSelected,
    namesOfGroupSelected,
  };
  return props;
}

export function getNumberOfSelected(selection: Partial<TrackingContextType>) {
  let numberSelected = null;
  if (selection.newValue.type === 'users') {
    numberSelected = selection.newValue.users.all ? 'All' : selection.newValue.users.list.length;
  } else {
    numberSelected = selection.newValue.groups.all ? 'All' : selection.newValue.groups.list.length;
  }
  return numberSelected;
}

export function getSelectedUsersNames(selection: Partial<TrackingContextType>) {
  const userNames = [];
  const userData = of(selection.userData);

  if (selection.newValue.users.all) {
    userNames.push('All Users');
  }
  let usersList = null;

  userData.subscribe(value => {
    if (value) {
      usersList = value;
    }
  });
  if (usersList) {
    for (const selectedUser of selection.newValue.users.list) {
      const user = usersList.find(u => {
        return u.id === selectedUser;
      });
      userNames.push(user.name);
    }
  }
  return userNames;
}

export function getSelectedGroupNames(selection: Partial<TrackingContextType>) {
  const groupNames = [];
  if (selection.newValue.groups.all) {
    groupNames.push('All Groups');
  } else {
    const groups = selection.newValue.allGroups || selection.allGroups;
    for (const selectedGroup of selection.newValue.groups.list) {
      const group = groups.find(g => {
        return g.id === selectedGroup;
      });
      groupNames.push(group?.name);
    }
  }
  return groupNames;
}

export function transformPeriodPickerSelection(val: Partial<TrackingContextType>) {
  const selection = val.newValue;
  const pickerType = (val.hasOwnProperty('type')) ? val.type : selection.type;
  const props = {
    location: 'Header',
  };
  if (pickerType === 'day') {
    props['selectedOption'] = { value: selection.originalSelection.toFormat('yyyy-MM-dd') };
    props['name'] = 'Selected Period Date';
  } else {
    props['name'] = 'Selected Date Range';
    props['selectedOption'] = {
      end: selection.end.toFormat('yyyy-MM-dd'),
      start: selection.start.toFormat('yyyy-MM-dd'),
    };
  }
  return props;
}

export function transformNewValue(val: Partial<TrackingContextType>) {
  if (typeof val?.newValue === 'string') {
    val['selectedOption'] = { value: val.newValue };
    delete val.newValue;
  }
  if (typeof val?.newValue === 'number') {
    val['selectedOption'] = { value: val.newValue.toString() };
    delete val.newValue;
  }
  return val;
}

export function transformHighestIdleWidget(context: Partial<TrackingContextType>) {
  const isIdle = context?.dynamicname?.includes('idle');
  const btnType = context?.btnType;
  context.name = 'Clicked Activity Summary Report';
  if (btnType !== 'link') {
    context.name = isIdle ? 'Set Minimum Idle Time Worked' : 'Set Minimum Manual and Mobile Time Worked';
  }
  context.location = isIdle ? 'Highest Idle Time Widget' : 'Highest Manual and Moblie Time Widget';
  delete context.dynamicname;
  delete context.btnType;
  return transformNewValue(context);
}

export function transformPeriodTabsSelection(val: Partial<TrackingContextType>) {
  const props = {
    location: val.location,
    name: `Selected ${val.newValue.charAt(0).toUpperCase() + val.newValue.slice(1)}`,
  };
  return props;
}

export function transformSortColumnsSelection(val: Partial<TrackingContextType>) {
  const props = {
    name: 'Sort Column',
    location: val.location,
    selectedoption: { value: `${val.sort.active} ${val.sort.direction.toUpperCase()}` },
  };
  return props;
}

export function transformTabsSelection(val: Partial<TrackingContextType>) {
  const props = {
    name: 'Selected Tab',
    location: val.location,
    selectedOption: { value: `${val.tab.charAt(0).toUpperCase()} ${val.tab.slice(1)}` },
  };
  return props;
}

export function transformStackChartSelection(val: Partial<TrackingContextType>) {
  const dayOfWeek = val.newValue.label;
  const dateSelected = val.newValue.payload.date.toFormat('yyyy-MM-dd');
  const props = {
    name: 'Clicked Period Trends Range',
    selectedOption: { value: dateSelected + ' ' + dayOfWeek },
    location: val.location,
  };
  return props;
}


export function transformEntityFilter(val: Partial<TrackingContextType>) {
  const entityValue = val.newValue;
  const props = {
    name: val.name,
    location: val.location,
  };
  switch (entityValue) {
    case '':
      props['selectedOption'] = { value: 'Websites & Apps' };
      break;
    case 'domain':
      props['selectedOption'] = { value: 'Websites' };
      break;
    case 'app':
      props['selectedOption'] = { value: 'Applications' };
      break;
    case 'users':
      props['selectedOption'] = { value: 'Users' };
      break;
    case 'webApps':
      props['selectedOption'] = { value: 'Websites & Apps' };
      break;
  }

  return props;
}

export async function transformRadioUserSelection(val: Partial<TrackingContextType>) {
  let props = null;
  const userinfo = await val.selected.subscribe((user: Partial<User>) => {
    props = {
      name: 'User Selection Pulldown',
      location: 'Header',
      selectedOption: { value: user?.name },
    };
  });
  return props;
}

export function transformTimeTubeSelection(selectedWorklog: Worklog) {
  if (selectedWorklog.mode === 'offline') {
    return { date: formatDate(selectedWorklog['start'], 'YYYY-MM-dd', 'en', selectedWorklog['offset']) };
  }
  let delta = selectedWorklog.time;
  const totalHours = Math.floor(selectedWorklog.time / 3600) % 24;
  delta -= totalHours * 3600;
  const totalMins = Math.floor(delta / 60) % 60;
  const timeWorked = totalHours + 'h ' + totalMins + 'm';
  const selectedOption = {
    date: formatDate(selectedWorklog['start'], 'YYYY-MM-dd', 'en', selectedWorklog['offset']),
    timeWorked,
    startTime: formatDate(selectedWorklog['start'], 'hh:mm a', 'en', selectedWorklog['offset']),
    endTime: formatDate(selectedWorklog['end'], 'hh:mm a', 'en', selectedWorklog['offset']),
  };
  return selectedOption;
}

export function transformEditTime(val: Partial<TrackingContextType>, clockPipe: ClockPipe) {
  const timeFormat = 'hh:mm AA';
  const props = { location: 'Main Widget', name: val.name };
  let extraProps = {};
  switch (val.type) {
    case 'delete':
      extraProps = {
        selectedOption: {
          endtime: DateTime.fromISO(val.selection.end).toUTC().setZone(val.selection.timezone).toFormat('t'),
          projectname: val.selection.projectName,
          starttime: DateTime.fromISO(val.selection.start).toUTC().setZone(val.selection.timezone).toFormat('t'),
          taskname: val.selection.taskName,
        },
      };
      delete val.selection;
      break;
    case 'time':
      extraProps = {
        selectedOption: {
          value: `${DateTime.fromISO(val.pickerDate.originalSelection).toUTC().setZone(val.pickerDate.timezone).toFormat('yyyy-MM-dd')} ${clockPipe.transform(val.newValue, 'seconds', timeFormat)}`,
        },
      };
      break;
    case 'taskProject':
      extraProps = {
        selectedOption: {
          value: (val.newValue?.name === undefined) ? null : val.newValue.name,
        },
      };
      break;
  }
  return { ...props, ...extraProps };
}

export function transformActivitySummary(val: Partial<TrackingContextType>) {
  const summaryReport = val?.newValue;
  let props = { value: summaryReport.user.name };
  if (!val?.fromUserWidget) props = { employeename: summaryReport.user.name, ...summaryReport.activity };
  delete props['userId'];
  return props;
}

export function transformTreeReport(val: Partial<TrackingContextType>) {
  const props = {
    showDifferentColors: val.timesListEnabled ? 'yes' : 'no',
    tableView: val.selectedView.replace(/\s/g, '').toLowerCase(),
  };
  return props;
}

