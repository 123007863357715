import { Injectable } from '@angular/core';
import { SegmentService } from '../segment/segment.service';
import { TrackingContextType } from './context';

@Injectable()
export abstract class TrackingService {
  abstract track(type: string, context: Partial<TrackingContextType>);
}

@Injectable()
export class LogTrackingService extends TrackingService {
  track(type: string, context: Partial<TrackingContextType>) {
    console.log('Tracked ' + type, context);
  }
}

@Injectable()
export class SegmentTrackingService extends TrackingService {
  constructor(
    private segment: SegmentService,
  ) {
    super();
  }

  track(type: string, context: Partial<TrackingContextType>) {
    this.segment.directivesData(type, context);
  }
}
