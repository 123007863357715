import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { Component, Inject, InjectionToken, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { LOGIN_NEW_URL, LOGIN_URL } from '@shared';
import { Observable, combineLatest, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, first, map, mergeMap, pairwise, startWith, withLatestFrom } from 'rxjs/operators';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { DismissWelcomeBanner } from 'src/app/store/onboarding/onboarding.actions';
import { AuthCompany } from 'src/models';
import { StarterSidebarChangeEvent } from '../components/starter-sidebar/starter-sidebar.component';
import { SocketHandler } from '../initializers/socket-handler.init';
import { BrandingService } from '../services/branding.service';
import { UrlService } from '../services/url.service';
import { AfterLogout, Logout } from '../store/auth/auth.actions';
import { AuthState } from '../store/auth/auth.state';
import { PopLoading, PushLoading } from '../store/loading/loading.actions';
import { LoadingState } from '../store/loading/loading.state';
import { ProgressState } from '../store/progress/progress.state';
import { defualtUserApiLimit } from './app.constants';

export const APP_HEADER_PORTAL = new InjectionToken<CdkPortalOutlet>('App Header Portal', { providedIn: 'root', factory: () => null });

export interface InAppVisitReferrer {
  'type': string;
  'name': string;
  'path': string;
  'url': string;
}

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('loading', [
      state('void', style({
        opacity: 0,
      })),
      transition(':enter', [
        animate('0.5s ease-in-out'),
      ]),
      transition(':leave', [
        animate('0.1s ease-in-out'),
      ]),
    ]),
  ],
  providers: [
    {
      provide: APP_HEADER_PORTAL, deps: [AppComponent],
      useFactory: (app: AppComponent) => app.headerPortalOutlet,
    },
  ],
})

export class AppComponent implements OnInit {
  prevInAppVisitReferrer: InAppVisitReferrer;
  activeInAppVisitReferrer: InAppVisitReferrer;
  prevPageName: string;
  activeUrl: string;
  headerHidden$: Observable<boolean>;
  showWidgetType: string;
  @Select(LoadingState.loading) loading$: Observable<boolean>;
  loadingDebounced$: Observable<boolean>;

  @Select(ProgressState.show) showProgress$: Observable<boolean>;
  @Select(ProgressState.indeterminate) progressIndeterminate$: Observable<boolean>;
  @Select(ProgressState.percentage) progressPercentage$: Observable<number>;

  @ViewChild('headerPortalOutlet', { static: true, read: CdkPortalOutlet }) headerPortalOutlet: CdkPortalOutlet;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  bannerParams = { email: 'support2@timedoctor.com' };
  isOwner = false;
  allCompleted = false;
  welcomeSidebarCompleted = false;

  constructor(
    private actions: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private title: Title,
    private translate: TranslateService,
    private branding: BrandingService,
    private route: ActivatedRoute,
    private socketHandler: SocketHandler,
    private urlService: UrlService,
    private segment: SegmentService,
    @Inject(LOGIN_URL) private loginUrl: string,
    @Inject(LOGIN_NEW_URL) private loginNewUrl: string,
  ) {
    this.route.queryParamMap.subscribe(query => {
      this.socketHandler.liveUpdatesEnabled = !query.get('disableLiveUpdates') || query.get('disableLiveUpdates') === 'false';
    });
  }

  ngOnInit(): void {
    this.redirectToNewOnboardingContainer();
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(0),
    ).subscribe(() => {
      let child = this.router.routerState.snapshot.root;
      while (child.firstChild) {
        child = child.firstChild;
      }

      const paths = child.pathFromRoot.map(x => x.routeConfig && x.routeConfig.path || '').filter(x => x);
      this.activeUrl = paths.join('/');
    });

    const routeData = this.router.events.pipe(
      untilDestroyed(this),
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    );

    combineLatest([
      this.store.select(AuthState.company).pipe(distinctUntilChanged(), untilDestroyed(this)),
      routeData.pipe(
        map(data => data.title),
        map(title => title ? this.translate.instant(title) : this.branding.getAppName()),
      ),
    ]).subscribe(([company, title]) => {
      let titleText = title;
      if (company) {
        titleText = `${company.name} - ${titleText}`;
        this.socketHandler.liveUpdatesEnabled = company.userCount <= defualtUserApiLimit;
      }
      this.title.setTitle(titleText);
      this.prevInAppVisitReferrer = this.activeInAppVisitReferrer;
      if (this.activeUrl !== '') {
        this.activeInAppVisitReferrer = {
          type: 'webapp page',
          name: titleText,
          path: '/' + this.activeUrl,
          url: window.location.href,
        };
        this.urlService.setPreviousUrl(this.activeInAppVisitReferrer);
        const pageInfo = this.urlService.getPageNameByPath(this.activeUrl, title);
        if (title !== 'Onboarding' && this.prevPageName !== pageInfo.pageName) {
          this.prevPageName = pageInfo.pageName;
          this.segment.page(pageInfo.category, pageInfo.pageName, {}, this.prevInAppVisitReferrer);
        }
      }
    });

    this.headerHidden$ = routeData.pipe(map(data => !!data.hideHeader));

    this.store.select(AuthState.company).pipe(
      map(x => x && x.id),
      distinctUntilChanged(),
      pairwise(),
      withLatestFrom(routeData.pipe(map(data => data.onCompanyChange))),
    ).subscribe(([[prev, cur], onChange]) => {
      if ((onChange === 'ignore') || !prev || !cur) { return; }
      if (onChange === 'rerun') {
        this.router.navigateByUrl(this.router.url);
      } else {
        location.reload();
      }
    });

    this.actions.pipe(ofActionSuccessful(Logout)).subscribe(async (action: Logout) => {
      await this.router.navigateByUrl(action.errorMessage ? this.loginNewUrl : this.loginUrl);
      this.store.dispatch(new AfterLogout());
    });

    this.router.events.pipe(filter(x => x instanceof NavigationStart)).subscribe(() => {
      this.store.dispatch(new PushLoading());
      this.sidenav.close();
    });

    this.router.events.pipe(filter(x =>
      x instanceof NavigationEnd
      || x instanceof NavigationCancel
      || x instanceof NavigationError,
    )).subscribe(() => {
      this.store.dispatch(new PopLoading());
    });

    // Show the master loading in index.html until the first navigation occurs
    // Also hide the other loading so that there are not two loadings
    const firstLoad = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      first(),
    );
    firstLoad.subscribe(() => {
      const masterLoading = document.querySelector('#master-loading');
      if (masterLoading && masterLoading.parentElement) {
        masterLoading.parentElement.removeChild(masterLoading);
      }
    });

    this.loadingDebounced$ =
      combineLatest([firstLoad.pipe(map(() => true), startWith(false)), this.loading$])
        .pipe(
          map(([x, y]) => x && y),
          distinctUntilChanged(),
          debounce(x => timer(x ? 300 : 0)),
        );


    this.store.select(AuthState.company).pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      filter(x => !!x),
    ).subscribe(company => {
      this.setupDataLayer(company);
    });

    this.checkIframe();
  }

  redirectToNewOnboardingContainer() {
    const domain = window.location.hostname.split('.').slice(-2).join('.');
    const redirectOnboardingUrl = `https://onboarding.${domain}`;
    const redirectDashboardUrl = `https://2.${domain}`;
    const queryParameters = this.route.snapshot.queryParamMap;
    const queryString = queryParameters.keys
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryParameters.get(key)))
      .join('&');

    const fullUrl = queryString ?
      window.location.hostname + '?' + queryString :
      window.location.hostname;

    Sentry.withScope(scope => {
      scope.setExtra('fullUrl', fullUrl);
      Sentry.captureMessage(`Visit: ${fullUrl}`);
    });

    const redirectUrl = queryParameters.has('token') ?
      redirectOnboardingUrl :
      redirectDashboardUrl;

    const fullRedirectUrl = queryString ?
      redirectUrl + '?' + queryString :
      redirectUrl;

    window.location.href = fullRedirectUrl;
  }

  setupDataLayer(company: AuthCompany) {
    this.store.select(AuthState.user).pipe(
      distinctUntilChanged((a, b) => a?.id === b?.id),
      filter(x => !!x),
    ).subscribe(user => {
      if (!(window && window.dataLayer && company && user)) { return; }
      const isPaid = company?.subscription?.status === 'paid' || false;
      const arrName = user.name.split(' ');
      let userName = user.name;
      let lastName = '';
      if (arrName.length > 1) {
        userName = arrName[0];
        delete arrName[0];
        lastName = arrName.join(' ').trim();
      }
      const googleauth = !!this.segment.getExperimentDataByName('google-auth');
      const icp1 = !!this.segment.getExperimentDataByName('icp1');
      const props = {
        userEmail: user.email,
        companyID: company.id,
        userID: user.id,
        userName,
        lastName,
        companyName: company.name,
        siteType: window.location.hostname,
        isPaid,
        userLevel: company?.role,
        userPlan: company?.pricingPlan,
        userCount: company?.userCount,
        companyRegDate: company?.companyCreatedAt,
        ipAddress: user?.lastSeenGlobal?.ip,
        googleauth,
        icp1,
      };
      window.dataLayer.push(props);
    });
  }

  dismissBanner() {
    this.store.dispatch(new DismissWelcomeBanner());
  }

  onChangeStarterSideBar(event: StarterSidebarChangeEvent) {
    this.isOwner = event.isOwner;
    this.allCompleted = event.allCompleted;
    this.welcomeSidebarCompleted = event.welcomeSidebarCompleted;
  }

  checkIframe() {
    if (window.location !== window.parent.location) {
      Sentry.captureMessage('Loaded inside iframe', {
        level: Sentry.Severity.Info,
        tags: { iframe: true },
        extra: {
          href: window.location.href,
          parentHref: document.referrer || '',
        },
      });
    }
  }
}

