import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, Observable } from 'rxjs';
import { MenuDef, MenuParent, MENU_DEF } from 'src/app/common/menu-def.provider';
import { BrandingService } from 'src/app/services/branding.service';
import { Logout, SelectCompany } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { AuthCompany, AuthUser } from 'src/models';
import { helpLinks } from './help-links';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() navToggle = new EventEmitter<boolean>();
  @Select(AuthState.authorized) authorized$: Observable<string>;
  @Select(AuthState.user) user$: Observable<AuthUser>;
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(AuthState.companies) companies$: Observable<AuthCompany[]>;

  hasMultipleCompanies: boolean;

  @Input() activeUrl: string;

  constructor(
    private store: Store,
    @Inject(MENU_DEF) public menuDef: MenuDef,
    public branding: BrandingService,
  ) {
    this.companies$.subscribe((companiesList) => {
      if (companiesList) {
        this.hasMultipleCompanies = companiesList.length > 1;
      } else {
        this.hasMultipleCompanies = false;
      }
    });
  }

  navOpen() {
    this.navToggle.emit(true);
  }

  logout() {
    this.store.dispatch(new Logout(true));
  }

  async selectCompany(company: AuthCompany) {
    await lastValueFrom(this.store.dispatch(new SelectCompany(company)));
  }

  anyChildActive(item: MenuParent) {
    return item.children.some(x => x['routerLink'] === '/' + this.activeUrl);
  }

  getHelpLinks() {
    return helpLinks[this.activeUrl] || [];
  }

  openBubbleMessage() {
    window.HubSpotConversations?.widget?.open();
  }
}
