<div fxLayout="row" style="min-height: 100%;">
  <div fxLayout="column">
  </div>
  <div fxLayout="column" style="flex-grow: 1; min-width: 0;" class="page-container">
    <shared-top-banner #banner icon="check" iconColor="primary"
      *ngIf="isOwner && !welcomeSidebarCompleted && allCompleted">
      <div banner-header style="max-width: 550px; display: inline-block;"
        [innerHtml]="'sidebar.banner' | translate:bannerParams"></div>

      <button banner-buttons mat-button color="accent" (click)="dismissBanner(); banner.dismiss();">
        {{'common.gotIt' | translate | uppercase}}
      </button>
    </shared-top-banner>
    <mat-sidenav-container>
      <mat-sidenav #sidenav role="navigation" style="width: 320px; left: 0;" [fixedInViewport]="true">
        <app-sidenav [activeUrl]="activeUrl"></app-sidenav>
      </mat-sidenav>
      <mat-sidenav-content cdkScrollable fxLayout="column">
        <app-header [fxHide]="headerPortalOutlet.hasAttached() || (headerHidden$ | async)" [activeUrl]="activeUrl"
          (navToggle)="sidenav.toggle()">
        </app-header>
        <ng-template cdkPortalOutlet #headerPortalOutlet="cdkPortalOutlet"></ng-template>

        <div fxFlex="1 0 auto" fxLayout="column">
          <mat-progress-bar class="app-progress" color="primary" *ngIf="showProgress$ | async"
            [mode]="(progressIndeterminate$ | async) ? 'indeterminate' : 'determinate'"
            [value]="progressPercentage$ | async">
          </mat-progress-bar>

          <router-outlet class="primary-outlet"></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <router-outlet name="modal"></router-outlet>

    <app-loading *ngIf="loadingDebounced$ | async" @loading></app-loading>
  </div>
</div>
