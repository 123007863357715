import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { Store } from '@ngxs/store';
import { pick, SocketData, SocketService } from '@shared';
import { lastValueFrom } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { CompanySettings, User } from 'src/models';
import { CompanySettingsChanged, StatusChanged, StatusPayload, UserSettingsChanged } from '../store/auth/auth.actions';
import { AuthState } from '../store/auth/auth.state';
import { ManagedUserSettingsChanged, ManagedUserStatusChanged } from '../store/manager/manager.actions';


@Injectable({
  providedIn: 'root',
})
export class SocketHandler {
  public liveUpdatesEnabled = true;

  constructor(
    private store: Store,
    private socket: SocketService,
  ) {
    this.handleUserChange();
    this.handleCompanyChange();
    this.handleStatusChange();
  }

  private handleUserChange() {
    const props: (keyof (CompanySettings & User))[] = ['screenshots', 'videos', 'workCheckInterval', 'allowEditTime', 'workScheduleFeature',
      'showOnReports', 'name', 'payrollAccess', 'billingAccess', 'timezone', 'emailConfirmed', 'onlyProjectIds', 'tagIds'];
    this.resourceForCurrentUser('users').subscribe((data) => {
      const change = pick(data.payload || {}, props);

      const payload = data.payload || {};
      const custom = {
        ...('custom.browserExtensionEnabled' in payload ? { browserExtensionEnabled: payload['custom.browserExtensionEnabled'] } : {}),
      };

      this.store.dispatch(new UserSettingsChanged(change, custom));
    });

    this.socket.resource('users', 'put').pipe(takeWhile(() => this.liveUpdatesEnabled))
      .subscribe((data) => {
        const change = pick(data.payload || {}, props);
        if (data.payload.custom) { change.custom = data.payload.custom; }

        this.store.dispatch(new ManagedUserSettingsChanged(data.id, change));
      });
  }

  private handleCompanyChange() {
    this.resourceForCurrentCompany('companies').subscribe((data) => {
      const payload = data.payload || {};

      const rootProps = ['name', 'timezone', 'pricingPlan'];
      const settingProps: (keyof CompanySettings)[] = [
        'blurScreenshots', 'name', 'tasksMode', 'trackingMode', 'screenshots', 'videos', 'workCheckInterval', 'payrollFeature',
        'workScheduleFeature', 'allowManagerTagCategories', 'firstDayOfWeek', 'webAndAppTracking', 'allowNoBreak',
      ];
      const rootChanges = pick(payload, rootProps);
      const settingChanges = pick(payload, settingProps);

      const custom = {
        ...('custom.browserExtensionEnabled' in payload ? { browserExtensionEnabled: payload['custom.browserExtensionEnabled'] } : {}),
        ...('custom.browserExtensionSettings' in payload ? { browserExtensionSettings: payload['custom.browserExtensionSettings'] } : {}),
      };

      this.store.dispatch(new CompanySettingsChanged(rootChanges as any, settingChanges as any, custom));
    });
  }

  private handleStatusChange() {
    this.socket.resource('status').pipe(takeWhile(() => this.liveUpdatesEnabled))
      .subscribe(async (data: SocketData<StatusPayload>) => {
        await lastValueFrom(this.store.dispatch(new ManagedUserStatusChanged(data.payload)));

        const user = this.store.selectSnapshot(AuthState.user);
        if (data.payload.id === user.id) {
          this.store.dispatch(new StatusChanged(data.payload));
        }
      });
  }

  private resourceForCurrentUser(resource: string, method?: 'post' | 'put' | 'delete') {
    return this.socket.resource(resource, method).pipe(
      takeWhile(() => this.liveUpdatesEnabled),
      filter(x => {
        const user = this.store.selectSnapshot(AuthState.user);
        return x.id === user.id;
      }),
    );
  }

  private resourceForCurrentCompany(resource: string, method?: 'post' | 'put' | 'delete') {
    return this.socket.resource(resource, method).pipe(
      takeWhile(() => this.liveUpdatesEnabled),
      filter(x => {
        const company = this.store.selectSnapshot(AuthState.company);
        return x.payload && x.payload.companyId === company.id;
      }),
    );
  }
}

export function getSocketHandlerInitializer(socketHandler: SocketHandler) {
  return () => socketHandler;
}

export const socketHandlerInitProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: getSocketHandlerInitializer,
  deps: [SocketHandler],
  multi: true,
};
