import { Component, EventEmitter, HostBinding, Output } from '@angular/core';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class SidepanelComponent {

  @HostBinding('class.visible') protected visible = false;

  @Output() closed = new EventEmitter();

  close() {
    this.closed.emit();
    this.visible = false;
  }

  open() {
    this.visible = true;
  }
}
