<div class="learn-more-header">
<button class="close" mat-button (click)="onNoClick()" style="float: right;margin: 0px 0px 10px 10px;">X</button>
</div>
<div class="learn-more-cont">
  <div class="learn-more-content">
      <h1><span id="hs_cos_wrapper_name" class="hs_cos_wrapper hs_cos_wrapper_meta_field hs_cos_wrapper_type_text" style="" data-hs-cos-general-type="meta_field" data-hs-cos-type="text">Silent vs. Interactive Apps</span></h1>
      <p>The Time Doctor desktop apps track activities on the computers that they’re installed on and send that information back to Time Doctor’s servers where it will appear in your reports on the website.&nbsp;</p>
      <p>Here are the differences between the two desktop apps that we have available.</p>
      <h3>The Silent App&nbsp;</h3>
      <p><strong>How It Works</strong></p>
      <ul>
      <li>
      <p>The silent app will track computer activities whenever the computer is on.</p>
      </li>
      <li>
      <p>Since the silent app has no user interface, users won’t interact with it and won’t see it running unless they look at all running programs in their Task Manager (Windows).</p>
      </li>
      </ul>
      <p><strong>How to Install It</strong></p>
      <ul>
      <li>
      <p>You can install it manually on any single computer by running the installation file on that computer.</p>
      </li>
      <li>
      <p>Since the installation file is an MSI file, a system administrator can install the silent app throughout a network of computers through <a href="https://en.wikipedia.org/wiki/Group_Policy" target="_blank" rel="noopener">Group Policy</a>.</p>
      </li>
      <li>
      <p>It’s not possible to invite people to use the silent app.</p>
      </li>
      </ul>
      <p><strong>Additional Info</strong></p>
      <ul>
      <li>
      <p>The app that you download from your <a href="https://www.timedoctor.com/download.html" target="_blank" rel="noopener">Downloads</a> page contains information that identifies it as being from your company’s account. Any computer that the app is installed on will send data to your company’s account.</p>
      </li>
      </ul>
      <h3>The Interactive App&nbsp;</h3>
      <p><strong>How It Works</strong></p>
      <ul>
      <li>
      <p>The interactive app has a user interface that allows the user to start or stop tracking their activities whenever they choose.</p>
      </li>
      <li>
      <p>It tracks all the same computer activity info that the silent app tracks, unless the user has paused the tracking.</p>
      </li>
      </ul>
      <p><strong>Tasks vs. No Tasks</strong></p>
      <ul>
      <li>
      <p>If you’re using the “Projects &amp; Tasks” feature (which admins can activate on the <strong>Edit Company&nbsp;</strong>page), the interactive desktop app will display a window containing a list of projects &amp; tasks. The user can click on a project &amp; task to track time to it.</p>
      </li>
      <li>
      <p>If you’re not using the “Projects &amp; Tasks” feature, the desktop interface will simply be an icon in the system tray (Windows &amp; Linux) or in the menu bar (Mac). There will be a menu attached to it that allows the user to start or stop tracking and change some settings.</p>
      </li>
      </ul>
      <p><strong>How to Install It</strong></p>
      <ul>
      <li>
      <p>Each user installs their own interactive app. Admins can send invitations to users from the <strong>Invite Users</strong> page. The invitations will include a link for the invited user to download and install the app.</p>
      </li>
      </ul>
      <p><strong>Your entire company can be:</strong></p>
      <ul>
      <li>
      <p><strong>Silent</strong><br>Users can only track work with the silent app. This option is suitable when your users are in an office where their computers are used exclusively for work, and you don’t want the app to take up any of their time or focus.</p>
      </li>
      <li>
      <p><strong>Interactive</strong><br>Users can only track work with the interactive app. This option is more suitable when your users work remotely and/or are allowed to use their computers for personal activities.</p>
      </li>
      <li>
      <p><strong>Mixed</strong><br>Both silent and interactive apps can be used.</p>
      </li>
      </ul>
      <p>Admins and owners can control this setting for their company on the <strong>Edit Company&nbsp;</strong>page.</p>
      <ul></ul>
      <img class="private-image image-plugin-component hs-image-align-none" src="https://support2.timedoctor.com/hubfs/Knowledge%20Base%20Import/support.timedoctor.comensupportsolutionsarticles66000480701-silent-vs-interactive-appshit.gif">
  </div>
</div>
