
export const createEmailNotificationId = Symbol('Create Email Notification');

export type NotificationType = 'daily' | 'realtime';
export type NotificationScope = 'workspace' | 'user' | 'tag';

export type NotificationConditionType = 'unproductive-time-per-day' | 'productive-time-per-day' |
  'idle-min-per-day' | 'idle-sec-per-day' | 'no-track-time' | 'daily-worked-hrs' | 'start-time' |
  'did-not-follow-work-schedule' | 'manual-and-mobile-time-added' | 'added-manual-time' |
  'not-started-working' | 'not-started-shift' | 'unproductive-time-today';

export interface EmailNotification {
  id: string | symbol;
  name: string;
  notifyType: NotificationType;
  condType: NotificationConditionType;
  condValue: string;
  scope: NotificationScope;
  dataIds: string[];
  daysApplicable: number[];
  timezone: string;
}

export function parseCondition(condition: string) {
  if (!condition) { return null; }

  if (condition.startsWith('_') || condition.endsWith('_')) {
    return {
      condition: condition.startsWith('_') ? 'lowerThan' : 'greaterThan',
      value: parseConditionValue(condition),
    };
  } else if (condition.endsWith('d$')) {
    return { condition: 'in', value: parseConditionValue(condition) };
  }

  return null;
}

function parseConditionValue(condition: string) {
  if (!condition) { return 0; }

  if (condition.includes('_')) {
    return parseFloat(condition.replace(/_/g, ''));
  }

  if (condition.includes('d$')) {
    return parseFloat(condition.replace('d$', ''));
  }

  return 0;
}
