import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { ERROR_URL } from 'src/app/app/app.constants';
import { isMenuAllowed } from 'src/app/common/menu-def.provider';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(ERROR_URL) private errorUrl,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const urlTree = this.router.parseUrl(state.url);
    const urlWithoutParams = urlTree.root.children?.primary?.segments?.map(it => it.path).join('/') || '';

    const company = this.store.selectSnapshot(AuthState.company);
    if (urlWithoutParams && !isMenuAllowed(company, '/' + urlWithoutParams)) {
      return this.router.createUrlTree([this.errorUrl, 'unauthorized']);
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
