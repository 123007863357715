import { Injectable } from '@angular/core';
import { Action, InitState, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { appendOrUpdate, AuthService } from '@shared';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthCompany, AuthUser } from 'src/models';
import { PopLoading, PushLoading } from '../loading/loading.actions';
import { SharedState } from '../shared/shared.state';
import { BeforeLogout, CompanySettingsChanged, EditProfile, ForgotPassword, InvalidateUser, Login, LoginAs, Logout, Register, SelectCompany, SetUeMemberFlag, StatusChanged, TokenLogin, UpdateCurrentUser, UserSettingsChanged } from './auth.actions';



export interface AuthStateModel {
  user?: AuthUser;
  token?: string;
  userRequiresUpdate?: boolean;
  company?: AuthCompany;
  errorMessage?: string;
  totpNeeded?: boolean;
  usersToRemember: { token: string, user: AuthUser }[];
  adminLogin?: boolean;
  isEuMember?: boolean;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    usersToRemember: [],
    errorMessage: null,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  static clockFormatFactory(store: Store) {
    return () => store.selectSnapshot(AuthState.hourFormat24) ? 24 : 12;
  }

  constructor(
    private store: Store,
    private authService: AuthService,
    private segment: SegmentService,
  ) { }

  @Selector()
  static authorized(state: AuthStateModel) { return state && state.company && state.token; }

  @Selector()
  static token(state: AuthStateModel) { return state && state.token; }

  @Selector()
  static user(state: AuthStateModel) { return state.user; }

  @Selector()
  static company(state: AuthStateModel) { return state.company; }

  @Selector()
  static role(state: AuthStateModel) { return state.company?.role; }

  @Selector()
  static companyTimezone(state: AuthStateModel) { return state.company?.companyTimezone; }

  @Selector()
  static firstDayOfWeek(state: AuthStateModel) { return state.company?.companySettings?.firstDayOfWeek ?? 1; }

  @Selector()
  static screencastsEnabled(state: AuthStateModel) { return state.company?.companySettings?.screencastsFeature !== false; }

  @Selector()
  static defaultTag(state: AuthStateModel) { return state.company?.allUsersTagId; }

  @Selector()
  static combinedId(state: AuthStateModel) {
    if (state.token && state.user && state.company && !state.userRequiresUpdate) {
      return `${state.user.id}__${state.company.id}`;
    }
    return null;
  }

  @Selector()
  static usersToRemember(state: AuthStateModel) { return state.usersToRemember; }

  @Selector()
  static companies(state: AuthStateModel) { return state.user?.companies; }

  @Selector()
  static hourFormat24(state: AuthStateModel) { return state.user.reportIn24HourFormat || false; }

  @Selector()
  static adminLogin(state: AuthStateModel) { return state.adminLogin; }

  @Selector()
  static isActive(state: AuthStateModel) {
    return state.user?.active;
  }

  @Selector()
  static tasksEnabled(state: AuthStateModel) {
    return state.company?.companySettings?.tasksMode !== 'off';
  }

  @Selector()
  static trackingMode(state: AuthStateModel) {
    return state.company?.companySettings?.trackingMode;
  }

  @Selector()
  static webAppTrackingMode(state: AuthStateModel) {
    return state.company?.companySettings?.webAndAppTracking;
  }

  @Selector()
  static extensionEnabledCompany(state: AuthStateModel) {
    return state.company?.companySettings?.custom?.browserExtensionEnabled || false;
  }

  @Selector()
  static extensionSettings(state: AuthStateModel) {
    return state.company?.companySettings?.custom?.browserExtensionSettings || {};
  }

  @Selector()
  static extensionEnabledUser(state: AuthStateModel) {
    const companyEnabled = this.extensionEnabledCompany(state);
    return companyEnabled === 'per-user' ? (state.company?.userSettings?.custom?.browserExtensionEnabled === true) : companyEnabled;
  }

  @Selector()
  static canAdjustRatings(state: AuthStateModel) {
    const company = state.company;
    return !!company && (company.role === 'admin' || company.role === 'owner'
      || (company.companySettings.allowManagerTagCategories && company.role === 'manager'));
  }

  @Selector()
  static canAdjustWorkSchedules(state: AuthStateModel) {
    const company = state.company;
    return !!company && (company.role === 'admin' || company.role === 'owner'
      || (company.companySettings.allowManagerWorkSchedules && company.role === 'manager'));
  }

  @Selector()
  static allowEditTime(state: AuthStateModel) {
    return state.company?.userSettings?.allowEditTime;
  }

  @Action(InitState)
  ngxsOnInit({ patchState }: StateContext<AuthStateModel>) {
    patchState({ errorMessage: null, totpNeeded: false, userRequiresUpdate: true });
  }

  @Action(Login)
  async login({ patchState, dispatch, setState }: StateContext<AuthStateModel>, { email, password, rememberMe, totpCode }: Login) {
    await lastValueFrom(dispatch(new PushLoading()));
    patchState({ errorMessage: null, totpNeeded: false, user: null, company: null });

    try {
      const { token, status, ...user }: { token: string, status?: 'totpNeeded' } & AuthUser =
        await lastValueFrom(this.authService.login(email, password, totpCode)) as any;

      if (status === 'totpNeeded') {
        patchState({ totpNeeded: true, errorMessage: null });
        return;
      }

      await lastValueFrom(dispatch(new LoginAs(user, token)));

      const newState: Partial<AuthStateModel> = {
        errorMessage: null,
        totpNeeded: false,
        userRequiresUpdate: false,
      };

      setState(patch({
        ...newState,
        ...(rememberMe && { usersToRemember: appendOrUpdate([{ token, user }], (a, b) => a.user.id === b.user.id) }),
      }));
    } catch (error) {
      if (error && error.error === 'invalidCredentials') {
        patchState({ errorMessage: 'login.invalidCredentials' });
      } else if (error && error.error === 'invalidTotpCode') {
        patchState({ errorMessage: 'login.invalidTotpCode' });
      } else {
        patchState({ errorMessage: 'login.genericError' });
        throw error;
      }
    } finally {
      await lastValueFrom(dispatch(new PopLoading()));
    }
  }

  @Action(LoginAs)
  async loginAs({ patchState, dispatch }: StateContext<AuthStateModel>, { user, token }: LoginAs) {
    const newState: Partial<AuthStateModel> = { user, token, userRequiresUpdate: true, company: null };
    patchState(newState);

    if (user.companies && user.companies.length === 1) {
      const company = user.companies[0];
      await lastValueFrom(dispatch(new SelectCompany(company)));
      await this.segment.identify();
      this.segment.serverSideCall();
      setTimeout(async () => {
        this.segment.track('Signed In', {
          userId: user.id,
        });
      }, 1000);
      this.segment.dataForExternalTracking({ name: user.name, email: user.email });
    }
  }

  @Action(ForgotPassword)
  async forgotPassword({ patchState, dispatch, setState }: StateContext<AuthStateModel>, { email }: ForgotPassword) {
    await lastValueFrom(dispatch(new PushLoading()));
    patchState({ errorMessage: null });

    try {
      await lastValueFrom(this.authService.forgotPassword(email));
    } catch (error) {
      if (error && error.error === 'invalidCredentials') {
        patchState({ errorMessage: 'forgotPassword.invalidCredentials' });
      } else if (error && error.error === 'badEmail') {
        patchState({ errorMessage: 'forgotPassword.invalidTotpCode' });
      } else {
        patchState({ errorMessage: 'login.genericError' });
        throw error;
      }
    } finally {
      await lastValueFrom(dispatch(new PopLoading()));
    }
  }

  @Action(UpdateCurrentUser)
  async updateCurrentUser(ctx: StateContext<AuthStateModel>, payload: UpdateCurrentUser) {
    if (!payload.hideLoading) {
      ctx.dispatch(new PushLoading());
    }

    try {
      // Update the current user with latest data
      const online = this.store.selectSnapshot(SharedState.online);

      if (online) {
        const me = await lastValueFrom(this.authService.auth());
        const state = ctx.getState();

        let company = me.companies.find(x => x.id === (state.company && state.company.id));

        if (!company && me.companies && me.companies.length === 1) {
          company = me.companies[0];
        }

        ctx.patchState({ company, userRequiresUpdate: false });
        this.changeUser(ctx, me);
      }
    } finally {
      if (!payload.hideLoading) {
        ctx.dispatch(new PopLoading());
      }
    }
  }

  @Action(Logout)
  async logout({ setState, getState, dispatch }: StateContext<AuthStateModel>, payload: Logout) {
    const state = getState();
    if (state.user) {
      await this.segment.track('Signed Out', {
        userId: state.user.id,
      });
    }
    await lastValueFrom(dispatch(new BeforeLogout()));
    const usersToRemember = [...state.usersToRemember];
    if (payload.forget) {
      const ind = usersToRemember.findIndex(x => x.user.id === state.user.id);
      if (ind >= 0) {
        usersToRemember.splice(ind, 1);
      }

      if (state.token) {
        try {
          this.authService.logout(state.token);
        } catch (err) {
          console.error(err);
        }
      }
    }

    setState({ usersToRemember, errorMessage: payload.errorMessage });
    this.segment.dataForExternalTracking(null);
  }

  @Action(SelectCompany)
  selectCompany({ patchState }: StateContext<AuthStateModel>, payload: SelectCompany) {
    patchState({ company: payload.company });
  }

  @Action(UserSettingsChanged)
  userSettingsChanged(ctx: StateContext<AuthStateModel>, payload: UserSettingsChanged) {
    const { company } = ctx.getState();
    const custom = company && company.userSettings.custom || {};
    const newCustom = payload.custom || {};
    const newCompany = {
      ...company,
      userSettings: {
        ...company.userSettings,
        ...payload.changes,
        custom: {
          ...custom,
          ...newCustom,
          completedStep: {
            ...(custom.completedStep || {}),
            ...(newCustom.completedStep || {}),
          },
          dismissedStep: {
            ...(custom.dismissedStep || {}),
            ...(newCustom.dismissedStep || {}),
          },
        },
      },
    };

    this.changeCompany(ctx, newCompany);
  }


  @Action(CompanySettingsChanged)
  companySettingsChanged(ctx: StateContext<AuthStateModel>, payload: CompanySettingsChanged) {
    const { company } = ctx.getState();
    const custom = company && company.companySettings.custom || {};
    const newCustom = payload.custom || {};
    const newCompany: AuthCompany = {
      ...company,
      ...payload.rootChanges,
      companySettings: {
        ...company.companySettings,
        ...payload.settingChanges,
        custom: {
          ...custom,
          ...newCustom,
          completedStep: {
            ...(custom.completedStep || {}),
            ...(newCustom.completedStep || {}),
          },
          dismissedStep: {
            ...(custom.dismissedStep || {}),
            ...(newCustom.dismissedStep || {}),
          },
        },
      },
    };

    this.changeCompany(ctx, newCompany);
  }

  @Action(StatusChanged)
  statusChanged(ctx: StateContext<AuthStateModel>, payload: StatusChanged) {
    const { company } = ctx.getState();
    const newCompany: AuthCompany = {
      ...company,
      lastSeen: {
        ...company.lastSeen,
        ip: payload.data.remoteIp,
        online: payload.data.mode !== 'offline',
        updatedAt: new Date().toUTCString(),
      },
      lastTrack: {
        ...company.lastTrack,
        ip: payload.data.remoteIp,
        activeAt: new Date().toUTCString(),
        projectId: payload.data.projectId,
        taskId: payload.data.taskId,
        status: payload.data.mode,
        online: payload.data.mode !== 'offline',
        updatedAt: new Date().toUTCString(),
      },
    };

    this.changeCompany(ctx, newCompany);
  }

  private changeCompany(ctx: StateContext<AuthStateModel>, newCompany: AuthCompany) {
    ctx.patchState({ company: newCompany });

    const { user } = ctx.getState();

    if (user) {
      const newCompanies = [...user.companies];
      const ind = user.companies.findIndex(x => x.id === newCompany.id);
      if (ind >= 0) {
        newCompanies[ind] = newCompany;
      }

      const newUser = { ...user, companies: newCompanies };

      this.changeUser(ctx, newUser);
    }
  }

  private changeUser(ctx: StateContext<AuthStateModel>, newUser: AuthUser) {
    const usersToRemember = [...ctx.getState().usersToRemember];
    const ind = usersToRemember.findIndex(x => x.user.id === newUser.id);
    if (ind >= 0) {
      usersToRemember[ind] = { user: newUser, token: usersToRemember[ind].token };
    }

    ctx.patchState({ user: newUser, usersToRemember });
  }

  @Action(InvalidateUser)
  invalidateUser({ getState, setState }: StateContext<AuthStateModel>, { errorMessage }: InvalidateUser) {
    const state = getState();

    setState((draft) => {
      return {
        ...draft,
        usersToRemember: state.usersToRemember.filter(x => x.token !== state.token),
        errorMessage,
        userRequiresUpdate: false,
        user: null,
        company: null,
        adminLogin: false,
      };
    });
  }

  @Action(TokenLogin)
  TokenLogin({ patchState, dispatch, getState }: StateContext<AuthStateModel>, payload: TokenLogin) {
    patchState({ token: payload.token, userRequiresUpdate: true, company: null, adminLogin: payload.adminLogin });
    return dispatch(new UpdateCurrentUser()).pipe(tap(() => {
      const st = getState();
      const companies = st.user.companies;

      const foundCompany = companies?.find(x => x.id === payload.company) || (companies?.length === 1 && companies[0]);
      if (foundCompany) {
        patchState({ company: foundCompany });
        this.segment.dataForExternalTracking({ name: st.user.name, email: st.user.email });
      }
    }));
  }

  @Action(EditProfile)
  async editProfile({ setState }: StateContext<AuthStateModel>, payload: EditProfile) {
    try {
      const tokenResult = await this.authService.editProfile(payload.properties);

      const { password, ...pl } = payload.properties;
      setState(patch({ user: (x) => ({ ...x, ...pl }), token: (x) => tokenResult.token || x, errorMessage: null }));
    } catch (error) {
      if (error?.error === 'resourceExists') {
        setState(patch({ errorMessage: 'userProfile.emailExists' }));
      } else {
        setState(patch({ errorMessage: 'userProfile.genericError' }));
      }
    }
  }

  @Action(Register)
  async register({ setState }: StateContext<AuthStateModel>, payload: Register) {
    const tokenResult = await this.authService.register(payload.email, payload.name, payload.password);

    setState(patch({ user: (x) => ({ ...x, name: payload.name }), token: (x) => tokenResult.token || x }));
  }

  @Action(SetUeMemberFlag)
  SetUeMemberFlag({ patchState }: StateContext<AuthStateModel>, { isUEMember }: SetUeMemberFlag) {
    patchState({ isEuMember: isUEMember });
    return isUEMember;
  }
}
