import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Branding } from 'src/models';
import { getDisableBilling } from '../app/app.constants';

@Injectable({ providedIn: 'root' })
export class BrandingService {
  private static readonly props = {
    [Branding.TimeDoctor]: {
      appName: 'Time Doctor 2',
      appUrl: environment.appUrl,
      downloadAppName: 'timedoctor2',
      hasLogo: true,
      disableBilling: false,
      disableDownload: false,
    },

    [Branding.India]: {
      appName: 'Time Doctor 2',
      appUrl: environment.appUrl,
      downloadAppName: 'timedoctor2-in',
      apiUrl: 'https://api2-in.timedoctor.com',
      hasLogo: false,
      disableBilling: true,
      disableDownload: false,
    },

    [Branding.LibertyFinance]: {
      appName: 'Ignite Desktop',
      appUrl: 'https://ignite.liberty.com.au',
      downloadAppName: 'ignitedesktop',
      hasLogo: true,
      disableBilling: false,
      disableDownload: true,
    },

    [Branding.Vodafone]: {
      appName: 'Time Doctor 2',
      appUrl: 'https://vois.timedoctor.com',
      downloadAppName: 'timedoctor2-vois',
      hasLogo: false,
      disableBilling: true,
      disableDownload: true,
    },

    [Branding.VodafoneIndia]: {
      appName: 'Time Doctor 2',
      appUrl: 'https://vois-in.timedoctor.com',
      downloadAppName: 'timedoctor2-vois-in',
      hasLogo: false,
      disableBilling: true,
      disableDownload: true,
    },

    [Branding.HelloRache]: {
      appName: 'Time Doctor 2',
      appUrl: 'https://hellorache2.timedoctor.com',
      downloadAppName: 'timedoctor2-hellorache',
      hasLogo: false,
      disableBilling: false,
      disableDownload: false,
    },
  };

  private specialHosts = {
    localhost: Branding.TimeDoctor,
    'ignite.liberty.com.au': Branding.LibertyFinance,
    'in.timedoctor.com': Branding.India,
  };

  private subdomainBrandingMap = {
    liberty: Branding.LibertyFinance,
    in: Branding.India,
    vois: Branding.Vodafone,
    'vois-in': Branding.VodafoneIndia,
    hellorache2: Branding.HelloRache,
  };

  readonly branding: Branding = Branding.TimeDoctor;

  get isDefault() { return this.branding === Branding.TimeDoctor || !this.branding; }

  static initialize(service: BrandingService) {
    return () => {
      document.body.classList.add(service.branding);
      if (!service.isDefault) {
        try {
          window.analytics?.page('disable_intercom', 'disable_intercom', {}, { Intercom: { hideDefaultLauncher: true } });
          window.Intercom?.('update', { hide_default_launcher: true });
        } catch (err) { }
      }
    };
  }

  static appUrlFactory(service: BrandingService) {
    return service.getAppUrl();
  }

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
  ) {
    const specialHost = this.specialHosts[window.location.hostname];
    if (specialHost) {
      this.branding = specialHost;
    } else if (window.location.host.includes('timedoctor.com')) {
      const subdomain = window.location.host.split('.')[0];
      this.branding = this.subdomainBrandingMap[subdomain] || Branding.TimeDoctor;
    }

    const match = window.location.search.match(/(\?|&)branding=(\w+)(&|$)/);
    if (match?.length) {
      this.branding = match[2] as Branding;
    }
  }

  getProp(prop: string) {
    return BrandingService.props[this.branding]?.[prop];
  }

  getAppName(): string {
    return this.getProp('appName');
  }

  getAppUrl(): string {
    return this.getProp('appUrl');
  }

  getApiUrl(): string {
    return this.getProp('apiUrl');
  }

  billingDisabled(): boolean {
    return getDisableBilling() || this.getProp('disableBilling');
  }

  downloadDisabled(): boolean {
    return this.getProp('disableDownload');
  }

  getDownloadAppName(): string {
    return this.getProp('downloadAppName');
  }

  getLogo(): string {
    return `${this.baseHref}assets/images/brand/${this.getProp('hasLogo') ? this.branding : 'timedoctor'}/logo.svg`;
  }

  getLogoGray(): string {
    return `${this.baseHref}assets/images/brand/${this.getProp('hasLogo') ? this.branding : 'timedoctor'}/logo_gray.svg`;
  }
}
