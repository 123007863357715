import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthState } from '../store/auth/auth.state';


function initializeErrorMonitoringUser(store: Store) {
  return () => {
    store.select(AuthState.user).pipe(distinctUntilChanged()).subscribe(user => {
      if (user) {
        Sentry.setUser({ id: user.id, email: user.email, username: user.name });
      } else {
        Sentry.setUser(null);
      }
    });

    store.select(AuthState.adminLogin).pipe(distinctUntilChanged()).subscribe(adminLogin => {
      Sentry.setTag('adminLogin', !!adminLogin);
    });

    store.select(st => st).subscribe((state => {
      Sentry.setContext('state', state);
    }));
  };
}

const SentryErrorHandler = Sentry.createErrorHandler({
  showDialog: false,
});


export const sentryProviders: Provider[] = [
  {
    provide: ErrorHandler,
    useValue: SentryErrorHandler,
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => null,
    deps: [Sentry.TraceService],
    multi: true,
  },
  { provide: APP_INITIALIZER, useFactory: initializeErrorMonitoringUser, deps: [Store], multi: true },
];
