import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ClockPipe } from '@shared';
import { lastValueFrom } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { OnboardingPages } from 'src/app/pages/onboarding/onboarding-pages';
import { UserService } from 'src/app/services/user/user.service';
import { ExtCustomCompanySettings, TokenLogin, UserSettingsChanged } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { SetOnboardingWizardConfig } from 'src/app/store/onboarding/onboarding.actions';
import type { AppState } from 'src/app/store/state';
import { environment } from 'src/environments/environment';
import { AuthCompany, AuthUser } from 'src/models';
import { BrandingService } from '../branding.service';
import { CompanyService } from '../company/company.service';
import { TrackingContextType } from '../tracking/context';
import { DeviceDetector } from './device-detector';
import freeEmailDomains from './free-email-domains.json';
import {
  transformActivitySummary,
  transformEditTime,
  transformEntityFilter,
  transformHighestIdleWidget,
  transformNewValue,
  transformPeriodPickerSelection,
  transformPeriodTabsSelection,
  transformRadioUserSelection,
  transformSortColumnsSelection,
  transformStackChartSelection,
  transformTabsSelection,
  transformTimeTubeSelection,
  transformTimezoneSelection,
  transformTreeReport,
  transformUserLazySelection
} from './parse-data';

export interface SegmentOptions {
  integrations: {
    'All': boolean;
    'Intercom': boolean;
    'Google Analytics': boolean;
    [key: string]: boolean;
  };
}

export interface DataForExternalTracking {
  name?: string;
  email?: string;
}

@Injectable({ providedIn: 'root' })
export class SegmentService {
  user: AuthUser;
  company: AuthCompany;
  adminLogin: boolean;
  commonTraits: any;
  companyTraits: any;
  userTraits: any;
  prevStep = null;
  companyName: string;
  currentUser: AuthUser;
  userEmail: string;
  readonly homectaWithDiscount = {
    TDDeelPromo10: 'TDDeelPromo10',
    TimeDoctorPartner50: 'TimeDoctorPartner50',
    TimeDoctorPartner20: 'TimeDoctorPartner20',
    TimeDoctorPartner10: 'TimeDoctorPartner10',
  };
  isUEMember: boolean;
  userGeoInfo: any;
  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private actions$: Actions,
    private branding: BrandingService,
    private clockPipe: ClockPipe,
    private companyService: CompanyService,
    private deviceDetector: DeviceDetector,
    private userService: UserService,
  ) {
    this.actions$
      .pipe(
        ofActionSuccessful(TokenLogin, SetOnboardingWizardConfig),
      )
      .subscribe(async (action) => {
        if (
          typeof window.analytics.user === 'function' &&
          !window.analytics.user().id()
        ) {
          await this.identify();
        }
        switch (action.constructor.type) {
          case TokenLogin.type:
            await this.pageOnboarding('Auth');
            break;
          case SetOnboardingWizardConfig.type:
            if (action?.config?.currentStep) {
              const currStep = action.config.currentStep;

              // Make sure this is not called for the ICP1 onboarding flow
              if (this.prevStep !== currStep && Object.keys(OnboardingPages).includes(currStep)) {
                this.isUEMember = this.store.selectSnapshot(AuthState).isEuMember;
                if (this.isUEMember && currStep === 'trackingModeStep2') return false;
                this.pageOnboarding('Onboarding', OnboardingPages[currStep]);
                this.prevStep = currStep;
              }
            }
            break;
        }
      });
  }

  prepareData() {
    this.user = null;
    this.company = null;
    this.store.select((st: AppState) => st.auth.user).pipe(
      distinctUntilChanged(),
    ).subscribe(user => {
      this.user = user;
    });
    this.store.select((st: AppState) => st.auth.company).pipe(
      distinctUntilChanged(),
    ).subscribe(company => {
      this.company = company || {} as AuthCompany;
    });
  }

  trackSideBarActivity(stepId: string, dismissedStep: boolean = false) {
    this.prepareData();
    const { user } = this;
    if (!user) {
      return false;
    }
    const completedSteps = user?.companies[0]?.userSettings?.custom?.completedStep;
    if (completedSteps) {
      if (completedSteps[stepId] || completedSteps['trackedAll']) {
        return false;
      }
    }

    if (stepId === 'trackedAll') {
      this.trackWithUTMS('Onboard Complete 2');
      return true;
    }

    const sideBarProps = {
      createAcc: {
        stepNumber: 1,
        description: 'Create Account',
      },
      confirmEmail: {
        stepNumber: 2,
        description: 'Confirm your email',
      },
      addEmployee: {
        stepNumber: 3,
        description: 'Add your first employee',
      },
      employeeTrack: {
        stepNumber: 4,
        description: 'Get an employee to track time',
      },
      addProjectTask: {
        stepNumber: 5,
        description: 'Add poject and tasks',
      },
      setupGroups: {
        stepNumber: 6,
        description: 'Set up employee groups',
      },
      companySettings: {
        stepNumber: 7,
        description: 'Review & adjust company settings',
      },
      selectProductive: {
        stepNumber: 8,
        description: 'Select which apps and websites are productive',
      },
      addCreditCard: {
        stepNumber: 9,
        description: 'Add your credit card',
      },
      setupPassword: {
        stepNumber: 10,
        description: 'Set up your password',
      },
    };
    this.trackWithUTMS(
      'SideBar Activity',
      {
        completedStep: sideBarProps[stepId]?.stepNumber,
        completedStepDescription: sideBarProps[stepId]?.description,
        dismissedStep,
      },
    );
    return true;
  }

  async getUserTraits() {
    this.prepareData();
    const { user, company } = this;
    if (!this.commonTraits?.userTraits) {
      await this.getCommonTraits();
    }
    const nameArr = user?.name.split(/ (.+)/);
    const firstname = this.commonTraits?.userTraits?.firstname ?? nameArr[0];
    const lastname = this.commonTraits?.userTraits?.lasttname ?? nameArr[1];
    const traits = {
      tduserid: user.id,
      tdcompanyid: company.id,
      email: this.commonTraits?.userTraits?.email ?? user.email,
      tdEmail: user.email,
      name: user?.name,
      firstname,
      lastname,
      access_level: (company.role === 'user') ? 'regular user' : company.role,
      numberofcompanies: this.commonTraits?.userTraits?.numberofcompanies ?? user.companies.length,
      createdAt: company.companyCreatedAt,
      timezone: this.commonTraits?.userTraits?.timezone ?? company.timezone,
      workspaceName: this.commonTraits?.userTraits?.workspaceName ?? company.name,
      workspaceId: this.commonTraits?.userTraits?.workspaceId ?? company.id,
      phonenumber: this.commonTraits?.userTraits?.phonenumber ?? null,
      twofactorauth: this.commonTraits?.userTraits?.twofactorauth ?? null,
      utmContent: this.commonTraits?.userTraits?.utmContent || this.getUTMsByName('utm_content'),
      utmSource: this.commonTraits?.userTraits?.utmSource || this.getUTMsByName('utm_source'),
      utmMedium: this.commonTraits?.userTraits?.utmMedium || this.getUTMsByName('utm_medium'),
      utmCampaign: this.commonTraits?.userTraits?.utmCampaign || this.getUTMsByName('utm_campaign'),
      utmTerm: this.commonTraits?.userTraits?.utmTerm || this.getUTMsByName('utm_term'),
      client_id: this.commonTraits?.userTraits?.client_id ?? this.getDataLayer('GAClientID_DL'),
      contact_country: this.commonTraits?.userTraits?.contact_country ?? null,
      hubspot_contact_id: this.commonTraits?.userTraits?.hubspot_contact_id ?? null,
    } as any;

    this.userTraits = traits;
    return traits;
  }

  async getCompanyTraits() {
    this.prepareData();
    const company = this.company;
    const companyStatus = company.subscription.status === 'free' ? 'trial expired' : company.subscription.status;
    if (!this.commonTraits?.companyTraits) {
      await this.getCommonTraits();
    }
    const tdRef = this.getCookie('td_referrer') || null;
    let employees = this.getExperimentDataByName('employees') !== '' ?
      Number(this.getExperimentDataByName('employees')) :
      company.userCount;
    employees = this.commonTraits?.companyTraits?.employees ?? employees;
    const traits = {
      tdcompanyid: company.id,
      timezone: company.companyTimezone,
      createdAt: company.companyCreatedAt,
      employees,
      name: this.commonTraits?.companyTraits?.name ?? company.name,
      td_referrer: this.commonTraits?.companyTraits?.td_referrer ?? tdRef,
      taskMode: this.commonTraits?.companyTraits?.taskMode ?? company.companySettings.tasksMode,
      phoneNumber: this.commonTraits?.companyTraits?.phoneNumber ?? null,
      tdapptype: 'TD2',
      utmContent: this.commonTraits?.companyTraits?.utmContent || this.getUTMsByName('utm_content'),
      utmSource: this.commonTraits?.companyTraits?.utmSource || this.getUTMsByName('utm_source'),
      utmMedium: this.commonTraits?.companyTraits?.utmMedium || this.getUTMsByName('utm_medium'),
      utmCampaign: this.commonTraits?.companyTraits?.utmCampaign || this.getUTMsByName('utm_campaign'),
      utmTerm: this.commonTraits?.companyTraits?.utmTerm || this.getUTMsByName('utm_term'),
      client_id: this.commonTraits?.companyTraits?.client_id ?? this.getDataLayer('GAClientID_DL'),
      gclid: this.commonTraits?.companyTraits?.gclid ?? this.getDataLayer('gclid'),
      fbclid: this.commonTraits?.companyTraits?.fbclid ?? this.getDataLayer('fbclid'),
      td_website_url: this.commonTraits?.companyTraits?.td_website_url ?? this.getExperimentDataByName('website'),
      home_cta: this.commonTraits?.companyTraits?.home_cta ?? this.getExperimentDataByName('popup-signup-trigger'),
    } as any;

    if (this.commonTraits?.companyTraits?.hubspot_company_id) {
      traits.hubspot_company_id = this.commonTraits.companyTraits.hubspot_company_id;
    }
    this.companyTraits = traits;
    return traits;
  }

  async trackAccountCreated() {
    if (
      typeof window.analytics?.user === 'function' &&
      !window.analytics.user().id()
    ) {
      await this.identify();
    }
    this.trackOnboardingEvent('Account Created');
  }

  async getAnonymousId() {
    if (
      typeof window.analytics.user === 'function' &&
      window.analytics.user().anonymousId()
    ) {
      return window?.analytics?.user()?.anonymousId();
    }
    return null;
  }

  async trackOnboardingEvent(name?: any, extraProps: any = {}) {
    this.prepareData();
    const { user, company } = this;
    const companyStatus = company.subscription?.status === 'free' ? 'trial expired' : company.subscription?.status;
    const googleauth = !!this.getExperimentDataByName('google-auth');
    const icp1 = !!this.getExperimentDataByName('icp1');
    let props = {
      name: user?.name,
      email: user?.email,
      createdAt: company?.companyCreatedAt,
      home_cta: this.getExperimentDataByName('popup-signup-trigger'),
      companyName: company?.name,
      companyStatus,
      trialStartDate: company?.companyCreatedAt,
      trialEndDate: company?.subscription?.expires,
      planName: company?.pricingPlan,
      companyId: company?.id,
      ...await this.deviceDetector.initialize(),
      googleauth,
      icp1,
    };
    props = { ...props, ...extraProps };
    const context = {
      campaign: this.getUTMs(),
    };
    props = { ...props, ...extraProps };
    return this.track(name, props, context, true);
  }

  trackCreditCardAdded() {
    this.prepareData();
    const { company } = this;
    const amount = this.getSubscriptionAmountByPlan(company.pricingPlan);
    const props = {
      currency: 'USD',
      value: amount,
    };
    this.trackOnboardingEvent('CreditCard Offer Accepted', props);
  }

  trackCompleteOnBoarding() {
    this.prepareData();
    const { company } = this;
    const groupStatus = company.subscription.status === 'free' ? 'trial expired' : company.subscription.status;
    this.trackOnboardingEvent('Onboard Complete');
    this.logPageOrEventCall('Onboard Complete', 'event');
    if (this.analyticsEnabled() && typeof window?.analytics?.user === 'function') {
      window.analytics.user().traits({});
    }
  }

  async identify(extraTraits?: any, options?: SegmentOptions, callback?: () => any) {
    try {
      if (this.analyticsEnabled()) {
        let userTraits = await this.getUserTraits();
        userTraits = this.cleanObject(userTraits);
        const traits = {
          email: userTraits.email,
          tduserid: userTraits.tduserid,
          tdcompanyid: userTraits.tdcompanyid,
          firstname: userTraits?.firstname,
          lastname: userTraits?.lastname,
          access_level: userTraits?.access_level,
          industry: userTraits?.industry ?? 'null',
          jobtitle: userTraits?.jobtitle ?? 'null',
          isadminlogin: this.adminLogin,
        };
        window.analytics.identify(userTraits.tduserid, traits || {}, options, callback);
        if (typeof window?.analytics?.user === 'function') {
          await window.analytics.user().traits(traits);
        }
      }
    } catch (e) { }
  }

  async initIdentify(options?: SegmentOptions, callback?: () => any) {
    try {
      if (this.analyticsEnabled()) {
        this.prepareData();
        const { user, company } = this;
        const traits = {
          firstname: user.name,
          email: user.email,
          tduserid: user.id,
          tdcompanyid: company.id,
          access_level: company.role ? (company.role === 'user' ? 'regular user' : company.role) : null,
          isadminlogin: this.adminLogin,
        };
        window.analytics.identify(user.id, traits || {}, options, callback);
      }
    } catch (e) { }
  }

  async trackInAppEvents(pageName?: string, extraProps: any = {}) {
    await this.prepareData();
    const { user, company } = this;
    if (!user || !company) {
      return false;
    }
    const companyStatus = company?.subscription?.status;
    let props = {
      companyName: company.name,
      companyStatus,
      planName: company.pricingPlan,
      access_level: (company.role === 'user') ? 'regular user' : company.role,
      trackingType: company.companySettings.trackingMode,
      tdcompanyid: company.id,
      tduserid: user.id,
    };
    props = { ...props, ...extraProps };

    this.track(pageName, props, {}, false);
  }

  async trackWithUTMS(name?: any, extraProps: any = {}) {
    let userTraits: any;
    this.prepareData();
    const { user, company } = this;
    if (!user || !company) {
      return false;
    }
    if (!this.companyTraits) {
      userTraits = await this.getCompanyTraits();
    } else {
      userTraits = this.companyTraits;
    }
    const companyStatus = userTraits.status ?? company?.subscription?.status;
    let props = {
      name: user.name,
      email: user.email,
      access_level: (company.role === 'user') ? 'regular user' : company.role,
      planName: company.pricingPlan,
      trialStartDate: company.companyCreatedAt,
      trialEndDate: company.subscription.expires,
      workspace_tracking_type: userTraits.commonTraits?.userTraits?.workspace_tracking_type ?? company.companySettings.trackingMode,
    };
    props = { ...props, ...extraProps };
    const context = {
      companyName: company.name,
      companyStatus,
      campaign: {
        name: userTraits.commonTraits?.companyTraits?.utmCampaign || this.getUTMsByName('utm_campaign'),
        source: userTraits.commonTraits?.companyTraits?.utmSource || this.getUTMsByName('utm_source'),
        medium: userTraits.commonTraits?.companyTraits?.utmMedium || this.getUTMsByName('utm_medium'),
        content: userTraits.commonTraits?.companyTraits?.utmContent || this.getUTMsByName('utm_content'),
        keyword: this.getUTMsByName('utm_keyword'),
        adgroup: this.getUTMsByName('adgroup'),
      },
    };
    this.track(name, props, context, false);
  }
  async track(event: string, properties?: any, context?: any, isOnboarding?: boolean, options?: SegmentOptions, callback?: () => any) {
    this.prepareData();
    const { user, company } = this;
    if (user) {
      const props = { email: user.email, isadminlogin: this.adminLogin };
      properties = properties ? { ...properties, ...props } : props;
    }
    context = { ...context, groupId: company.id };
    properties = this.formatProperties(properties);
    if (this.analyticsEnabled()) {
      window.analytics.track(event, properties, context, options, callback);
    }
    if (this.analyticsEnabled(false, true)) {
      return this.serverSideTracking(event, properties, context);
    }
  }

  formatProperties(props) {
    const result = {};
    for (const [key, value] of Object.entries(props)) {
      if (typeof value === 'object' && value !== null) {
        result[key.toLocaleLowerCase().replace(/_/g, '')] = this.formatProperties(value);
      } else {
        result[key.toLowerCase().replace(/_/g, '')] = value;
      }
    }
    return result;
  }

  async pageOnboarding(category?: any, name?: any, properties?: any, options?: SegmentOptions, callback?: () => any) {
    if (this.getParameter('from2a')) {
      return;
    }
    if (this.analyticsEnabled()) {
      const { user, company } = this;
      if (user) {
        const props = { email: user.email };
        properties = properties ? { ...properties, ...props } : props;
      }
      window.analytics.page(category, name, properties, options, callback);
      if (category && !name) {
        name = category;
        category = null;
      }
    }
    if (this.analyticsEnabled(false, true)) {
      this.serverSidePageCall(category, name, properties);
    }
  }

  async page(category?: any, name?: any, properties?: any, referrer?: any, options?: SegmentOptions, callback?: () => any) {
    this.prepareData();
    const { user, company } = this;
    if (user) {
      const props = { email: user.email, isadminlogin: this.adminLogin };
      properties = properties ? { ...properties, ...props } : props;
    } else {
      return false;
    }
    if (name !== 'Time Doctor 2') {
      await this.updateLocalCacheFromServer();
    }
    const context = { options, context: { campaign: this.getUTMs(), referrer, groupId: company?.id || null } };
    properties = this.formatProperties(properties);
    if (this.analyticsEnabled()) {
      window.analytics.page(category, name, properties, context, options, callback);
    }
    if (this.analyticsEnabled(false, true)) {
      this.serverSidePageCall(category, name, properties, { groupId: company?.id || null });
    }
  }
  getUTMsByName(name: string) {
    return this.getCookie(name) || this.getDataLayer(name) || null;
  }

  getUTMs() {
    return {
      name: this.getUTMsByName('utm_campaign'),
      source: this.getUTMsByName('utm_source'),
      medium: this.getUTMsByName('utm_medium'),
      term: this.getUTMsByName('utm_term'),
      content: this.getUTMsByName('utm_content'),
      keyword: this.getUTMsByName('utm_keyword'),
      adgroup: this.getUTMsByName('adgroup'),
    };
  }

  async group(extraTraits?: any, options?: SegmentOptions, callback?: () => any) {
    if (this.analyticsEnabled()) {
      const companyTraits = await this.getCompanyTraits();
      const traits = { ...companyTraits, ...extraTraits };
      this.serverSideCall(traits);
    }
  }
  cleanObject(obj) {
    const propNames = Object.getOwnPropertyNames(obj);
    for (const propName of propNames) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  }
  async updateLocalCacheFromServer() {
    if (this.analyticsEnabled() && typeof window?.analytics?.user === 'function') {
      let userTraits = await this.getUserTraits();
      userTraits = this.cleanObject(userTraits);
      const traits = {
        email: userTraits.email,
        tduserid: userTraits.tduserid,
        tdcompanyid: userTraits.tdcompanyid,
        firstname: userTraits.firstname,
        lastname: userTraits?.lastname,
        access_level: userTraits.access_level,
        industry: userTraits?.industry ?? 'null',
        jobtitle: userTraits?.jobtitle ?? 'null',
        isadminlogin: this.adminLogin,
      };
      const localTraits = await window.analytics.user().traits();
      await window.analytics.user().traits(traits);
      if (userTraits && JSON.stringify(localTraits) !== JSON.stringify(traits) && this.analyticsEnabled()) {
        await window.analytics.identify(userTraits.tduserid, traits);
      }
    }
  }
  getSubscriptionAmountByPlan(plan) {
    const plans = {
      basic: '7.00',
      standard_new: '10.00',
      premium: '20.00',
      business: '12.00',
      enterpirse: '24.00',
    };
    return (plan in plans) ? plans[plan] : '10.00';
  }

  async firstOnboardingCalls(pageName) {
    const geoInfo = await this.detectCountryByIp();
    const employees = this.getExperimentDataByName('employees') ? { employees: this.getExperimentDataByName('employees') } : {};
    const contactCountry = geoInfo?.names?.en || '';
    await this.serverSideCall({ onboarding_step: pageName, ...employees }, { contact_country: contactCountry });
  }
  clearLocalTraits() {
    if (this.analyticsEnabled() && typeof window?.analytics?.user === 'function') {
      window.analytics.user().traits({});
    }
  }

  isFreeEmail(domain) {
    return freeEmailDomains.includes(domain);
  }
  cleanUrl(url: string, withWWW: boolean) {
    if (withWWW) {
      return `https://www.${url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}`;
    }
    return `https://${url.replace(/^(?:https?:\/\/)?(?:)?/i, '').split('/')[0]}`;
  }

  async serverSidePageCall(categoryName: string, pageName: string, properties = {}, context = {}) {
    context = {
      ...context,
      ...{ campaign: this.getUTMs() },
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };
    const deviceInfo = await this.deviceDetector.initialize();
    const { user, company } = this;
    properties = {
      ...properties,
      ...{
        tdcompanyid: company.id,
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
      ...deviceInfo,
    };
    this.prepareData();
    const anonymousId = await this.uuid();
    const segmentanonymousid = await this.getAnonymousId();
    const payload = {
      category: categoryName,
      name: pageName,
      userId: user.id,
      anonymousId,
      segmentanonymousid,
      properties,
      context,
      type: 'page',
    };

    this.httpClient.post(
      this.getGoogleCloudFunctionUrl() + '/api/v1/tracking?type=page&name=' + pageName,
      payload,
    ).subscribe();
    this.trackingLogger(payload);
  }

  async serverSideTracking(eventName: string, properties = {}, context = {}) {
    const excludedEvent = ['Account Created', 'Onboard Complete', 'Signed Out', 'Time Doctor 2'];
    if (excludedEvent.includes(eventName)) {
      return false;
    }
    context = {
      ...context,
      ...{ campaign: this.getUTMs() },
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };
    this.prepareData();
    const { user } = this;
    const anonymousId = await this.uuid();
    const segmentanonymousid = await this.getAnonymousId();
    const deviceInfo = await this.deviceDetector.initialize();
    properties = { ...properties, ...deviceInfo };

    const payload = {
      category: null,
      event: eventName,
      userId: user.id,
      anonymousId,
      segmentanonymousid,
      properties,
      context,
      type: 'track',
    };

    this.trackingLogger(payload);
    return lastValueFrom(this.httpClient.post(
      this.getGoogleCloudFunctionUrl() + '/api/v1/tracking?type=track&name=' + eventName,
      payload,
    ));
  }

  async logPageOrEventCall(name: string, type: string) {
    this.prepareData();
    const { user, company } = this;
    const anonymousId = await this.uuid();
    const segmentanonymousid = await this.getAnonymousId();
    const deviceInfo = await this.deviceDetector.initialize();

    const context = {
      ...{ campaign: this.getUTMs() },
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };
    const country = await this.detectCountryByIp();
    let isUEMember = country?.is_in_european_union;
    if (country.iso_code === 'GB') isUEMember = true;
    const properties = {
      ...{
        accesslevel: (company.role === 'user') ? 'regular user' : company.role,
        companyname: company?.name,
        companystatus: company?.subscription?.status,
        createdate: company.companyCreatedAt,
        email: user.email,
        googleauth: !!this.getExperimentDataByName('google-auth'),
        homecta: this.getExperimentDataByName('popup-signup-trigger'),
        iseuaccount: isUEMember,
        name: user.name,
        planname: company?.pricingPlan,
        trialenddate: company?.subscription?.expires,
        trialstartdate: company?.companyCreatedAt,
      },
      ...deviceInfo,
    };
    const payload = {
      anonymousId,
      segmentanonymousid,
      userId: user.id,
      properties,
      context,
      type,
    };
    if (type === 'event') {
      payload['event'] = name;
    } else {
      payload['name'] = name;
    }
    this.trackingLogger(payload);
  }

  trackingLogger(payload: any) {
    this.httpClient.post(
      'https://us-central1-tracking-logger-staging.cloudfunctions.net/tracking_logger',
      payload,
    ).subscribe();
  }

  async prepareTraits(extraCompanyTraits?: any, extraUserTraits?: any, sendSegment?: boolean, isAccountCreated?: boolean) {
    this.prepareData();
    const { company, user } = this;
    const companyIdAsDomain = company?.id?.replace(/[_-]/g, '');
    const emailDomain = extraCompanyTraits?.website ?
      `${extraCompanyTraits.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}` :
      user.email.substring(user.email.lastIndexOf('@') + 1);
    let origDomain = emailDomain;
    let userDomain = emailDomain;
    const isFreeEmail = this.isFreeEmail(emailDomain);
    if (isFreeEmail) {
      userDomain = `www.${companyIdAsDomain}.com`;
      origDomain = userDomain;
    }
    let userTraits = await this.getUserTraits();
    userTraits = { ...userTraits, ...extraUserTraits };
    const mainTraits = await this.getCompanyTraits();

    const companyTraits = {
      ...mainTraits,
      ...extraCompanyTraits,
    };
    companyTraits.td_website_url = (
      !(companyTraits.td_website_url ||
        companyTraits.td_website_url !== '') ||
      extraCompanyTraits?.website
    ) ? origDomain : companyTraits.td_website_url;
    companyTraits.td_website_url = this.cleanUrl(companyTraits.td_website_url, true);
    if (!!isAccountCreated) {
      companyTraits['anonymousId'] = await this.uuid();
      companyTraits['segmentanonymousid'] = await this.getAnonymousId();
      userTraits['googleauth'] = !!this.getExperimentDataByName('google-auth');
      const country = await this.detectCountryByIp();
      let isUEMember = country?.is_in_european_union;
      if (country.iso_code === 'GB') { isUEMember = true; }
      companyTraits['iseuaccount'] = isUEMember;
    }
    const traits = { userTraits, companyTraits, send_segment: !!sendSegment, isAccountCreated: !!isAccountCreated };
    if (!this.commonTraits?.companyTraits && !isAccountCreated) {
      return null;
    }
    this.commonTraits.userTraits = userTraits;
    this.commonTraits.companyTraits = companyTraits;
    if (traits.companyTraits?.status) {
      delete traits.companyTraits.status;
    }

    return traits;
  }

  dataForExternalTracking(userData: DataForExternalTracking | null) {
    const dataForTracking = userData ? btoa(JSON.stringify(userData)) : null;
    const daysToExpire = dataForTracking ? 7 : 0;
    this.setCookie('__tdudft', dataForTracking, daysToExpire);
  }

  prepareQueryParams(params: string[]): string {
    if (!params.length) return '';

    return `?${params.join('&')}`;
  }

  async serverSideCall(
    extraCompanyTraits?: any,
    extraUserTraits?: any,
    sendSegment?: boolean,
    isAccountCreated?: boolean,
    queryParams: string[] = [],
  ) {
    if (this.analyticsEnabled(false, true)) {
      const isAutomation = this.getParameter('automation') ?? window?.localStorage?.getItem('automation');
      if (isAutomation) queryParams.push('automation=1');

      const traits = await this.prepareTraits(extraCompanyTraits, extraUserTraits, sendSegment, isAccountCreated);
      if (!traits) return;

      const allQueryParams = this.prepareQueryParams(queryParams);

      return lastValueFrom(this.httpClient.post(
        this.getGoogleCloudFunctionUrl() + '/api/v1/traits' + allQueryParams,
        traits,
      ));
    }
  }

  async getCommonTraits() {
    if (window.location.pathname === '/dashboard' || this.commonTraits !== undefined) { return; }
    this.prepareData();
    const { user, company } = this;

    if (this.commonTraits?.length || !user || !company) {
      return this.commonTraits;
    }
    const traits = await lastValueFrom(this.httpClient.get<any>(
      this.getGoogleCloudFunctionUrl() + '/api/v1/traits/?tduserid=' + user.id + `&tdcompanyid=` + company.id));
    let userTraits = null;
    let companyTraits = null;
    if (traits?.data?.userTraits[0]) {
      delete traits.data.userTraits[0].id;
      userTraits = traits.data.userTraits[0];
    }
    if (traits?.data?.companyTraits[0]) {
      delete traits.data.companyTraits[0].id;
      companyTraits = traits.data.companyTraits[0];

    }
    this.commonTraits = {
      userTraits,
      companyTraits,
    };
    return this.commonTraits;
  }

  trackPartnerStackSignup(user) {
    const isGSPK = this.getCookie('growSumoPartnerKey') || (this.getParameter('gspk') && this.getParameter('gsxid'));
    if (isGSPK) {
      window.dataLayer.push({
        event: 'partnerStackSignUp',
        partnerStackEmail: user.email,
        partnerStackName: user.name,
        partnerStackCustKey: user.id,
      });
    }
  }
  async trackExperiment(id: string, name: string, variation: any = {}, audience?: string, extraProps: any = {}) {
    await this.prepareData();
    const { user, company } = this;
    const tdstatus = company.subscription?.status === 'free' ? 'trial expired' : company.subscription?.status;

    let props = {
      email: user?.email,
      experiment_id: id,
      experiment_name: name,
      variation_id: variation.id,
      variation_name: variation.name,
      audienceName: audience,
      tdcompanyid: company?.id,
      tdstatus,
      createdate: company?.companyCreatedAt,
      plan: this.commonTraits?.companyTraits?.plan ?? company?.pricingPlan,
      homecta: this.getExperimentDataByName('popup-signup-trigger'),
      trialstarttime: company?.companyCreatedAt,
      trialendtime: company?.subscription?.expires,
      workspacetrackingtype: company?.companySettings?.trackingMode,
      subscriptionstarted: company?.companyCreatedAt,
    };
    props = { ...props, ...extraProps };

    const cookieVisit = JSON.parse(this.getCookieExp(id)) ?? null;

    if (!cookieVisit?.tracked) {
      await this.track('Experiment Viewed', props, {}, true);
    }
  }

  getCookieExp(name) {
    const match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));
    return match ? match[1] : null;
  }

  async getSplitTestVersion(testsName?: string): Promise<{ version: string, tdAnonymousId: string }> {
    let stVisit: { version: string, tdAnonymousId: string };
    const cookieVisit = this.getCookie(testsName);
    stVisit = !cookieVisit ? { version: null, tdAnonymousId: '' } : JSON.parse(cookieVisit);
    if (!stVisit?.version) {
      const stVersion = await this.httpClient.get<any>(
        this.getGoogleCloudFunctionUrl() + '/api/v1/split-tests?testName=' + testsName).toPromise();
      stVisit = stVersion?.data;
      if (!stVisit?.version) {
        stVisit.version = 'C';
      }
      this.setCookie(testsName, JSON.stringify(stVisit), 365);
    }
    return stVisit;
  }

  async uuid() {
    this.prepareData();
    const { company } = this;
    const customUserSettings = company.userSettings?.custom as ExtCustomCompanySettings;
    let uuid = customUserSettings.tdAnonymousId;
    const tdAnonymousIdFromCookie = this.getCookie('td_anonymousId');
    if (!uuid) {
      uuid = tdAnonymousIdFromCookie;
      if (!uuid) {
        let dt = new Date().getTime();
        uuid = 'TD-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          // eslint-disable-next-line no-bitwise
          const r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          // eslint-disable-next-line no-bitwise
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        this.setCookie('td_anonymousId', uuid, 365);
      }
      await lastValueFrom(this.userService.editProperties('me', { 'custom.tdAnonymousId': uuid } as any));
      await lastValueFrom(this.store.dispatch(new UserSettingsChanged(null, { tdAnonymousId: uuid })));
    }
    if (tdAnonymousIdFromCookie !== uuid) {
      this.setCookie('td_anonymousId', uuid, 365);
    }
    return uuid;
  }

  getCookie(name: string) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) { return decodeURI(match[2]) || null; }
    return null;
  }

  getGoogleCloudFunctionUrl() {
    let firebaseFunctionUrl = 'https://data-integrity.timedoctortest.com';
    if (this.getDomain() === 'timedoctor.com') {
      firebaseFunctionUrl = 'https://data-integrity.timedoctor.com';
    }
    return firebaseFunctionUrl;
  }

  getDomain() {
    const domain = window.location.hostname;
    const subdomains = domain.split('.').reverse();
    return subdomains[1]?.includes('timedoctor') ? `${subdomains[1]}.${subdomains[0]}` : '';
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    const domain = window.location.hostname;
    const subdomains = domain.split('.').reverse();
    const subdomain = subdomains[1]?.includes('timedoctor') ? `${subdomains[1]}.${subdomains[0]}` : domain;
    if (subdomain !== '') {
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=' + subdomain;
    }
  }

  removeCookie(cname) {
    if (this.getCookie(cname)) {
      const domain = window.location.hostname;
      const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
      const subdomains = domain.split('.').reverse();
      const subdomain = `${subdomains[1]}.${subdomains[0]}`;
      document.cookie = cname + '=' + ';' + expires + ';path=/;domain=' + subdomain;
    }
  }

  getDataLayer(prop) {
    const dataLayer = (window as any).dataLayer;
    if (!dataLayer) {
      return null;
    }
    for (const index in dataLayer) {
      if (dataLayer[index][prop]) {
        const dataLayerData = decodeURI(dataLayer[index][prop])
          && decodeURI(dataLayer[index][prop]) !== 'undefined' ? decodeURI(dataLayer[index][prop]) : null;
        return dataLayerData || null;
      }
    }
    return null;
  }

  getExperimentDataByName(exprName: string): any {
    this.prepareData();
    const { company } = this;
    const experiments = company?.splitTest || [];
    for (const expr of experiments) {
      if (expr.name === exprName) {
        return expr.value || '';
      }
    }
    return '';
  }

  getParameter(parameterName) {
    let result = null;
    let tmp = [];
    location.search
      .substr(1)
      .split('&')
      .forEach(item => {
        tmp = item.split('=');
        if (tmp[0] === parameterName) { result = decodeURIComponent(tmp[1]); }
      });
    return result;
  }

  analyticsEnabled(enableForAutomation: boolean = false, serverSideTracking: boolean = false): boolean {
    if (window?.localStorage?.getItem('automation') && !enableForAutomation) {
      return false;
    }
    if (this.getParameter('automation')) {
      window.localStorage.setItem('automation', this.getParameter('automation'));
      if (!enableForAutomation) {
        return false;
      }
    }

    if (!serverSideTracking && !(window.analytics && environment.enableAnalytics && this.branding.isDefault)) {
      return false;
    }

    this.adminLogin = this.store.selectSnapshot((x: AppState) => x.auth.adminLogin) || false;

    if (!this.user) {
      this.prepareData();
    }

    if (!this.user) {
      return false;
    }

    if (/@timedoctor(dev|test)\.com$/i.test(this.user.email)) {
      // do not allow timedoctordev.com and timedoctortest.com emails
      return false;
    }

    if (this.getDomain() === 'timedoctor.com') {
      return !/@(timedoctor|staff)\.(com|dev)$/i.test(this.user.email);
    }

    return true;
  }

  async updateUserTraits(userTraits?: any, sendSegment?: boolean) {
    const isAutomation = this.getParameter('automation') ?? window?.localStorage?.getItem('automation');
    const companyTraits = await this.getCompanyTraits();
    const traits = { userTraits, companyTraits, send_segment: !!sendSegment };
    const automationParam = isAutomation ? '?automation=1' : '';
    this.httpClient.post(
      this.getGoogleCloudFunctionUrl() + '/api/v1/traits' + automationParam,
      traits,
    ).subscribe();
  }

  async directivesData(type: string, context: Partial<TrackingContextType>) {
    let props = {};
    const pageEvent = context.page;
    delete context.page;
    const basicOptions = ['tabs', 'columns'];
    if (pageEvent === undefined) return false;
    if (context.hasOwnProperty('option')) {
      switch (context.option) {
        case 'timezone':
          delete context.option;
          props = transformTimezoneSelection(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'userpulldown':
          if (!context.userData) break;
          delete context.option;
          if (!context?.userData) break;
          props = transformUserLazySelection(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'periodpicker':
          delete context.option;
          props = transformPeriodPickerSelection(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case basicOptions.find((x) => x === context.option):
          delete context.option;
          delete Object.assign(context, { ['selectedoption']: context['newValue'] })['newValue'];
          context['selectedoption'] = { value: context['selectedoption'] };
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'periodTabs':
          delete context.option;
          if (context.newValue !== 'days') {
            props = transformPeriodTabsSelection(context);
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'sortColumns':
          delete context.option;
          if (context.sort.direction !== '') {
            props = transformSortColumnsSelection(context);
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'stackChart':
          delete context.option;
          if (context?.newValue !== null) {
            props = transformStackChartSelection(context);
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'entityFilter':
          delete context.option;
          props = transformEntityFilter(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'userlazy':
          delete context.option;
          props = await transformRadioUserSelection(context);
          if (props !== null) this.trackInAppEvents(pageEvent, props);
          break;
        case 'timetube':
          const selectedWorklog = context?.selectedWorklog;
          delete context.option;
          delete context.page;
          delete context.selectedWorklog;
          context.selectedOption = transformTimeTubeSelection(selectedWorklog);
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'editTimeTransform':
          delete context.option;
          props = transformEditTime(context, this.clockPipe);
          if (props['selectedOption'].value !== null) this.trackInAppEvents(pageEvent, props);
          break;
        case 'selectedTab':
          delete context.option;
          props = transformTabsSelection(context);
          if (props['selectedOption'].value !== null) {
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'ratioMinHours':
          delete context.option;
          context.newValue = context.newValue / 3600;
          props = transformNewValue(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'highestidle':
          delete context.option;
          props = transformHighestIdleWidget(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'selectedtabtoname':
          delete context.option;
          context.name = 'Clicked ' + context.selectedTab.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
          delete context.selectedTab;
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'activitysummary':
          if (!context?.newValue) break;
          context.selectedOption = transformActivitySummary(context);
          delete context.option;
          delete context.newValue;
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'exportbutton':
          delete context.option;
          const organization = context.selectedOption.organization.toLowerCase();
          const userIdentification = context.selectedOption.userFields;
          const newObject = Object.assign(
            {}, context.selectedOption,
            {
              content: undefined,
              userFields: undefined,
              userAdditionalColumns: undefined,
              organization: (context?.sidepanel) ? undefined : organization,
              userIdentification,
            });

          this.trackInAppEvents(pageEvent, { name: context?.name, location: context?.location, selectedOption: newObject });
          break;
        case 'treeReport':
          context.selectedOption = transformTreeReport(context);
          delete context.option;
          delete context.selectedView;
          delete context.timesListEnabled;
          this.trackInAppEvents(pageEvent, context);
          break;
      }
    } else {
      props = transformNewValue(context);
      this.trackInAppEvents(pageEvent, props);
    }
  }

  getCompanyStatus() {
    this.prepareData();
    const company = this.company;
    if (company.id !== undefined) {
      const payload = {
        tdcompanyid: company.id,
        company: company.id,
      };
      return lastValueFrom(this.httpClient.get(
        this.getGoogleCloudFunctionUrl() + `/api/v1/traits?tdcompanyid=${payload.tdcompanyid}`,
      ));
    } else {

      return new Promise((resolve) => { resolve(null); });
    }

  }

  async updateCompanySettings(settings) {
    await this.companyService.putCompanySettings(this.company.id, settings).toPromise();
  }

  async detectCountryByIp() {
    if (this.userGeoInfo) return this.userGeoInfo;
    let apiUrl = '';
    const domain = this.getDomain();
    if (domain === 'timedoctortest.com' || domain === 'timedoctordev.com') {
      apiUrl = 'https://www.timedoctortest.com';
    } else {
      apiUrl = 'https://www.timedoctor.com';
    }
    try {
      const res = await fetch(apiUrl + '/userCountryService.php', {
        method: 'GET',
      });
      const data = await res.json();
      this.userGeoInfo = data;
      return this.userGeoInfo;
    } catch (e) { }
  }

  getCompanyName(company: string, email: string) {
    this.prepareData();
    let companyname: string | null = null;
    if (!this.isFreeEmail(email)) {
      if (company) {
        companyname = company;
        this.sendBusinnesCompanyUpdates(companyname);
      } else {
        companyname = email?.substring(email.lastIndexOf('@') + 1).split('.')[0];
        this.sendBusinnesCompanyUpdates(companyname);
      }
    } else {
      this.trackInAppEvents('Email Type', { classification: 'free' });
    }
    return companyname;
  }

  private sendBusinnesCompanyUpdates(companyname: string) {
    this.updateCompanySettings({ name: companyname?.trim() });
    this.trackInAppEvents('Email Type', { companyname, classification: 'business' });
  }
}
