import { Injectable } from '@angular/core';
import { SocketAdminMessage, SocketApiResult, SocketData, SocketService, StoreReadyService } from '@shared';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NoopSocketService implements Pick<SocketService, keyof SocketService> {
  public initialized: Promise<void>;
  socketApiResult: Observable<SocketApiResult<any>> = EMPTY;
  notify: Observable<SocketData<any>> = EMPTY;
  msg: Observable<SocketAdminMessage> = EMPTY;
  disconnect: Observable<unknown> = of(null);
  onConnection: Observable<string> = of('');

  constructor(
    private storeReady: StoreReadyService,
  ) {
    this.initialized = this.init();
  }

  private async init() {
    await this.storeReady.ready;
  }

  get connected(): boolean {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  emit(event: string, ...args: any[]): void { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  subscribe(userIds: string[], companyId: string, detail?: string | string[]): void { }

  resource<T = any>(resource: string, method?: 'post' | 'put' | 'delete'): Observable<SocketData<T>> {
    return EMPTY;
  }

  onceConnected(combinedId?: string): Observable<string> {
    return of(combinedId);
  }
}
