import { Injectable } from '@angular/core';
import { ApiService } from '@shared';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthCompany, Company, CompanySettings, SsoProviderParams, SsoProviderType } from 'src/models';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private api: ApiService) { }

  editCompanySettings(company: AuthCompany, properties: (keyof CompanySettings)[]) {
    const data = {};
    for (const prop of properties) {
      data[prop] = company.companySettings[prop];
    }

    return this.api.request('put', `companies/${company.id}`, data);
  }

  putCompanySettings(company: string, data: Partial<CompanySettings & Company>) {
    return this.api.request('put', `companies/${company}`, data);
  }

  getCompany(id: string) {
    return this.api.request<{ data: Company }>('get', `companies/${id}`).pipe(map(x => x.data));
  }

  getTimezones(id: string) {
    return lastValueFrom(this.api.request<{ data: { timezones: string[] } }>('get', `companies/${id}/timezones`)
      .pipe(map(x => x?.data?.timezones?.filter(t => t))));
  }

  getSsoProviders(id: string) {
    return lastValueFrom(this.api.request<{ data: (SsoProviderParams & { provider: SsoProviderType })[] }>('get', `companies/${id}/sso`)
      .pipe(map(x => x?.data)));
  }

  getSsoProvider(id: string, provider: SsoProviderType) {
    return lastValueFrom(
      this.api.request<{ data: (SsoProviderParams & { provider: SsoProviderType }) }>('get', `companies/${id}/sso/${provider}`)
        .pipe(map(x => x?.data)));
  }

  deleteSsoProvider(id: string, provider: SsoProviderType) {
    return lastValueFrom(this.api.request('delete', `companies/${id}/sso/${provider}`));
  }

  setSsoProvider(id: string, provider: SsoProviderType, { ...params }: SsoProviderParams) {
    return lastValueFrom(this.api.request('put', `companies/${id}/sso/${provider}`, params));
  }
}
