import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ERROR_URL } from 'src/app/app/app.constants';
import { isOwner } from 'src/app/common/menu-def.provider';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class PaidProtectedGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(ERROR_URL) private errorUrl,
  ) { }

  canActivate() {
    const company = this.store.selectSnapshot(AuthState.company);
    const isFreeSubscription = company.subscription.status === 'free';

    if (isFreeSubscription && isOwner(company)) {
      return this.router.createUrlTree(['/billing']);
    } else if (isFreeSubscription && !isOwner(company)) {
      return this.router.createUrlTree([this.errorUrl, 'insufficientPlan']);
    }
    return true;
  }

  canActivateChild() {
    return this.canActivate();
  }

  canLoad() {
    return this.canActivate() === true;
  }
}
