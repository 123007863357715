import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutofocusType, ValueAccessorBase } from '@shared';

type InlineEditType = string | number;

@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineEditComponent),
      multi: true,
    },
  ],
})
export class InlineEditComponent extends ValueAccessorBase<InlineEditType> {
  @Input() editing = false;
  @Input() continueAfterSubmit = false;
  @Input() canEdit = true;
  @Input() showEditButton = true;
  @Input() type = 'text';
  @Input() min: number;
  @Input() maxLength = 10000;
  @Input() eager = false;
  @Input() autofocus: AutofocusType = 'all';
  @Input() placeholder = '';
  @Input() showErrorIcon = true;

  @Input() error = '';
  @Input() submitCallback: (value: string) => any;

  @Output() startEditing = new EventEmitter();
  @Output() endEditing = new EventEmitter();
  @Output() saved = new EventEmitter();
  @Output() cancelled = new EventEmitter();


  private previousValue: InlineEditType;
  private tempValueInner: InlineEditType;

  get tempValue() { return this.tempValueInner; }
  set tempValue(value: InlineEditType) {
    if (this.type === 'number') {
      value = parseFloat(value as any || 0);
    }
    this.tempValueInner = value;

    if (this.eager) {
      this.value = this.tempValueInner;
    }
  }

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  writeValue(value: InlineEditType) {
    super.writeValue(value);
    this.tempValue = value;
    this.cd.detectChanges();
  }

  edit(event?: Event) {
    if (event) { event.stopPropagation(); }
    if (!this.canEdit || this.editing) { return; }
    this.editing = true;
    this.previousValue = this.value;
    this.startEditing.emit();
    this.cd.markForCheck();
  }

  async save(value, event?: Event) {
    if (value === undefined) { value = this.tempValue; }

    if (event) { event.stopPropagation(); }

    try {
      this.error = null;

      if (this.submitCallback) {
        await this.submitCallback(value);
      }

      if (this.continueAfterSubmit) {
        this.tempValue = '';
      } else {
        this.editing = false;
      }

      if (this.value !== value) {
        this.value = value;
      }

      this.endEditing.emit();
      this.saved.emit(this.value);
    } catch (err) {
      this.error = (err && err.message) || err;
      this.cd.markForCheck();
    }
  }

  cancel(event?: Event) {
    if (event) { event.stopPropagation(); }
    const cancelledValue = this.tempValue;
    this.editing = false;
    this.tempValue = this.previousValue;
    this.error = null;
    this.endEditing.emit();
    this.cancelled.emit(cancelledValue);
  }
}
