import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { ClockPipe } from '@shared';
import { Duration, DurationUnit } from 'luxon';
import { AuthState } from 'src/app/store/auth/auth.state';


@Injectable()
@Pipe({
  name: 'clockRange',
})
export class ClockRangePipe implements PipeTransform {
  constructor(private clock: ClockPipe, private store: Store) { }

  transform(value: { start: number, end: number }, unit: DurationUnit = 'seconds', separator = ' - '): string {
    const st = Duration.fromObject({ [unit]: value.start });
    const en = Duration.fromObject({ [unit]: value.end });

    const sameMeridian = (en > st) && (Math.floor(st.as('hours') / 12) === Math.floor(en.as('hours') / 12));
    const forceMinutes = this.store.selectSnapshot(AuthState.hourFormat24);

    return `${this.transformPart(st, !sameMeridian, forceMinutes)}${separator}${this.transformPart(en, true, forceMinutes)}`;
  }

  private transformPart(val: Duration, meridian: boolean, forceMinutes: boolean) {
    const hasMinutes = forceMinutes || !Number.isInteger(val.as('hours'));

    const format = `h${hasMinutes ? ':mm' : ''}aa`;

    const res = this.clock.transform(val.as('seconds'), 'seconds', format);

    if (!meridian) { return res.replace('am', '').replace('pm', ''); }
    return res;
  }
}
