import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Duration, DurationLike, DurationUnit } from 'luxon';

const defaultUnits: DurationUnit[] = ['month', 'day', 'hour', 'minute', 'second'];

@Injectable()
@Pipe({
  name: 'durationHumanize',
})
export class DurationHumanizePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(
    value: DurationLike,
    units: DurationUnit[] = [],
    maxUnits = 3,
    separator = ' ',
    translationPrefix = 'time.duration',
  ): string {
    if (!units || !units.length) {
      units = defaultUnits;
    }

    let dur = Duration.fromDurationLike(value).normalize();
    const parts = [];

    for (const unit of units) {
      const dd = Math.floor(dur.as(unit));
      dur = dur.minus({ [unit]: dd });

      if (dd > 0) {
        parts.push(this.translate.instant(`${translationPrefix}.#${unit}`, { count: dd }));
      }

      if (parts.length >= maxUnits) {
        break;
      }
    }

    return parts.join(separator);
  }
}
