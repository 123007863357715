import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { DialogTrackModeLearnMoreComponent } from '../../pages/onboarding/onboarding.component';

@UntilDestroy()
@Component({
  selector: 'app-onboarding-footer',
  templateUrl: './onboarding-footer.component.html',
  styleUrls: ['./onboarding-footer.component.scss'],
})

export class OnboardingFooterComponent {
  @Input() showLink = false;
  @Input() company = false;
  @Input() withContent = false;

  constructor(private segment: SegmentService, public dialog: MatDialog) {
  }

  openTrackModeLearnDialog(): void {
    const dialogRef = this.dialog.open(DialogTrackModeLearnMoreComponent, {
      width: '650px',
      height: '650px',
      data: {},
    });
    const currentUrl = 'https://2a.' + this.segment.getDomain() + '/onboarding';
    this.segment.track('Clicked Learn More Onboarding', { category: 'Onboarding', url: currentUrl }, {}, true);
  }
}
