import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[scrollIntoView]',
})
export class ScrollIntoViewDirective implements AfterContentInit {
  @Input() scrollIntoView: boolean | ScrollIntoViewOptions;

  public constructor(private el: ElementRef) { }

  public ngAfterContentInit() {
    setTimeout(() => {
      const el: HTMLElement = this.el.nativeElement;
      if (this.scrollIntoView === false) { return; }

      let scrollMode: ScrollIntoViewOptions = this.scrollIntoView as any;

      if (this.scrollIntoView === true) {
        scrollMode = { block: 'center' };
      }

      el.scrollIntoView(scrollMode);
    }, 100);
  }
}
