<span fxFlex class="ellipsis" [class.editing-content]="editing && canEdit">
  <ng-content></ng-content>
</span>

<span class="edit-buttons" [class.editing-content]="editing && canEdit">
  <ng-content select="[prefix-buttons]"></ng-content>

  <button *ngIf="canEdit && showEditButton" class="compact edit-button" mat-icon-button
    [matTooltip]="'common.edit' | translate" (click)="edit($event)">
    <mat-icon>edit</mat-icon>
  </button>

  <ng-content select="[suffix-buttons]"></ng-content>
</span>

<ng-container *ngIf="editing && canEdit">
  <div class="input-container" fxLayoutAlign="start center">
    <input #valueInput stopPropagation [autofocus]="autofocus" fxFlex [type]="type" [value]="value"
      [maxLength]="maxLength" [min]="min" [(ngModel)]="tempValue" [placeholder]="placeholder"
      (keyup.enter)="save(tempValue)" (keyup.escape)="cancel()" />

    <mat-icon *ngIf="error && showErrorIcon" class="compact error-icon" [matTooltip]="error | translatex">
      error
    </mat-icon>

    <button mat-icon-button [matTooltip]="'common.cancel' | translate" class="compact" (click)="cancel($event)">
      <mat-icon class="cancel-icon">close</mat-icon>
    </button>

    <button mat-mini-fab color="primary" [matTooltip]="'common.save' | translate" class="mat-elevation-z0 compact"
      (click)="save(tempValue, $event)">
      <mat-icon style="color: white;" class="save-icon">check</mat-icon>
    </button>
  </div>
</ng-container>
