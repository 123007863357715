<ng-container *ngIf="authorized$ | async">
  <ng-container *ngIf="company$ | async as company">
    <mat-toolbar *ngIf="user$ | async as user">
      <a routerLink="/" class="home-link" routerLinkActive="active">
        <img [src]="branding.getLogoGray()" alt="Timedoctor2 Logo" width="32">
      </a>
      <button mat-icon-button (click)="navOpen()" fxHide.gt-sm class="menu-toggle">
        <mat-icon>menu</mat-icon>
      </button>

      <div fxHide.lt-md fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="stretch stretch">
        <ng-container *ngFor="let item of menuDef.items">
          <ng-container *ngIf="!item.condition || item.condition(company)">
            <ng-container *ngIf="!$any(item).children; else buttonWithChildren">
              <a class="toolbar-button" [routerLink]="$any(item).routerLink" routerLinkActive="active">
                {{ item.name | translate | uppercase }}
              </a>
            </ng-container>

            <ng-template #buttonWithChildren>
              <button mat-button class="toolbar-button" [matMenuTriggerFor]="subMenu"
                [class.active]="anyChildActive($any(item))">
                {{ item.name | translate | uppercase }}
                <mat-icon inline>keyboard_arrow_down</mat-icon>
              </button>

              <mat-menu #subMenu="matMenu">
                <ng-container *ngFor="let sub of $any(item).children">
                  <ng-container *ngIf="!sub.condition || sub.condition(company)">
                    <a mat-menu-item [routerLink]="sub.routerLink" routerLinkActive="active" style="min-width: 180px;">
                      {{ sub.name | translate }}
                    </a>
                  </ng-container>
                </ng-container>
              </mat-menu>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>

      <div style="flex: 1 1 auto;"></div>

      <button *ngIf="company.role !== 'user' && company.role !== 'guest' && branding.isDefault" mat-icon-button
        [matMenuTriggerFor]="helpMenu" class="help-button">
        <mat-icon svgIcon="help_outline"></mat-icon>
      </button>

      <mat-menu #helpMenu="matMenu" xPosition="before" yPosition="below">
        <a *ngFor="let helpLink of getHelpLinks()" mat-menu-item target="_blank" rel="noopener"
          href="{{helpLink.url}}">{{ ('header.help.titles.' + helpLink.title) | translate }}</a>
        <mat-divider *ngIf="getHelpLinks().length"></mat-divider>
        <a mat-menu-item target="_blank" rel="noopener" href="https://support.timedoctor.com/en/support/home">{{
          'header.help.titles.helpCenter' | translate }}</a>
        <button mat-menu-item (click)="openBubbleMessage()">{{ 'header.help.titles.contactUs' | translate }}</button>
      </mat-menu>

      <button *ngIf="hasMultipleCompanies else singleCompany" class="toolbar-button ellipsis company-button" mat-button
        [matMenuTriggerFor]="companySelectMenu">
        <span>{{ company.name }}</span>
      </button>

      <ng-template #singleCompany>
        <button class="toolbar-button ellipsis company-button" mat-button>
          <span>{{ company.name }}</span>
        </button>
      </ng-template>

      <button fxFlex="0 10000 auto" class="toolbar-button ellipsis profile-button" mat-button
        [matMenuTriggerFor]="profileMenu">
        <span class="user-name" fxHide.xs>{{ user.name }}</span>
        <shared-avatar [name]="user.name" [size]="32"></shared-avatar>
      </button>

      <mat-menu #companySelectMenu="matMenu" xPosition="before" yPosition="below">
        <button *ngFor="let sCompany of companies$ | async" mat-menu-item style="min-width: 180px;"
          (click)="company !== sCompany && selectCompany(sCompany)">{{ sCompany.name }}</button>
      </mat-menu>

      <mat-menu #profileMenu="matMenu" xPosition="before" yPosition="below">
        <a mat-menu-item routerLink="profile-edit">{{ 'header.editProfile' | translate }}</a>
        <button mat-menu-item routerLink="/logout" style="min-width: 160px;">{{ 'header.logout' | translate }}</button>
      </mat-menu>
    </mat-toolbar>
  </ng-container>
</ng-container>
