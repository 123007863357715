import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { animationFrameScheduler, Observable, scheduled } from 'rxjs';
import { distinctUntilChanged, map, repeat } from 'rxjs/operators';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'truncated-text',
  templateUrl: './truncated-text.component.html',
  styleUrls: ['./truncated-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TruncatedTextComponent implements AfterViewInit {
  @ViewChild('showTooltip', { static: true }) showTooltip: ElementRef<HTMLSpanElement>;

  shouldDisableTooltip: Observable<boolean>;
  @Input() text = '';
  @Input() tooltip = '';
  @Input() disabled = false;
  @Input() position: TooltipPosition = 'below';

  ngAfterViewInit() {
    this.shouldDisableTooltip = scheduled([0], animationFrameScheduler).pipe(
      repeat(),
      untilDestroyed(this),
      map(() => !this.showTooltip || this.showTooltip.nativeElement.offsetWidth > 1),
      distinctUntilChanged(),
    );
  }
}
