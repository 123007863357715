import { Project } from 'src/models';
import integrations from '../pages/integrations/integrations-list';

export function getIntegrationNameByProjectId(projectId: string) {
  if (projectId && /^[a-z0-9_]+-.*/.test(projectId)) {
    const integrationId = projectId.split('-')[0];

    const capitalizedId = integrationId.split('_').map(str => str.substr(0, 1).toUpperCase() + str.substr(1, str.length)).join(' ');
    const integration = integrations.find(i => i.id === integrationId.replace(' ', '_'));
    return integration ? integration.shortName || integration.title : capitalizedId;
  }
  return null;
}

export function getIntegrationName(project: Partial<Project>) {
  if (project.integration?.data && project.integration.data.integrationName) {
    return project.integration.data.integrationName;
  }
  return getIntegrationNameByProjectId(project.id);
}

export function getIntegrationProjectName(project: Partial<Project>) {
  const intName = getIntegrationName(project);
  return intName && !project.name?.startsWith(`${intName}:`) ? `${intName}: ${project.name}` : project.name;
}
