<div class="onboarding-header" id="header-onboarding">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div><img class="onboarding-logoTD" src="/assets/images/brand/timedoctor/logo-full.png" alt="Time Doctor logo"/>
    </div>
    <div class="onboarding-steps-count">Step {{stepCount}}</div>
  </div>
  <div class="onboarding-progress-bar">
    <mat-progress-bar *ngIf="progress !== undefined" [value]="progress"></mat-progress-bar>
  </div>
</div>

<div class="onboarding-header-mobile">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div><img class="onboarding-logoTD" src="/assets/images/brand/timedoctor/logo-full.png" alt="Time Doctor logo"/>
    </div>
    <div class="onboarding-steps-count">
      Step {{stepCount}}
      <div class="onboarding-progress-bar">
        <mat-progress-bar [value]="progress"></mat-progress-bar>
      </div>
    </div>

  </div>
<hr class="mobile-hr">
</div>
