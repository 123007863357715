import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { autofocus, AutofocusType, ValueAccessorBase } from '@shared';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    },
  ],
})
export class SearchComponent extends ValueAccessorBase<string> implements AfterViewInit {
  @ViewChild('inputElement', { static: false }) inputElement: ElementRef<HTMLInputElement>;

  get text() {
    return this.value;
  }

  set text(value: string) {
    this.setDebouncedValue(value, this.debounce);
  }

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() showClear = true;
  @Input() alwaysShowClear = false;
  @Input() debounce = 300;
  @Input() placeholder: string;
  @Input() autofocus: AutofocusType;

  ngAfterViewInit() {
    if (this.autofocus && this.inputElement) {
      autofocus(this.inputElement.nativeElement, this.autofocus);
    }
  }

  focus() {
    if (this.inputElement) {
      autofocus(this.inputElement.nativeElement, this.autofocus);
    }
  }

  enterDown() {
    this.search.next(this.text);
  }

  clear() {
    this.value = '';
    this.enterDown();
  }
}
