export enum TimeuseScore {
  Unrated = 0,
  Unused = 1,
  Unproductive = 2,
  Neutral = 3,
  Productive = 4,
}

export enum TimeuseEntityType {
  App = 2,
  Domain = 5,
}

export type TimeuseEntity = 'app' | 'domain';
export type TimeuseScope = 'global' | 'workspace';
export type TimeuseType = 'app' | 'web';

export interface TimeuseCategory {
  id: string;
  entity: TimeuseEntity;
  name: string;
  scope: TimeuseScope;
  score: TimeuseScore;
  time: number;
}

export interface Timeuse {
  start: string;
  time: number;
  score: TimeuseScore;
  category: TimeuseCategory;
  type: TimeuseType;
  value: string;
  title: string;
}
