import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface InAppVisitReferrer {
  'type': string;
  'name': string;
  'path': string;
  'url': string;
}

@Injectable({ providedIn: 'root' })
export class UrlService {
  private previousUrl: BehaviorSubject<InAppVisitReferrer> = new BehaviorSubject<InAppVisitReferrer>(null);
  previousUrl$: Observable<InAppVisitReferrer> = this.previousUrl.asObservable();

  setPreviousUrl(inAppVisitReferrer: InAppVisitReferrer) {
    this.previousUrl.next(inAppVisitReferrer);
  }

  getPageNameByPath(path: string, pageName: string): { category?: string, pageName?: string } {
    let category = null;
    switch (path) {
      case 'dashboard': {
        pageName = 'Team Dashboard';
        category = 'Webapp Dashboard';
        break;
      }
      case 'dashboard-individual': {
        pageName = 'Individual Dashboard';
        category = 'Webapp Dashboard';
        break;
      }
      case 'activity-summary':
      case 'hours-tracked':
      case 'projects-report':
      case 'timeline':
      case 'web-app-usage':
      case 'csv': {
        category = 'Webapp Reports';
        break;
      }
      case 'billing':
      case 'projects-and-tasks':
      case 'productivity-ratings':
      case 'manage-users':
      case 'manage-user-groups':
      case 'integrations':
      case 'company/edit': {
        category = 'Webapp Settings';
        break;
      }
      case 'edit-time': {
        category = 'Webapp Edit Time';
        break;
      }
      default: { }
    }
    return { category, pageName };
  }

}
