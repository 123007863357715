<div fxLayout="column" fxLayoutAlign="center center" fxFlex [class]="cssClass">
  <div fxFlex="1 1 auto"></div>

  <div class="icon">
    <mat-icon>{{icon}}</mat-icon>
  </div>

  <div class="title">
    {{ error | translate }}
  </div>

  <div class="details" *ngIf="errorDetails">
    {{ errorDetails | translate }}
  </div>

  <div *ngIf="showUpgradeButton">
    <a mat-raised-button color="primary" [routerLink]="billingRoute" class="upgrade-btn">{{ 'pricingLimit.upgradeBtn' |
      translate }}</a>
  </div>

  <div fxFlex="2 1 auto"></div>
</div>
