export interface HelpLinkList {
  [key: string]: HelpLink[];
}

export interface HelpLink {
  title: string;
  url: string;
}

export const helpLinks: HelpLinkList = {
  'activity-summary': [
    {
      title: 'aboutIdle',
      url: 'https://support.timedoctor.com/en/support/solutions/articles/66000480949-how-to-understand-idle-times-in-the-activity-summary-report',
    },
  ],
  'edit-time': [
    {
      title: 'howToEditTime',
      url: 'https://support2.timedoctor.com/knowledge/how-to-manually-edit-time-in-time-doctor',
    },
  ],
  integrations: [
    {
      title: 'aboutIntegrations',
      url: 'https://support2.timedoctor.com/knowledge/how-time-doctor-chrome-extension-works',
    },
    {
      title: 'howToAddCustomDomains',
      url: 'https://support2.timedoctor.com/knowledge/how-to-add-a-custom-domain-with-the-chrome-extension-for-time-doctor',
    },
  ],
  'manage-users': [
    {
      title: 'howToManageUsers',
      url: 'https://support2.timedoctor.com/knowledge/managing-users-in-time-doctor',
    },
    {
      title: 'howToDeleteAndRestore',
      url: 'https://support2.timedoctor.com/knowledge/how-to-delete-and-restore-users-in-time-doctor',
    },
  ],
  'manage-user-groups': [
    {
      title: 'howToCreateGroups',
      url: 'https://support2.timedoctor.com/knowledge/how-to-create-user-groups-in-time-doctor',
    },
  ],
  payroll: [
    {
      title: 'howPayrollWorks',
      url: 'https://support.timedoctor.com/en/support/solutions/articles/66000480959-how-to-configure-and-use-payroll-in-time-doctor',
    },
  ],
  'projects-and-tasks': [
    {
      title: 'howToProjects',
      url: 'https://support2.timedoctor.com/knowledge/how-to-create-projects-and-tasks-in-time-doctor',
    },
    {
      title: 'waysRegularUsersCreateTasks',
      url: 'https://support2.timedoctor.com/knowledge/how-can-my-employees-create-their-own-tasks',
    },
  ],
  'productivity-ratings': [
    {
      title: 'aboutProductivityRatings',
      url: 'https://support2.timedoctor.com/knowledge/productivity-ratings',
    },
  ],
  'web-app-usage': [
    {
      title: 'aboutOtherWebPages',
      url: 'https://support2.timedoctor.com/knowledge/why-are-some-websites-showing-up-as-other-web-pages',
    },
    {
      title: 'aboutProductivityRatings',
      url: 'https://support2.timedoctor.com/knowledge/productivity-ratings',
    },
  ],
  schedules: [
    {
      title: 'workScheduleHelp',
      url: 'https://support.timedoctor.com/en/support/solutions/articles/66000481063-how-to-set-up-work-schedules-in-time-doctor',
    },
  ],
  attendance: [
    {
      title: 'attendanceStatuses',
      url: 'https://support2.timedoctor.com/knowledge/how-to-use-the-time-doctor-attendance-report',
    },
  ],
  notifications: [
    {
      title: 'notificationsHowToSetup',
      url: 'https://support2.timedoctor.com/knowledge/how-to-set-up-custom-email-notifications',
    },
  ],
};
