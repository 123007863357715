<mat-toolbar>
  <h2 class="user-name" fxFlex>{{ (user$ | async)?.name }}</h2>

  <button mat-icon-button [matMenuTriggerFor]="profileMenu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #profileMenu="matMenu">
    <a mat-menu-item routerLink="profile-edit">{{ 'header.editProfile' | translate }}</a>
    <button mat-menu-item (click)="logout()">{{ 'header.logout' | translate }}</button>
  </mat-menu>
</mat-toolbar>

<mat-nav-list *ngIf="company$ | async as company">
  <ng-container *ngFor="let item of menuDef.items">
    <ng-container *ngIf="!item.condition || item.condition(company)">
      <ng-container *ngIf="!$any(item).children; else buttonWithChildren">
        <a mat-list-item [routerLink]="$any(item).routerLink" routerLinkActive="active">
          {{ item.name | translate }}
        </a>
      </ng-container>

      <ng-template #buttonWithChildren>
        <mat-expansion-panel>
          <mat-expansion-panel-header expandedHeight="48px">
            {{ item.name | translate }}
          </mat-expansion-panel-header>

          <mat-nav-list>
            <ng-container *ngFor="let sub of $any(item).children">
              <ng-container *ngIf="!sub.condition || sub.condition(company)">
                <a mat-list-item [routerLink]="sub.routerLink" routerLinkActive="active">
                  {{ sub.name | translate }}
                </a>
              </ng-container>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-nav-list>
