import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { of } from 'rxjs';
import enTranslation from 'src/assets/i18n/en.json';

export function createTranslateLoader(http: HttpClient, baseHref: string): TranslateLoader {
  const loader = {
    getTranslation: (lang: string) => {
      if (lang === 'en') { return of(enTranslation); }
      return httpLoader.getTranslation(lang);
    },
  };

  const httpLoader = new TranslateHttpLoader(http, `${baseHref}assets/i18n/`, '.json');

  return loader;
}

@Injectable({ providedIn: 'root' })
export class InjectableTranslateMessageFormatCompiler extends TranslateMessageFormatCompiler { }
