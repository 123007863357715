import { Injectable } from '@angular/core';
import { Action, InitState, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { SetFocusedStatus, UpdateOnlineStatus, UpdateVisibleStatus } from './shared.actions';


export interface SharedStateModel {
  visible?: boolean;
  online?: boolean;
  focused?: boolean;
}

@State<SharedStateModel>({
  name: 'shared',
  defaults: {},
})
@Injectable()
export class SharedState implements NgxsOnInit {
  @Selector()
  public static online(state: SharedStateModel) {
    return state.online;
  }

  @Selector()
  public static visible(state: SharedStateModel) {
    return state.visible;
  }

  @Selector()
  public static focused(state: SharedStateModel) {
    return state.focused;
  }

  ngxsOnInit({ dispatch }: StateContext<SharedStateModel>) {
    window.addEventListener('online', () => {
      dispatch(new UpdateOnlineStatus());
    }, false);

    window.addEventListener('offline', () => {
      dispatch(new UpdateOnlineStatus());
    }, false);
  }

  @Action(InitState)
  @Action(UpdateOnlineStatus)
  updateOnlineStatus({ patchState }: StateContext<SharedStateModel>) {
    patchState({ online: window.navigator.onLine });
  }

  @Action(SetFocusedStatus)
  setFocusedStatus({ patchState }: StateContext<SharedStateModel>, payload: SetFocusedStatus) {
    patchState({ focused: payload.focused });
  }

  @Action(InitState)
  @Action(UpdateVisibleStatus)
  updateVisibleStatus({ patchState }: StateContext<SharedStateModel>) {
    patchState({ visible: !(document && document.hidden) });
  }
}
