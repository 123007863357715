import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ExportDialogComponent, ExportDialogPage } from 'src/app/dialogs/export-dialog/export-dialog.component';
import { ExportOptions } from 'src/app/dialogs/export-dialog/store/model';
import { ExportProgressDialogComponent } from 'src/app/dialogs/export-progress-dialog';
import { provideTrackingCtx, TrackingContext } from 'src/app/services/tracking/context';
import { TrackingService } from 'src/app/services/tracking/service';

@Component({
  selector: 'app-export-options-button',
  templateUrl: './export-options-button.component.html',
  styleUrls: ['./export-options-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideTrackingCtx({}),
  ],
})
export class ExportOptionsButtonComponent {
  @Input() page: ExportDialogPage;
  @Input() hideLabel = false;
  @Input() showUserFieldsColumn: boolean;
  @Input() showContentColumn: boolean;
  @Input() showOrganizationColumn: boolean;
  @Input() exportHandler: (exportOptions: ExportOptions) => void;
  @Input() exportProgress: BehaviorSubject<number>;
  @Input() customFootnote: string;


  constructor(
    public dialog: MatDialog,
    private trackingContext: TrackingContext,
    private trackingService: TrackingService,
  ) { }

  exportOptions() {
    if (this.page) {
      const exporDialogRef = this.dialog.open(ExportDialogComponent, {
        data: {
          page: this.page,
          showUserFieldsColumn: this.showUserFieldsColumn,
          showContentColumn: this.showContentColumn,
          showOrganizationColumn: this.showOrganizationColumn,
          customFootnote: this.customFootnote,
        },
      });
      exporDialogRef.afterClosed().subscribe(exportOptions => {
        if (exportOptions) {
          this.exportHandler(exportOptions);
          this.showExportProgress();
        }
        const context = this.trackingContext.context;
        const props = { ...context, ...{ selectedOption: exportOptions } };
        this.trackingService.track('', props);
      });
    } else {
      this.exportHandler({} as ExportOptions);
      this.showExportProgress();
    }
  }

  showExportProgress() {
    if (this.exportProgress) {
      const progressData = { type: 'export', value: 0 };
      const progressDialog = this.dialog.open(ExportProgressDialogComponent, {
        width: '850px',
        disableClose: true,
        data: progressData,
      });

      this.exportProgress.subscribe(value => {
        progressData.value = value;
        if (value === 100) {
          setTimeout(() => {
            progressDialog.close();
            this.exportProgress.next(0);
          }, 500);
        }
      });
    }
  }
}
