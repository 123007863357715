import envTyped from './env.json';

/* eslint-disable @typescript-eslint/naming-convention */
interface KnownEnvironmentVariables {
  NODE_ENV: 'production' | 'development' | 'test';
  TD_APP_VERSION?: string;
  TD_APP_SEGMENT_KEY?: string;
  TD_APP_HOME?: string;
  TD_APP_API_HOST?: string;
  TD_APP_PAYMENT_HOST?: string;
  TD_APP_SENTRY_DSN?: string;
  TD_APP_DISABLE_SOCKET?: 'true' | string;
  TD_APP_DISABLE_ALL_SOCKET?: 'true' | string;
  TD_APP_SOCKET_USE_AUTH_HEADER?: 'true' | string;
  TD_APP_GOOGLE_CLIENT_ID?: string;
  TD_APP_DISABLE_BILLING?: 'true' | string;
  TD_APP_DATA_INTEGRITY_APIURL?: string;
  TD_APP_DATA_INTEGRITY_APIKEY?: string;
  TD_APP_GROWTHBOOK_ENDPOINT?: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

const env = envTyped as unknown as KnownEnvironmentVariables;

export interface Environment {
  version: string;
  production: boolean;
  hmr: boolean;
  baseHref: string;
  appName: string;
  appUrl: string;
  apiUrl: string;
  paymentApiUrl: string;
  socketApiRoot: string;
  socketHost: string;
  defaultApiVersion: string;
  useSocketApi: boolean;
  allowSocket: boolean;
  enableAnalytics: boolean;
  sentryDsn: string;
  sentryRelease: string;
  googleClientId: string;
  dataIntegrityApiUrl: string;
  dataIntegrityApiKey: string;
  growthbookEndpointUrl: string;
  env: KnownEnvironmentVariables;
}

const version = envTyped.TD_APP_VERSION || '0.0.0';
const production = env.NODE_ENV === 'production';
const hmr = env.NODE_ENV === 'development';

const appUrl = env.TD_APP_HOME || 'https://2.timedoctor.com';
const apiHost = env.TD_APP_API_HOST || 'https://api2.timedoctor.com';
const paymentApiUrl = env.TD_APP_PAYMENT_HOST || 'https://staff-api-stripe.herokuapp.com';
const sentryDsn = env.TD_APP_SENTRY_DSN || 'https://e58a1c5c6ecd432ea80ab7cc3af2b5e5@o481059.ingest.sentry.io/5530643';
const googleClientId = env.TD_APP_GOOGLE_CLIENT_ID;
const dataIntegrityApiUrl = env.TD_APP_DATA_INTEGRITY_APIURL;
const dataIntegrityApiKey = env.TD_APP_DATA_INTEGRITY_APIKEY;
const allowSocket = env.TD_APP_DISABLE_ALL_SOCKET !== 'true';
const growthbookEndpointUrl = env.TD_APP_GROWTHBOOK_ENDPOINT;


export const environment: Environment = {
  version,
  hmr,
  production,
  enableAnalytics: !!env.TD_APP_SEGMENT_KEY && production,
  useSocketApi: allowSocket && production && env.TD_APP_DISABLE_SOCKET !== 'true',
  allowSocket,
  baseHref: '/',
  appName: 'timedoctor2-web',
  appUrl,
  apiUrl: `${apiHost}/api/{version}`,
  paymentApiUrl,
  socketApiRoot: '/api/{version}',
  defaultApiVersion: '1.0',
  socketHost: apiHost,
  sentryDsn,
  sentryRelease: version,
  env,
  googleClientId,
  dataIntegrityApiUrl,
  dataIntegrityApiKey,
  growthbookEndpointUrl,
};
