import { AuthCompany, AuthUser, CompanySettings, CustomCompanySettings, User } from 'src/models';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public email: string, public password: string, public rememberMe: boolean, public totpCode?: string) { }
}

export class LoginAs {
  static readonly type = '[Auth] Login As';
  constructor(public user: AuthUser, public token: string) { }
}

export class UpdateCurrentUser {
  static readonly type = '[Auth] Update Current User';

  constructor(public hideLoading: boolean = false) { }
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor(public forget: boolean, public errorMessage?: string) { }
}

export class BeforeLogout {
  static readonly type = '[Auth] Before Logout';
}

export class AfterLogout {
  static readonly type = '[Auth] After Logout';
}

export class ForgotPassword {
  static readonly type = '[Auth] Forgot Password';
  constructor(public email: string) { }
}

export class SelectCompany {
  static readonly type = '[Auth] Select Company';
  constructor(public company: AuthCompany) { }
}

export interface ExtCustomCompanySettings extends CustomCompanySettings {
  trackedAccountCreated?: boolean;
  showSpecialOffer?: boolean;
  tdAnonymousId?: string;
}

export class UserSettingsChanged {
  static readonly type = '[Auth] User Settings Changed';
  constructor(
    public changes: Partial<CompanySettings>,
    public custom?: ExtCustomCompanySettings,
  ) { }
}

export class CompanySettingsChanged {
  static readonly type = '[Auth] Company Settings Changed';
  constructor(
    public rootChanges: Partial<Pick<AuthCompany, 'name' | 'timezone'>>,
    public settingChanges: Partial<Pick<CompanySettings, 'name' | 'blurScreenshots'>>,
    public custom: ExtCustomCompanySettings,
  ) { }
}

export class InvalidateUser {
  static readonly type = '[Auth] Has Invalid Token';

  constructor(public errorMessage: string = 'login.tokenExpired') { }
}

export class TokenLogin {
  static readonly type = '[Auth] Token Login';
  constructor(public token: string, public company: string, public adminLogin?: boolean) { }
}

export type StatusMode = 'offline' | 'computer' | 'manual' | 'break';

export interface StatusPayload {
  callerId: string;
  companyId: string;
  deviceId: string;
  id: string;
  mode: StatusMode;
  onlyProjectIds: string[];
  paging: {
    getTotal: boolean;
    limit: number;
    skip: number;
  };
  remoteIp: string;
  userId: string;
  projectId?: string;
  taskId?: string;
  projectName?: string;
  taskName?: string;
}

export class StatusChanged {
  static readonly type = '[Auth] Status Changed';
  constructor(public data: StatusPayload) { }
}

export class EditProfile {
  static readonly type = '[ManageUsers] Edit Profile';
  constructor(public properties: Partial<User> & { password?: string, twoFactorAuth?: boolean }) { }
}

export class Register {
  static readonly type = '[Auth] Register';
  constructor(public name: string, public email: string, public password: string) { }
}
export class SetUeMemberFlag {
  static type = '[Auth] Set EU Member Flag';

  constructor(public isUEMember: boolean) { }
}
