import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValueAccessorBase } from '@shared';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: 'button[type="submit"][formControlName]',
})
export class SubmitDirective extends ValueAccessorBase<any> implements OnInit {
  disabledByCustom = new BehaviorSubject(false);
  disabledByForm = new BehaviorSubject(false);
  disabledByControl = new BehaviorSubject(false);
  pending = new BehaviorSubject(false);

  @Input() set customDisabled(value: boolean) { this.disabledByCustom.next(value); }

  constructor(private control: NgControl, private renderer: Renderer2, private elementRef: ElementRef<HTMLButtonElement>) {
    super();

    control.valueAccessor = this;

    combineLatest([this.disabledByControl, this.disabledByCustom, this.disabledByForm, this.pending])
      .pipe(map(disabledArray => disabledArray.some(y => y)), untilDestroyed(this))
      .subscribe(isDisabled => {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
      });

    combineLatest([this.disabledByControl, this.pending])
      .pipe(map(disabledArray => disabledArray.some(y => y)), untilDestroyed(this))
      .subscribe(isDisabled => {
        if (isDisabled) {
          this.renderer.addClass(this.elementRef.nativeElement, 'submit-loading');
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, 'submit-loading');
        }
      });
  }

  ngOnInit() {
    const form = this.control['_parent'] as AbstractControl;
    if (form && form.statusChanges) {
      form.statusChanges.subscribe(x => this.disabledByForm.next(x !== 'VALID'));
    }

    this.control.statusChanges.subscribe(x => this.pending.next(x === 'PENDING'));
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledByControl.next(isDisabled);
  }
}
