import { AfterViewInit, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-onboarding-header',
  templateUrl: './onboarding-header.component.html',
  styleUrls: ['./onboarding-header.component.scss'],
})

export class OnboardingHeaderComponent implements AfterViewInit {
  @Input() progress = 0;
  @Input() stepCount = null;

  ngAfterViewInit() {
    const top = document.querySelector('.mat-dialog-container');
    if (top !== null) {
      top.scrollIntoView();
    }
  }
}
