import { Injectable } from '@angular/core';
import { ApiLimitParams, ApiService, PagedApiResult, PagingService } from '@shared';
import { lastValueFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { defualtUserApiLimit } from 'src/app/app/app.constants';
import { BulkInvitePayload, BulkInviteResponse, Invitation, User } from 'src/models';

export type UserApiDetail = 'id' | 'name' | 'alias' | 'tags' | 'info' | 'settings' | 'managers' | 'silent';

export interface UserMapped<T = any> {
  user: User;
  data: T;
}

export interface UserApiParams extends ApiLimitParams {
  user?: string | string[];
  tag?: string | string[];
  'archived-tag'?: string | string[];
  'task-project-names'?: boolean;
  self?: boolean | 'include' | 'exclude';
  deleted?: 1 | 0;
  detail?: UserApiDetail;
  sort?: string;
  'silent-details'?: 1 | 0;
  'filter[id]'?: string | string[];
  'filter[hiredAt]'?: string | string[];
  'filter[os]'?: string | string[];
  'filter[email]'?: string;
  'filter[keyphrase]'?: string;
  'filter[show-on-reports]'?: 1 | 0;
  'filter[last-track]'?: string;
  [key: string]: string | number | boolean | string[];
}

export interface InviteExist {
  id: string;
  name: string;
  invitePending: boolean;
}

export interface BulkInviteObject {
  tagIds?: string[];
  noSendEmail?: boolean;
  users: { name?: string, email: string, role: string }[];
}
export interface InviteExtraOptions {
  name?: string;
  employeeId?: string;
  noSendEmail?: boolean;
  tagIds?: string[];
}

export type SilentZombieGrouping = 'hostName' | 'machineId' | 'ip';
export interface GetSilentZombiesOptions {
  days?: number; // default 14
  'group-by'?: SilentZombieGrouping[]; // (default: hostName)
  sort?: string;
  filter?: string;
  limit?: number;
  page?: number;
}

export interface SilentZombieUser {
  id: string;
  name: string;
  lastTracked: string;
  lastAccessAttempt: string;
  userInfo: {
    NameCanonicalEx: string;
    NameDnsDomain: string;
    NameFullyQualifiedDN: string;
    NameSamCompatible: string;
    NameUniqueId: string;
  };
  os: string;
  client: string;
}

export interface SilentZombie {
  ip: string | string[];
  hostName: string | string[];
  machineId: string | string[];
  users: SilentZombieUser[];
}

const defaultPagingLimit = defualtUserApiLimit;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private api: ApiService, private paging: PagingService) { }

  get(id: string, deleted: boolean = false, params: UserApiParams = {}) {
    return this.api.request<{ data: User }>('get', `users/${id}`, { ...(params || {}), deleted: deleted ? 1 : 0 }).pipe(map(x => x.data));
  }

  users(params: UserApiParams = {}) {
    if (params?.user?.length === 0) { return this.paging.empty<User>(); }

    const limit = params?.limit || defaultPagingLimit;

    return this.paging.getPagedResult((page) =>
      this.api.request<PagedApiResult<User>>('get', `users`, {
        'no-total': page === 0 ? 0 : 1,
        page, ...params, limit,
        ...(params.user && { user: '' + params.user }),
        ...(params.tag && { tag: '' + params.tag }),
      }), limit);
  }

  usersMapped<T>(
    params: UserApiParams,
    mapFn: (userIds: string[]) => Promise<T[] | Map<string, T>>,
    zipFn: (user: User, dt: T) => boolean,
  ) {
    if (params?.user?.length === 0) { return this.paging.empty<UserMapped<T>>(); }

    const limit = params?.limit || defaultPagingLimit;

    return (params['filter[id]']?.length === 0 || params.tag?.length === 0) ? this.paging.empty<UserMapped<T>>() :
      this.paging.getPagedResult((page) =>
        this.api.request<PagedApiResult<User>>('get', `users`, {
          'no-total': page === 0 ? 0 : 1,
          page, ...params, limit,
          ...(params.user && { user: '' + params.user }),
          ...(params.tag && { tag: '' + params.tag }),
        }).pipe(switchMap(userResult => {
          if (!userResult?.data) { return null; }
          const userIds = userResult.data.map(y => y.id);
          return mapFn(userIds).then(res => ({
            ...userResult,
            data: userResult.data.map(user => ({
              user,
              data: res instanceof Map ? res.get(user.id) : res.find(r => zipFn(user, r)),
            })),
          }));
        })),
        limit);
  }

  editProperties(id: string, properties: Partial<User>) {
    return this.api.request('put', `users/${id}`, properties);
  }

  delete(id: string) {
    return this.api.request('delete', `users/${id}`);
  }

  restore(id: string) {
    return this.api.request('delete', `users/${id}`, {}, { params: { deleted: '1' } });
  }

  resendInvite(id: string) {
    return lastValueFrom(this.api.request('get', `invitations/resend/${id}`, null, null, null, '1.1'));
  }

  userExists(email: string) {
    return lastValueFrom(this.api.request<{ data: InviteExist }>('get', 'invitations/exists', { email }, null, null, '1.1'));
  }

  invite(email: string, role: 'user' | 'guest' | 'admin' | 'manager', options: InviteExtraOptions = null) {
    return lastValueFrom(this.api.request<{ data: Invitation }>('post', 'invitations', { ...options, email, role }, null, null, '1.1'));
  }

  bulkInvite(bulkInvites: BulkInvitePayload) {
    return lastValueFrom(this.api.request<{ data: BulkInviteResponse[] }>('post', 'invitations/bulk', bulkInvites, null, null, '1.1'));
  }

  getTotpQRCode() {
    return lastValueFrom(this.api.request<{ data: { qrUrl: string } }>('get', 'login/2fa/new')).then(x => x.data);
  }

  activateTotp(totpCode: string) {
    return lastValueFrom(this.api.request<{ data: { status: 'totpActive' } }>('post', 'login/2fa/activate', { totpCode }))
      .then(x => x.data);
  }

  resendConfirmationEmail() {
    return lastValueFrom(this.api.request('post', `profile/confirmation`, null, null, null, '1.1'));
  }

  getSilentZombies(params: GetSilentZombiesOptions = {}) {
    const baseUrl = 'silent-zombies';
    const limit = params?.limit || defaultPagingLimit;

    return this.paging.getPagedResult((page) =>
      this.api.request<PagedApiResult<SilentZombie>>('get', baseUrl,
        { page, ...params, 'group-by': params['group-by'] + '', limit }, null, true, '1.1'), limit);
  }
}
