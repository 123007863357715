import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DailySummary } from 'src/models';

@Component({
  selector: 'app-daily-timetube',
  templateUrl: './daily-timetube.component.html',
  styleUrls: ['./daily-timetube.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyTimetubeComponent {
  @Input() summary: DailySummary;
  readonly fullSeconds = 60 * 60 * 12;

  get computerWidth() {
    return (this.summary && this.summary.computer || 0) / this.fullSeconds;
  }

  get manualWidth() {
    return (this.summary && this.summary.manual || 0) / this.fullSeconds;
  }

  get mobileWidth() {
    return (this.summary && this.summary.mobile || 0) / this.fullSeconds;
  }
}
