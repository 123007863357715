import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { guestReports } from 'src/app/common/menu-def.provider';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private router: Router,
  ) { }

  async canActivate(route?: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const company = this.store.selectSnapshot(AuthState.company);
    if (company.role === 'user') {
      return this.router.parseUrl('/dashboard-individual');
    } else if (company.role === 'guest') {
      const matchingReport = guestReports.find(x => x.condition(company));
      const href = matchingReport && (matchingReport as { href: string }).href;
      const link = matchingReport && (matchingReport as { routerLink: string }).routerLink;

      if (href) {
        window.location.href = href;
        return false;
      } else if (link) {
        return this.router.parseUrl(link);
      } else {
        return this.router.parseUrl('/error/guest');
      }
    } else {
      const queryParams = route?.queryParams;
      return this.router.createUrlTree(['/dashboard'], { queryParams });
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }

  canLoad() {
    return this.canActivate().then(x => x === true);
  }
}
