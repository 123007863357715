import { InjectionToken } from '@angular/core';
import { APP_URL } from '@shared';
import { environment } from 'src/environments/environment';
import { AuthCompany, ReportType, WebAppTrackingMode } from 'src/models';
import { isAlphaCustomer, isTimeDoctor } from '../app/app.constants';
import { BrandingService } from '../services/branding.service';
import { flatten } from '../util/array-helpers';

const brandingService = new BrandingService(APP_URL.toString());

export type MenuCondition = (company: AuthCompany) => boolean;

export type MenuItem = {
  name: string;
  condition?: MenuCondition;
  routerLink: string;
} | {
  name: string;
  condition?: MenuCondition;
  href: string;
};

export interface MenuParent {
  name: string;
  condition?: MenuCondition;
  children: MenuItem[];
}

export interface MenuDef {
  items: (MenuItem | MenuParent)[];
}

export const MENU_DEF = new InjectionToken<MenuDef>('Menu Definition');

export const isWhiteLabel = () => !brandingService.isDefault;
export const disableDownload = () => brandingService.downloadDisabled();

export function isRegularUser(company: AuthCompany) {
  return company.role === 'user';
}

export function isGuest(company: AuthCompany) {
  return company.role === 'guest';
}

export function isManager(company: AuthCompany) {
  return ['manager', 'admin', 'owner'].includes(company.role);
}

export function isAdmin(company: AuthCompany) {
  return ['admin', 'owner'].includes(company.role);
}

export function isOwner(company: AuthCompany) {
  return company.role === 'owner';
}

export function isTaskBased(company: AuthCompany) {
  return company.companySettings.tasksMode !== 'off' && company.companySettings.trackingMode !== 'silent';
}

export function isSilent(company: AuthCompany) {
  return company.companySettings.trackingMode === 'silent';
}

export function isInteractive(company: AuthCompany) {
  return !isSilent(company);
}

export function isTimedoctorCompany(company: AuthCompany) {
  return isTimeDoctor(company.id);
}

export function isAlphaCompany(company: AuthCompany) {
  return isAlphaCustomer(company.id) || environment.hmr;
}

export function connectivityEnabled(company: AuthCompany) {
  return company.companySettings.trackConnectivity;
}

export function isDevelopment() {
  return !environment.production;
}

export function guestHasAccessTo(report: ReportType) {
  return (company: AuthCompany) => (company.userSettings.custom.allowedReports || {})[report];
}

export function hasScreencasts(company: AuthCompany) {
  const ssUser = parseFloat(company?.userSettings.screenshots.toString());
  const screencastsEnabledForUser = ssUser > 0 || company?.userSettings.videos === 'on';
  const screencastsEnabledForCompany = company?.companySettings.screencastsFeature !== false;
  return screencastsEnabledForCompany && (company?.hasManagedScreencasts || screencastsEnabledForUser);
}

export function and(...conditions: MenuCondition[]): MenuCondition {
  return (company: AuthCompany) => {
    return conditions.every(x => x == null || x(company));
  };
}

export function or(...conditions: MenuCondition[]): MenuCondition {
  return (company: AuthCompany) => {
    return conditions.some(x => x != null && x(company));
  };
}

export function not(condition: MenuCondition): MenuCondition {
  return (company: AuthCompany) => {
    return !condition(company);
  };
}

export const schedulesEnabled = (company: AuthCompany) => company.companySettings.workScheduleFeature;

export const payrollEnabled = and(
  isInteractive,
  (company: AuthCompany) => company.companySettings.payrollFeature && (isOwner(company) || company.userSettings.payrollAccess),
);

export const billingAccess = and(
  not(isGuest),
  or(isOwner, (company: AuthCompany) => company.userSettings.billingAccess),
  () => !brandingService.billingDisabled(),
);

export const webAppTrackingIs = (value: WebAppTrackingMode) => (x => x.companySettings?.webAndAppTracking === value) as MenuCondition;
export const hasWebAppTracking = not(webAppTrackingIs(WebAppTrackingMode.Off));

export const editTimeEnabled = and(not(isGuest), ((company: AuthCompany) => company.userSettings.allowEditTime));

export const productivityEnabledForManager = and(x => x.companySettings?.allowManagerTagCategories, hasWebAppTracking);
export const projectsEnabledForManager: MenuCondition = x => x.companySettings?.allowManagerProjectsTasks;
export const inviteEnabledForManager: MenuCondition = x => x.companySettings?.allowManagerInviteUsers;

const isBasicPlan: MenuCondition = x => x?.pricingPlan?.includes('basic');

export const hasNotificationsAccess: MenuCondition = not(isBasicPlan);

export const managerSettings: (MenuItem | MenuParent)[] = [
  {
    name: 'header.settings',
    condition: and(isManager, not(isAdmin), or(
      hasNotificationsAccess,
      productivityEnabledForManager,
      projectsEnabledForManager,
      billingAccess,
      schedulesEnabled,
    )),
    children: [
      { name: 'header.settingsBilling', routerLink: '/billing', condition: billingAccess },
      { name: 'header.settingsTasks', routerLink: '/projects-and-tasks', condition: and(isTaskBased, projectsEnabledForManager) },
      { name: 'header.settingsNotifications', routerLink: '/notifications', condition: hasNotificationsAccess },
      { name: 'header.settingsProductivity', routerLink: '/productivity-ratings', condition: productivityEnabledForManager },
      { name: 'header.settingsSchedules', routerLink: '/schedules', condition: schedulesEnabled },
    ],
  },
];

export const userSettings: (MenuItem | MenuParent)[] = [
  {
    name: 'header.settings',
    condition: and(isRegularUser, or(billingAccess, schedulesEnabled)),
    children: [
      { name: 'header.settingsBilling', routerLink: '/billing', condition: billingAccess },
      { name: 'header.settingsSchedules', routerLink: '/schedules', condition: schedulesEnabled },
    ],
  },
];

export const guestReports: MenuItem[] = [
  {
    name: 'reports.projectsAndTasks',
    routerLink: '/projects-report',
    condition: and(isTaskBased, isGuest, guestHasAccessTo('projectsAndTasks')),
  },
  {
    name: 'reports.screencasts',
    routerLink: '/screencasts',
    condition: and(hasScreencasts, isGuest, guestHasAccessTo('screencasts')),
  },
  {
    name: 'reports.activitySummary',
    routerLink: '/activity-summary',
    condition: and(isGuest, guestHasAccessTo('activitySummary')),
  },
  {
    name: 'reports.hoursTracked',
    routerLink: '/hours-tracked',
    condition: and(isGuest, guestHasAccessTo('hoursTracked')),
  },
  {
    name: 'reports.webAndAppUsage',
    routerLink: '/web-app-usage',
    condition: and(isGuest, guestHasAccessTo('webAndAppUsage'), hasWebAppTracking),
  },
  {
    name: 'reports.connectivity',
    routerLink: '/internet-report',
    condition: and(isGuest, guestHasAccessTo('connectivity')),
  },
  {
    name: 'reports.timeline',
    routerLink: '/timeline',
    condition: and(isGuest, guestHasAccessTo('timeline')),
  },
  {
    name: 'reports.attendance',
    routerLink: '/attendance',
    condition: and(isGuest, guestHasAccessTo('attendance')),
  },
];


const menuDef: MenuDef = {
  items: [
    {
      name: 'header.dashboard',
      routerLink: '/dashboard-individual',
      condition: isRegularUser,
    },
    {
      name: 'header.dashboard',
      children: [
        { name: 'header.team', routerLink: '/dashboard' },
        { name: 'header.user', routerLink: '/dashboard-individual' },
      ],
      condition: isManager,
    },
    {
      name: 'header.reports',
      children: [
        { name: 'reports.activitySummary', routerLink: '/activity-summary', condition: isManager },
        { name: 'reports.attendance', routerLink: '/attendance', condition: schedulesEnabled },
        { name: 'reports.hoursTracked', routerLink: '/hours-tracked' },
        { name: 'reports.projectsAndTasks', routerLink: '/projects-report', condition: isTaskBased },
        { name: 'reports.timeline', routerLink: '/timeline' },
        { name: 'reports.webAndAppUsage', routerLink: '/web-app-usage', condition: hasWebAppTracking },
        { name: 'reports.connectivity', routerLink: '/internet-report', condition: connectivityEnabled },
        { name: 'reports.csv', routerLink: '/csv', condition: isManager },
      ],
      condition: not(isGuest),
    },
    {
      name: 'reports.screencasts',
      routerLink: '/screencasts',
      condition: and(hasScreencasts, not(isGuest)),
    },
    ...guestReports,
    {
      name: 'header.editTime',
      routerLink: '/edit-time',
      condition: editTimeEnabled,
    },
    ...managerSettings,
    ...userSettings,
    {
      name: 'header.settings',
      condition: isAdmin,
      children: [
        { name: 'header.settingsBilling', routerLink: '/billing', condition: billingAccess },
        { name: 'header.settingsTasks', routerLink: '/projects-and-tasks', condition: isTaskBased },
        { name: 'header.settingsProductivity', routerLink: '/productivity-ratings', condition: hasWebAppTracking },
        { name: 'header.settingsUsers', routerLink: `/manage-users` },
        { name: 'header.settingsGroups', routerLink: '/manage-user-groups' },
        { name: 'header.settingsNotifications', routerLink: '/notifications', condition: hasNotificationsAccess },
        { name: 'header.settingsIntegrations', routerLink: '/integrations', condition: isInteractive },
        { name: 'header.settingsCompany', routerLink: '/company/edit' },
        { name: 'header.settingsSchedules', routerLink: '/schedules', condition: schedulesEnabled },
        { name: 'header.breaks', routerLink: '/breaks', condition: isTimedoctorCompany },
      ],
    },
    { name: 'header.payroll', routerLink: '/payroll', condition: and(payrollEnabled, not(isGuest)) },
    { name: 'header.invite', routerLink: '/invite', condition: and(or(isAdmin, and(isManager, inviteEnabledForManager)), isInteractive) },
    { name: 'header.addManagers', routerLink: '/add-managers', condition: and(isAdmin, isSilent) },
    { name: 'header.download', routerLink: '/downloads', condition: and(not(isGuest), or(not(disableDownload), isOwner)) },
  ],
};


// Require condition of both parent and children (and operator) in nested scenario
const flatMenu = flatten(menuDef.items.map(x => ('children' in x) ?
  x.children.map(c => ({ ...c, condition: and(c.condition, x.condition) })) : x));

// Require condition of atleast one of alternate routes (or operator) in parallel scenario
const conditionMap = new Map<string, MenuCondition>();
for (const cnd of flatMenu) {
  if ('routerLink' in cnd) {
    const existingCondition = conditionMap.get(cnd.routerLink);
    const newCondition = or(existingCondition, cnd.condition);
    conditionMap.set(cnd.routerLink, newCondition);
  }
}

/**
 * Determines if a page is accessible by checking the conitions in the menu definitions
 * @param company Company retrieved from auth request
 * @param url The url starting with `/`
 */
export function isMenuAllowed(company: AuthCompany, url: string) {
  return !!conditionMap.get(url)?.(company);
}

export const menuProvider = {
  provide: MENU_DEF,
  useFactory: () => menuDef,
};
