import { Component, Inject, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MenuDef, MENU_DEF } from 'src/app/common/menu-def.provider';
import { Logout } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { AuthCompany, AuthUser } from 'src/models';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(AuthState.user) user$: Observable<AuthUser>;

  @Input() activeUrl: string;

  constructor(
    private store: Store,
    @Inject(MENU_DEF) public menuDef: MenuDef,
  ) { }

  logout() {
    this.store.dispatch(new Logout(true));
  }
}
