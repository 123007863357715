import { LazySelectionModel } from '@shared';
import { UserApiParams } from 'src/app/services/user/user.service';
import { UserGroup, UserRole } from 'src/models';

export interface LazyUserSelectionSerializable {
  all?: boolean;
  list?: string[];
  type?: 'users' | 'groups';
}

export class LazyUserSelection {
  static readonly membersOfNoGroupId = '@membersOfNoGroup';

  static none = new LazyUserSelection('users', LazySelectionModel.none, LazySelectionModel.none);
  static all = new LazyUserSelection('users', LazySelectionModel.all, LazySelectionModel.all);
  static default = new LazyUserSelection('groups', LazySelectionModel.all, LazySelectionModel.all);
  static selfUser = LazyUserSelection.singleUser('');

  static singleUser(userId: string) {
    if (userId == null) { return null; }
    return LazyUserSelection.fromSerializable({ type: 'users', list: [userId] });
  }

  static fromSerializable(val: LazyUserSelectionSerializable) {
    if (!val) {
      return this.default;
    }

    const sel = new LazySelectionModel(val.list || [], !!val.all);
    const type = val.type || this.default.type;
    const users = type === 'users' ? sel : LazySelectionModel.all;
    const groups = type === 'groups' ? sel : LazySelectionModel.all;

    return new LazyUserSelection(type, users, groups);
  }

  static same(oldSelection: LazyUserSelection, newSelection: LazyUserSelection) {
    if (!(oldSelection && newSelection)) { return false; }
    if (oldSelection.type !== newSelection.type) { return false; }
    if (oldSelection.type === 'users') { return LazySelectionModel.same(oldSelection.users, newSelection.users); }
    if (oldSelection.type === 'groups') { return LazySelectionModel.same(oldSelection.groups, newSelection.groups); }
    return false;
  }

  constructor(
    public readonly type: 'users' | 'groups',
    public readonly users: LazySelectionModel,
    public readonly groups: LazySelectionModel,
    public readonly allGroups?: UserGroup[],
    public readonly noTag = false,
  ) { }

  setType(type: 'users' | 'groups') {
    return new LazyUserSelection(type, this.users, this.groups, this.allGroups, this.noTag);
  }

  setAllUsers(users: string[] | Set<string>, allUsersCount?: number): LazyUserSelection {
    return new LazyUserSelection(this.type, this.users.setAllItems(users, allUsersCount), this.groups, this.allGroups, this.noTag);
  }

  setAllGroups(groups: UserGroup[], allGroupsCount?: number): LazyUserSelection {
    return new LazyUserSelection(this.type, this.users,
      this.groups.setAllItems(null, allGroupsCount), groups, this.noTag);
  }

  select(...ids: string[]): LazyUserSelection {
    if (this.type === 'users') {
      return new LazyUserSelection(this.type, this.users.select(...ids), this.groups, this.allGroups, this.noTag);
    } else {
      return new LazyUserSelection(this.type, this.users, this.groups.select(...ids), this.allGroups, this.noTag);
    }
  }

  deselect(...ids: string[]): LazyUserSelection {
    if (this.type === 'users') {
      return new LazyUserSelection(this.type, this.users.deselect(...ids), this.groups, this.allGroups, this.noTag);
    } else {
      return new LazyUserSelection(this.type, this.users, this.groups.deselect(...ids), this.allGroups, this.noTag);
    }
  }

  set(selected: boolean, ...ids: string[]): LazyUserSelection {
    if (this.type === 'users') {
      return new LazyUserSelection(this.type, this.users.set(selected, ...ids), this.groups, this.allGroups, this.noTag);
    } else {
      return new LazyUserSelection(this.type, this.users, this.groups.set(selected, ...ids), this.allGroups, this.noTag);
    }
  }

  isSelected(id: string): boolean {
    if (this.type === 'users') {
      return this.users.isSelected(id);
    } else {
      return this.groups.isSelected(id);
    }
  }

  clear(): LazyUserSelection {
    if (this.type === 'users') {
      return new LazyUserSelection(this.type, this.users.clear(), this.groups, this.allGroups, this.noTag);
    } else {
      return new LazyUserSelection(this.type, this.users, this.groups.clear(), this.allGroups, false);
    }
  }

  selectAll(): LazyUserSelection {
    if (this.type === 'users') {
      return new LazyUserSelection(this.type, this.users.selectAll(), this.groups, this.allGroups, this.noTag);
    } else {
      return new LazyUserSelection(this.type, this.users, this.groups.selectAll(), this.allGroups, false);
    }
  }

  switchGroupsAll(): LazyUserSelection {
    const groups = new LazySelectionModel(this.allGroups?.map(x => x.id), false, null, this.groups.countAll);
    return new LazyUserSelection(this.type, this.users, groups, this.allGroups, this.noTag);
  }

  setNoTag(noTag: boolean) {
    return new LazyUserSelection(this.type, this.users, noTag ? this.groups.clear() : this.groups, this.allGroups, noTag);
  }

  get count(): number {
    return this.type === 'users' ? this.users.count : this.groups.count;
  }

  get userCount(): number {
    return (this.type === 'users' || this.groups.all)
      ? this.users.count
      : undefined;
    // TODO: We can't know the total number of users in selected groups as there may be intersections. Thus, the code below is wrong
    // : this.allGroups?.filter(x => this.groups.isSelected(x.id)).reduce((acc, cur) => acc + cur.usersOnReports, 0);
  }

  get any(): boolean {
    return this.type === 'users' ? this.users.any : this.groups.any;
  }

  get all(): boolean {
    return this.type === 'users' ? this.users.all : this.groups.all;
  }

  get indeterminate(): boolean {
    return this.type === 'users' ? this.users.indeterminate : this.groups.indeterminate;
  }

  isUserSelected = (user: { id: string, tagIds?: string[], role?: UserRole }) => {
    if (!user) {
      return false;
    }
    if (this.type === 'users') {
      if (this.users.all) {
        return true;
      }
      return this.users.isSelected(user.id);
    } else {
      if (this.groups.all) {
        return true;
      }
      if (!user.tagIds) {
        return false;
      }
      return this.groups.list.some(x =>
        x === LazyUserSelection.membersOfNoGroupId
          ? (user.role === 'user' ? user.tagIds.length < 2 : user.tagIds.length < 1)
          : user.tagIds.includes(x));
    }
  };

  toSerializable() {
    const all = this.all;
    const type = this.type;
    const list = all ? [] : (type === 'users' ? this.users.list : this.groups.list);

    return { all, list, type } as LazyUserSelectionSerializable;
  }

  asApiParams(allUsersKey: string = 'all-on-reports') {
    if (!this.any) { return { user: [] }; }
    if (this.all) { return { user: allUsersKey }; }

    return this.type === 'users'
      ? { user: this.users.list || [] }
      : { tag: this.groups.list, user: allUsersKey };
  }

  asUserApiParams(allUsersKey: string = 'all-on-reports') {
    if (this.noTag) {
      return { 'no-tag': true };
    }

    const apiParams = this.asApiParams(allUsersKey);

    return {
      ...apiParams.tag && { tag: apiParams.tag },
      ...apiParams.user && apiParams.user !== allUsersKey && { 'filter[id]': apiParams.user },
    } as UserApiParams;
  }

  getUserCounts(onReports: boolean = true): { max?: number, min?: number, exact?: number } {
    if (this.type === 'users' || this.groups.all) {
      const count = this.users.count;
      return { max: count, min: count, exact: count };
    }

    const selectedGroups = this.allGroups?.filter(x => this.groups.isSelected(x.id));

    if (!selectedGroups) { return {}; }

    const max = selectedGroups.reduce((acc, cur) => acc + (onReports ? cur.usersOnReports : cur.users), 0);
    const min = selectedGroups.reduce((acc, cur) => Math.max(acc, (onReports ? cur.usersOnReports : cur.users)), 0);

    return { max, min };
  }
}
