import { Directive, OnInit, Self } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

/**
 * @author Abdel El-medny
 * https://stackoverflow.com/questions/38844835/extending-angular-2-ngmodel-directive-to-use-observables
 */
@Directive({
  selector: '[rxModel]',
})
export class RxModelDirective implements OnInit {

  /**
   * Reference to the behaviorSubject, because during the first change we will transform
   * the behaviorSubject to its value in the ngModel
   */
  behaviorSubjectReference: BehaviorSubject<any>;

  constructor(@Self() private ngControl: NgControl) { }


  ngOnInit() {
    if (!(this.ngControl instanceof NgModel)) {
      // If the ngControl is not an instanceof ngModel, return early
      return;
    }

    this.ngControl.valueChanges.pipe(
      filter(value => {
        return value instanceof BehaviorSubject || Boolean(this.behaviorSubjectReference);
      }),
    ).subscribe((value: BehaviorSubject<any> | any) => {

      if (value instanceof BehaviorSubject) {
        // Saving the behaviorSubject for later use
        //
        this.behaviorSubjectReference = value;

        this.behaviorSubjectReference.pipe(distinctUntilChanged()).subscribe(x => this.ngControl.control.setValue(x));
      } else {
        // If we are in the else clause, the first change has already gone by and we have a
        // behaviorSubjectReference we can call .next on
        //
        this.behaviorSubjectReference.next(value);
      }
    });
  }
}
