<shared-avatar [name]="user?.name" [size]="size" [textSizeRatio]="textSizeRatio" *ngIf="!hideAvatar"></shared-avatar>

<div class="name-part">
  <truncated-text [text]="user?.name">
    <a [routerLink]="['/dashboard-individual', { user: user?.id }]" class="dashboard-link" suffix
      (click)="$event.stopPropagation()" [matTooltip]="'common.viewUsersDashboard' | translate"
      *ngIf="!hideLink && ((role$ | async) !== 'guest')" matTooltipPosition="above"
      [trackClick]="{ selectedoption: { value: user?.name } }">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </truncated-text>

  <ng-content></ng-content>
</div>
