import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { DurationHmsPipe } from '@shared';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { provideTrackingCtx, TrackingContext } from 'src/app/services/tracking/context';
import { TrackingService } from 'src/app/services/tracking/service';
import { StatusMode } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { diffSinceDayStart, EditTimeData } from 'src/app/util/timeline-helpers';
import { Worklog } from 'src/models';

const secondsInDay = 60 * 60 * 24;

type TimeTubeItem = Worklog & { left: number, width: number, tooltip: string, original: Worklog };

@Component({
  selector: 'app-timetube',
  templateUrl: './timetube.component.html',
  styleUrls: ['./timetube.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideTrackingCtx({}),
  ],
})
export class TimetubeComponent {
  @ViewChild('tooltip') tooltip: MatTooltip;
  hoveredItem: TimeTubeItem;

  @HostBinding('class.any-hovered') @Input() hovered: Worklog;
  @HostBinding('class.any-highlighted') @Input() highlighted: Worklog;
  @Input() editing: Worklog;
  @Input() editData: EditTimeData;
  @Output() hoveredChange = new EventEmitter<Worklog>();
  @Output() clickWorklog = new EventEmitter<Worklog>();

  notWorking: string;
  noTaskSelected: string;

  worklogs$ = new BehaviorSubject<Worklog[]>(undefined);
  @Input() set worklogs(value: Worklog[]) { this.worklogs$.next(value); }

  timezone$ = new BehaviorSubject<string>(undefined);
  @Input() set timezone(value: string) { this.timezone$.next(value); }

  items$: Observable<TimeTubeItem[]> =
    combineLatest([this.worklogs$, this.timezone$]).pipe(
      map(([wl, tz]) => (wl || []).map(x => ({
        ...x,
        original: x,
        left: diffSinceDayStart(x.start, tz) / secondsInDay * 100,
        width: x.time / secondsInDay * 100,
        tooltip: this.getTooltip(x.taskName, x.projectName, x.time, x.mode),
      }))),
      shareReplay(1),
    );

  readonly secondsInDay = secondsInDay;

  @Select(AuthState.hourFormat24) hourFormat24$: Observable<boolean>;

  constructor(
    private durationHms: DurationHmsPipe,
    private translate: TranslateService,
    private elRef: ElementRef<HTMLElement>,
    private trackingContext: TrackingContext,
    private trackingService: TrackingService,
  ) {
    this.notWorking = this.translate.instant('editTime.notWorking');
    this.noTaskSelected = this.translate.instant('timeline.onComputer');

    this.hoveredChange.subscribe(x => {
      if (x) {
        this.tooltip.show();
      } else {
        this.tooltip.hide();
      }
    });
  }

  getTooltip(taskName: string, projectName: string, time: number, mode: StatusMode) {
    return ((mode === 'offline') ?
      this.notWorking :
      (!taskName ? this.translate.instant('timeline.modes.' + mode) : `${taskName}${projectName ? `(${projectName})` : ''}`))
      + ` - ${this.durationHms.transform(time)}`;
  }

  trackBy(index, item: TimeTubeItem) {
    return item.start;
  }

  @HostListener('mousemove', ['$event'])
  async mousemove(ev: MouseEvent) {
    const width = this.elRef.nativeElement.offsetWidth;
    const position = ev.clientX - this.elRef.nativeElement.getBoundingClientRect().left;
    const hoveredTime = position / width * 100;
    const items = await firstValueFrom(this.items$);

    this.hoveredItem = items.find(x => (x.left <= hoveredTime) && ((x.left + x.width) > hoveredTime));
    this.hovered = this.hoveredItem?.original;
    this.hoveredChange.next(this.hovered);
  }

  @HostListener('mouseleave', ['$event'])
  mouseleave() {
    this.hoveredItem = null;
    this.hovered = null;
    this.hoveredChange.next(null);
  }

  @HostListener('click')
  click() {
    if (this.hovered) {
      this.clickWorklog.emit(this.hovered);
      const context = this.trackingContext.context;
      const props = { ...context, ...{ option: 'timetube', selectedWorklog: this.hovered } };
      this.trackingService.track('', props);
    }
  }
}
