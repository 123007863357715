import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { AuthState } from 'src/app/store/auth/auth.state';
import { SetExportOptions } from './store/actions';
import { DurationFormat, ExportOptions, ExportOptionsStateModel } from './store/model';

export type ExportDialogPage = 'projectsReport' | 'activitySummary' | 'timelineReport'
  | 'hoursTracked' | 'productivity' | 'productivity-apps' | 'attendance' | 'manageUsers' | 'internetReport';

interface ExportDialogConfig {
  page: ExportDialogPage;
  showContentColumn?: false;
  showOrganizationColumn: true;
  showUserFieldsColumn?: true;
  customFootnote?: string;
}

@Component({
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent {
  options = {
    fileType: ['csv', 'xls'],
    timeFormat: ['hours', 'decimal', 'digital'] as DurationFormat[],
    organization: ['summary', 'daily'],
    content: ['onlyTimeWorked', 'timeWorkedStartEnd'],
    userFields: ['name', 'email', 'employeeId'],
    userAdditionalColumns: ['projects', 'groups'],
  };
  page: ExportDialogPage;
  customFootnote: string;
  showColumnRenameNote: boolean;
  isRegularUser: boolean;

  showContentColumn = false;
  showOrganizationColumn = true;
  showUserFieldsColumn = true;
  showUserAdditionalColumns = false;
  showCustomExportFootnote = false;
  small = false;
  defaultTimeformat = ['hours'];

  exportOptions = new BehaviorSubject<ExportOptions>({} as ExportOptions);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ExportDialogConfig,
    private store: Store,
  ) {
    this.page = data.page;
    this.isRegularUser = this.store.selectSnapshot(AuthState.company)?.role === 'user';
    if (this.page) {
      switch (this.page) {
        case 'projectsReport':
          this.showOrganizationColumn = true;
          this.showColumnRenameNote = true;
          this.options.organization = ['summary', 'daily', 'onlyProjectTotal', 'onlyTaskTotal'];
          break;
        case 'activitySummary':
          this.showCustomExportFootnote = true;
          this.showColumnRenameNote = true;
          this.showOrganizationColumn = false;
          this.defaultTimeformat = ['digital'];
          break;
        case 'attendance':
          this.showOrganizationColumn = false;
          this.showColumnRenameNote = true;
          this.defaultTimeformat = ['digital'];
          this.small = true;
          break;
        case 'timelineReport':
          this.defaultTimeformat = ['digital'];
          this.options.organization = ['timelineSummary', 'timelineDetailed'];
          this.showContentColumn = data.showContentColumn;
          this.showOrganizationColumn = data.showOrganizationColumn;
          this.showCustomExportFootnote = true;
          this.showColumnRenameNote = true;
          break;
        case 'hoursTracked':
          this.showOrganizationColumn = true;
          this.showCustomExportFootnote = true;
          this.showColumnRenameNote = true;
          this.options.organization = ['onlyTotal', 'fullTable'];
          this.showUserFieldsColumn = data.showUserFieldsColumn;
          break;
        case 'productivity':
          this.showOrganizationColumn = true;
          this.options.organization = ['productivity', 'webAppUsage'];
          break;
        case 'manageUsers':
          this.showOrganizationColumn = false;
          this.showContentColumn = false;
          this.showUserFieldsColumn = false;
          this.showUserAdditionalColumns = true;
          this.small = true;
          break;
        case 'productivity-apps':
          this.customFootnote = data.customFootnote;
          break;
        case 'internetReport':
          this.showOrganizationColumn = false;
          break;
        default:
          break;
      }

      const savedOptions = this.store.selectSnapshot((st: ExportOptionsStateModel) => st.exportOptions?.[this.page]);
      this.exportOptions.next({ ...this.getDefaultExportOptions(), ...savedOptions });
    }
    this.showContentColumn = typeof data.showContentColumn !== 'undefined' ? data.showContentColumn : this.showContentColumn;
    this.showOrganizationColumn =
      typeof data.showOrganizationColumn !== 'undefined' ? data.showOrganizationColumn : this.showOrganizationColumn;

    this.exportOptions.subscribe(options => {
      this.store.dispatch(new SetExportOptions(this.page, options));
    });
  }

  getDefaultExportOptions() {
    const organization = this.options.organization[0];
    const userFields = ['name'];
    const timeFormat = this.defaultTimeformat;
    const userAdditionalColumns = [];

    return {
      content: 'onlyTimeWorked',
      fileType: 'csv',
      timeFormat,
      organization,
      userFields,
      userAdditionalColumns,
    } as ExportOptions;
  }

  setOption(type: string, value: any, checked: boolean = null) {
    const options = Object.assign({}, this.exportOptions.getValue());
    if (checked === null) {
      options[type] = value;
    } else if (Array.isArray(options[type])) {
      options[type] = [...options[type]];
      if (checked === true) {
        options[type].push(value);
      } else {
        options[type] = options[type].filter(option => option !== value);
      }
    }
    this.exportOptions.next(options);
  }
}
