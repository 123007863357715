import { AfterContentChecked, ChangeDetectorRef, Component, HostListener, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { WizardComponent } from '@shared/wizard';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable } from 'rxjs';
import IntegrationList from 'src/app/pages/integrations/integrations-list';
import { OnboardingPages } from 'src/app/pages/onboarding/onboarding-pages';
import { CompanyService } from 'src/app/services/company/company.service';
import { DeviceDetector } from 'src/app/services/segment/device-detector';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { BulkInviteObject, UserService } from 'src/app/services/user/user.service';
import { CompanySettingsChanged, SetUeMemberFlag } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { PopLoading, PushLoading } from 'src/app/store/loading/loading.actions';
import {
  CompleteWelcomeStep,
  OnboardingWizardConfig,
  ResetOnboardingWizardConfig,
  SetOnboardingWizardConfig
} from 'src/app/store/onboarding/onboarding.actions';
import { OnboardingState } from 'src/app/store/onboarding/onboarding.state';
import { AuthCompany, AuthUser } from 'src/models';

interface CompanyDetailsFrom {
  companyName: string;
  companySize: string;
  painPoint: string;
  otherPainPoint: string;
  phone: string;
}

@UntilDestroy()
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})

export class OnboardingComponent implements OnInit, AfterContentChecked {
  readonly interactiveFeatures = ['screenshots', 'tasksMode', 'allowEditTime', 'payrollFeature', 'workScheduleFeature'];
  readonly silentFeatures = ['screenshots'];
  readonly painPoints = [
    { key: 'productivity', value: 'Productivity', color: '#A9E7C4' },
    { key: 'wellness', value: 'Wellness', color: '#FFDAFF' },
    { key: 'timetrackingattendance', value: 'Time Tracking & Attendance', color: '#FCE3AC' },
    { key: 'payroll', value: 'Payroll & Billing', color: '#B1B7F0' },
  ];

  readonly integrationNames = IntegrationList.map(val => val.title);

  readonly companyInfoStep = 'companyInfoStep';
  readonly companyInfo2Step = 'companyInfo2Step';
  readonly trackingModeStep = 'trackingModeStep';
  readonly trackingModeStep2 = 'trackingModeStep2';

  readonly featuresStep = 'featuresStep';
  readonly enableExtensionStep = 'enableExtensionStep';
  readonly inviteCountStep = 'inviteCountStep';
  readonly sendInvitesStep = 'sendInvitesStep';
  readonly addCardStep = 'addCardStep';

  readonly testimonials = {
    companyInfo2Step: {
      background: '/assets/images/onboarding/testimonials/bg-top.svg',
      quoteGreen: '/assets/images/onboarding/testimonials/quotation.svg',
      header: 'Success stories',
      stories: 'By using Time Doctor, we were able to increase our profitability by 30%.',
      thumbnail: '/assets/images/onboarding/testimonials/brett.png',
      name: 'Nik Cree, CEO',
      website: 'Positive Business Online',
    },
    companyInfoStep: {
      background: '/assets/images/onboarding/testimonials/bg-top.svg',
      quoteGreen: '/assets/images/onboarding/testimonials/quotation.svg',
      header: 'Success stories',
      stories: 'If you want to take your company in the right direction, Time Doctor\'s data will make that possible for you.',
      thumbnail: '/assets/images/onboarding/testimonials/monica-thakwani.png',
      name: 'Monica Thakwani',
      website: 'Boring Commerce',
    },
    trackingModeStep: {
      background: '/assets/images/onboarding/testimonials/bg-top.svg',
      quoteGreen: '/assets/images/onboarding/testimonials/quotation.svg',
      header: 'Success stories',
      stories: 'It helps keep me focused and mindful of how much time I spend on specific projects.',
      thumbnail: '/assets/images/onboarding/testimonials/leslie.png',
      name: 'Leslie Sentman',
      website: 'Roper Insurance',
    },
    featuresStep: {
      background: '/assets/images/onboarding/testimonials/bg-top.svg',
      quoteGreen: '/assets/images/onboarding/testimonials/quotation.svg',
      header: 'Success stories',
      stories: 'A must have tool for employers looking to effectively track productivity of their employees.',
      thumbnail: '/assets/images/onboarding/testimonials/manish.png',
      name: 'Manish Patel',
      website: 'eBool',
    },
    enableExtensionStep: {
      background: '/assets/images/onboarding/testimonials/bg-top.svg',
      quoteGreen: '/assets/images/onboarding/testimonials/quotation.svg',
      header: 'Success stories',
      stories: 'A must have tool for employers looking to effectively track productivity of their employees.',
      thumbnail: '/assets/images/onboarding/testimonials/manish.png',
      name: 'Manish Patel',
      website: 'eBool',
    },
    inviteCountStep: {
      inviteLayout: 'invite-layout',
      background: '/assets/images/onboarding/testimonials/dash.svg',
      quoteGreen: '/assets/images/onboarding/testimonials/green.svg',
      header: 'Why invite your team?',
      stories1: 'To truly see the power of Time Doctor and what productivity insights it can provide for your organization, you need data. \
                The more users you invite, the more data you will collect during your trial.',
      stories2: 'Time Doctor’s dashboards and analytics deliver visibility into how time is spent at the user, team and department levels.',
    },
    sendInvitesStep: {
      inviteLayout: 'invite-layout',
      background: '/assets/images/onboarding/testimonials/dash.svg',
      quoteGreen: '/assets/images/onboarding/testimonials/green.svg',
      header: 'Why invite your team?',
      stories1: 'Inviting your team is the ONLY way you can see REAL data on the dashboards and see how it works. ',
      stories2: 'You can try it with only one person or a few people but you need to invite some of your team to test it out.',
    },
  };

  readonly pages = OnboardingPages;
  @ViewChild('onboardingDialog', { static: true }) onboardingDialog: TemplateRef<{}>;
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(AuthState.user) user$: Observable<AuthUser>;

  currentCompany: AuthCompany;
  currentUser: AuthUser;
  dialogRef: MatDialogRef<any>;

  storedConfig: OnboardingWizardConfig;

  companyInfoForm1: FormGroup;
  companyInfoForm2: FormGroup;
  featuresForm: FormGroup;
  inviteForm: FormGroup;

  errorMessage: string;

  trackingMode$: BehaviorSubject<'visible' | 'silent'>;
  enableExtension$ = new BehaviorSubject(true);
  inviteCount$ = new BehaviorSubject<number>(null);
  computerOwner: 'employees' | 'company';

  showIntegrationsList = false;
  features = {};

  showAddCard: boolean;
  isPartnerAccount: boolean;

  completedWizard: boolean;
  painPointEmpty: boolean;
  companySizeEmpty: boolean;

  inviteSuccess: Array<boolean>;
  inviteError: boolean;
  ownership: string;
  noEmailError: boolean;
  sendingInvite: boolean;
  capterraConversion: boolean;

  @ViewChild('wizard') stepper: any;
  progressBarCount: number;
  stepNumber: string;
  isUEMember = false;
  displayError = false;
  companyName: string;
  userEmail: string;
  trackInviteUsers = true;
  fireCompanyEngagement = true;
  trackValidUsers = true;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private store: Store,
    private segment: SegmentService,
    private companyService: CompanyService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private deviceDetector: DeviceDetector,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {
    this.currentCompany = this.store.selectSnapshot(AuthState.company);
    this.currentUser = this.store.selectSnapshot(AuthState.user);
    const isVisible = this.currentCompany?.companySettings?.trackingMode === 'interactive' || this.currentCompany?.companySettings?.trackingMode === 'mixed';
    this.trackingMode$ = new BehaviorSubject(isVisible ? 'visible' : 'silent');
    this.inviteCount$.next(2);
    this.inviteSuccess = Array(this.inviteCount$.value).fill(0).map(() => false);
    if (this.currentCompany?.companySettings?.custom['onboardingStep']) {
      this.store.dispatch(new SetOnboardingWizardConfig({ currentStep: this.currentCompany?.companySettings?.custom['onboardingStep'] }));
    }
    this.storedConfig = this.store.selectSnapshot(OnboardingState.wizardConfig);
    this.companyInfoForm1 = this.fb.group({
      companySize: ['', Validators.required],
    });
    this.companyInfoForm2 = this.fb.group({
      painPoint: [''],
      otherPainPoint: [''],
      phone: [''],
    });
    this.ownership = 'team members';
    combineLatest([this.trackingMode$, this.inviteCount$])
      .pipe(untilDestroyed(this)).subscribe(([selectedType, inviteCount]) => {
        const featureSet = selectedType === 'visible' ? this.interactiveFeatures : this.silentFeatures;
        this.featuresForm = this.fb.group(featureSet.reduce((acc, curr) => {
          let value = !!this.currentCompany?.companySettings[curr];
          if (curr === 'tasksMode') { value = this.currentCompany?.companySettings[curr] === 'preset'; }
          if (curr === 'screenshots') { value = this.currentCompany?.companySettings[curr] > 0; }
          acc[curr] = [value];
          return acc;
        }, {}));

        this.inviteForm = this.fb.group({
          emails: this.fb.array(Array(inviteCount).fill(0).map(() => this.fb.control('', [Validators.pattern(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/), this.isOwnEmail()]))),
          employees_name: this.fb.array(Array(inviteCount).fill(0).map(() => this.fb.control('', []))),
          access_level: this.fb.array(Array(inviteCount).fill(0).map(() => this.fb.control('', []))),
        });
      });
    this.companyName = this.segment.getExperimentDataByName('companyname');
    console.warn(this.currentUser);
    this.userEmail = this.currentUser?.email.substring(this.currentUser?.email.lastIndexOf('@') + 1);
    console.warn(this.currentUser?.email.substring(this.currentUser?.email.lastIndexOf('@') + 1));
    this.capterraConversion = this.loadCapterraConversion();
  }
  loadCapterraConversion() {
    if (this.segment.getParameter('utm_source') === 'gartner' &&
      this.segment.getParameter('utm_medium') === 'capterra' &&
      !this.currentCompany?.companySettings?.custom?.['gartnerConversion']
    ) {
      this.updateCompanySettings({ 'custom.gartnerConversion': true });
      return true;
    }
    return false;
  }
  openTrackModeLearnDialog(): void {
    const dialogRef = this.dialog.open(DialogTrackModeLearnMoreComponent, {
      width: '650px',
      height: '650px',
      data: {},
    });
    const currentUrl = 'https://2a.' + this.segment.getDomain() + '/onboarding';
    this.segment.track('Clicked Learn More Onboarding', { category: 'Onboarding', url: currentUrl }, {}, true);
  }

  ngOnInit(): void {
    this.measureTimeToLoad();
    this.isPartnerAccount = window.location.search.includes('partner');
    const isCardAdded = this.currentCompany?.userSettings?.custom?.completedStep?.['addCreditCard'];
    this.showAddCard = !(isCardAdded || this.isPartnerAccount);
    if (this.isPartnerAccount) {
      this.segment.serverSideCall({
        reseller: true,
        TDAffiliateType: 'PartnerStack - Reseller',
      });
    }
    this.trackAccountCreated();
    this.segment.trackPartnerStackSignup(this.currentUser);
    this.showOnboardingDialog();
    this.progressBarCount = 0;
    this.getUserCountry();
  }

  async getUserCountry() {
    const { country } = await this.deviceDetector.getClientIpAndCountry();
    this.isUEMember = this.deviceDetector.isCountryInEu(country);
    this.store.dispatch(new SetUeMemberFlag(this.isUEMember));
    this.updateCompanySettings({ 'custom.isUEMember': this.isUEMember });
  }

  private measureTimeToLoad(): void {
    if (this.activatedRoute.snapshot.queryParamMap.get('ts') !== '') {
      const ts = parseInt(this.activatedRoute.snapshot.queryParamMap.get('ts'));
      const trialStart = DateTime.fromMillis(ts);
      console.log('TRIAL TO ONBOARDING TIME MS', DateTime.now().diff(trialStart, 'milliseconds').get('milliseconds'));
    }
  }

  ngAfterContentChecked() {
    if (!this.completedWizard) {
      this.cd.detectChanges();
    }
  }

  getStepPercentage = (wizard: WizardComponent) => {
    this.progressBarCount = 80;
    const length = wizard.steps?.length;
    return length ? ((wizard.index + 1) / length) * 100 : 100;
  };

  addInviteField() {
    // this.inviteCount$.next(this.inviteCount$.value + 1);
    this.inviteSuccess[this.inviteCount$.value] = false;
    (this.inviteForm.get('emails') as FormArray).push(this.fb.control('', [Validators.email]));
    (this.inviteForm.get('employees_name') as FormArray).push(this.fb.control('', []));
    (this.inviteForm.get('access_level') as FormArray).push(this.fb.control('', []));
    this.cd.detectChanges();
  }

  skipInvite = async () => {
    this.completedWizard = true;
    await this.completeOnboarding();
    await this.segment.trackOnboardingEvent('Skipped Invite User during Onboarding');
  };

  getInviteControls() {
    return (this.inviteForm.get('emails') as FormArray).controls as FormControl[];
  }

  getInviteControlsByFieldName(fieldName: string) {
    return (this.inviteForm.get(fieldName) as FormArray).controls as FormControl[];
  }

  onStepChange(stepId: string) {
    if (stepId !== null) {
      const top = document.querySelector('.mat-dialog-container');
      if (top !== null) {
        top.scrollIntoView();
      }
      this.store.dispatch(new SetOnboardingWizardConfig({ currentStep: stepId }));
      this.updateCompanySettings({ 'custom.onboardingStep': stepId });
      const element = document.querySelector('.mat-dialog-container');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.setProgressBar(stepId);
    }
  }

  setEmptyCompanySize($event) {
    this.store.dispatch(new SetOnboardingWizardConfig({ companySize: $event.value }));
    this.onboardingStepTracking(this.pages[this.trackingModeStep]);
    const onboardingStep = this.pages[this.trackingModeStep];
    this.segment.serverSideCall({ tdpeopleinthecompany: $event.value, onboarding_step: onboardingStep });
    this.segment.trackOnboardingEvent('Company Name and Size', { companysize: $event.value });
    this.companySizeEmpty = false;
    this.stepper.next();
  }

  setPainPoint() {
    this.painPointEmpty = false;
  }

  setAdditionalCompanyInfo = () => {
    if (this.companyInfoForm2.invalid) {
      return false;
    }
    const formData: Partial<CompanyDetailsFrom> = this.companyInfoForm2.value;

    if (!formData.painPoint) {
      this.painPointEmpty = true;
      return false;
    }

    const phonenumber = formData.phone.trim().length > 3 ? formData.phone : '';

    this.segment.serverSideCall(
      {
        phoneNumber: phonenumber,
        onboarding_step: this.pages[this.companyInfoStep],
      },
      {
        other_lead_pain_point: formData.otherPainPoint,
        lead_pain_point: formData.painPoint,
        phonenumber,
      },
    );
    this.segment.trackOnboardingEvent('Pain Point Selected Option', { painpoint: formData.painPoint });
    this.onboardingStepTracking(this.pages[this.companyInfoStep]);
  };

  selectTrackMode = ($event, wizard: WizardComponent) => {
    const trackingMode = this.trackingMode$.value === 'visible' ? 'interactive' : 'silent';
    let trackMode = $event;
    if ($event === 'employees') {
      this.trackingMode$.next('visible');
      trackMode = 'visible';
      this.segment.setCookie('wizardSteps', 4, 365);
      this.segment.trackOnboardingEvent('Tracking Type Onboard', { category: 'Onboarding', trackingType: trackingMode, ownership: 'Team Members' });
      this.segment.serverSideCall(
        {
          onboarding_step: this.pages[this.sendInvitesStep],
          taskMode: 'preset',
        },
        {},
      );
      this.onboardingStepTracking(this.pages[this.sendInvitesStep]);
      const ueMember = this.currentCompany?.companySettings?.custom?.['isUEMember'] || this.isUEMember;
      if (ueMember) {
        this.segment.trackInAppEvents('Skipped Tracking Type Screen', { reason: 'Team Member Ownership GDPR' });
      } else {
        this.segment.trackInAppEvents('Skipped Tracking Type Screen', { reason: 'Team Member Ownership NonGDPR' });
      }
      wizard.setIndex(4);
    }
    if ($event === 'company') {
      this.ownership = 'company';
      this.onStepperSelectionChange(true, 'trackingModeStep');
    }
  };

  selectTrackOption = ($event, wizard: WizardComponent) => {
    const trackMode = $event;
    if (trackMode === 'visible') {
      this.trackingMode$.next(trackMode);
      const ueMember = this.currentCompany?.companySettings?.custom?.['isUEMember'] || this.isUEMember;
      if (ueMember) {
        this.sendUEskipTracking();
        this.setTrackingMode();
      }
      this.onStepperSelectionChange();
    } else if (trackMode === 'silent') {
      this.trackingMode$.next(trackMode);
      this.setTrackingMode();
      this.completedWizard = true;
      this.completeOnboarding();
    }
  };

  setTrackingMode = () => {
    const ueMember = this.currentCompany?.companySettings?.custom?.['isUEMember'] || this.isUEMember;
    const trackingMode = this.trackingMode$.value === 'visible' ? 'interactive' : 'silent';
    let tasksMode = 'preset';
    if (trackingMode === 'silent' && !ueMember) {
      tasksMode = 'off';
      this.segment.trackOnboardingEvent('Tracking Type Onboard', { category: 'Onboarding', trackingType: 'silent', ownership: this.ownership });
    }
    this.updateCompanySettings({ trackingMode, tasksMode });

    if (trackingMode === 'interactive' && !ueMember) {
      this.onboardingStepTracking(this.pages[this.sendInvitesStep]);
      this.segment.trackOnboardingEvent('Tracking Type Onboard', { category: 'Onboarding', trackingType: 'interactive', ownership: this.ownership });
      this.segment.serverSideCall(
        {
          onboarding_step: this.pages[this.sendInvitesStep],
          taskMode: tasksMode,
        },
        {},
      );
    } else {
      this.segment.serverSideCall(
        { taskMode: tasksMode },
        {},
      );
    }
  };

  setFeatures = () => {
    const companySettings = {
      ...this.featuresForm.value,
      screenshots: this.featuresForm.value.screenshots ? 900 : 0,
      tasksMode: this.featuresForm.value.tasksMode ? 'preset' : 'off',
    };
    this.updateCompanySettings(companySettings);
    let onboardingStep = this.pages[this.inviteCountStep];
    if (this.trackingMode$.value === 'visible' && this.featuresForm.value.tasksMode) {
      onboardingStep = this.pages[this.enableExtensionStep];
    }
    this.segment.serverSideCall({
      taskMode: companySettings.tasksMode,
      onboarding_step: onboardingStep,
    }, {});
  };

  enableExtension = () => {
    const companySettings = { 'custom.browserExtensionEnabled': this.enableExtension$.value };
    this.updateCompanySettings(companySettings);
    this.segment.serverSideCall(
      { onboarding_step: this.pages[this.inviteCountStep] },
      {},
    );
  };

  isOwnEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value === this.currentUser?.email ? { serverError: true, serverErrorMessage: 'This email address has already been added.' } : null;
    };
  }

  checkDuplicateEntry() {
    this.noEmailError = false;
    const invitedEmails = [];
    const emailControls = this.inviteForm.controls['emails'] as FormArray;
    const emails = this.inviteForm.value.emails;
    emails.map(async (email, index) => {
      if (emailControls.controls[index].getError('duplicateEmail')) {
        emailControls.controls[index].setErrors(null);
      }
      if (invitedEmails.includes(email)) {
        this.noEmailError = true;
        this.inviteError = true;
        emailControls.controls[index].setErrors({ serverError: true, serverErrorMessage: 'This email address has already been added.', duplicateEmail: true });
      } else if (email.length) {
        invitedEmails.push(email);
      }
    });
  }

  ValidateEmails($event: any) {
    const email = $event; //capture Email string
    const emails = this.inviteForm.value.emails; //array of emails to get index of selected
    const accessLevel = this.inviteForm.value.access_level; //array of access levels

    if (email === '') { return false; }
    const isEmailValid = this.isValidEmail(email);
    // if emaild is valid
    if (!isEmailValid) { return false; }
    // if email is owner of the account return
    if (email === this.currentUser.email) { return false; }
    const EmailIndex = emails.indexOf(email);
    if (EmailIndex === -1) { return; }
    const access = accessLevel[EmailIndex] || 'user';
    this.trackInvitedEmails(email, access);
  }

  private isValidEmail(emailAdress: string) {
    const regexEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (emailAdress.toLowerCase().match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }

  private trackInvitedEmails(email: string, accessLevel: string) {
    const trackOpts = {
      inviteeusertype: accessLevel,
      inviteuseremail: email.toLowerCase(),
    };

    if (this.trackValidUsers) {
      this.segment.trackInAppEvents('Invite Users Onboarding typed valid email', trackOpts);
      this.trackValidUsers = false;
    }
  }

  sendInvites = async () => {
    const emails = this.inviteForm.value.emails;
    const employeesName = this.inviteForm.value.employees_name;
    const accessLevel = this.inviteForm.value.access_level;
    const emailControls = this.inviteForm.controls['emails'] as FormArray;
    const invitedEmails = [];
    this.inviteError = false;
    let emptyFields = 0;
    this.sendingInvite = true;
    emails.map(async (email, index) => {
      if (invitedEmails.some(data => data.email === email)) {
        this.inviteError = true;
        emailControls.controls[index].setErrors({ serverError: true, serverErrorMessage: 'This email address has already been added.' });
        this.segment.trackInAppEvents('Failed Onboarding Invite Submission', { reason: 'Duplicated email address' });
      } else if (email.length) {
        const role = accessLevel[index] !== '' ? accessLevel[index] : 'user';
        invitedEmails.push({ name: employeesName[index], email, role });
      }
      if (!email.trim().length) {
        emptyFields++;
      }
    });
    if (emptyFields === emails.length) {
      emailControls.controls[0].setErrors({ serverError: true, serverErrorMessage: 'Please provide at least one email address to invite the team' });
      this.inviteError = true;
      this.noEmailError = true;
      this.sendingInvite = false;
      this.segment.trackInAppEvents('Failed Onboarding Invite Submission', { reason: 'email address cannot be blank' });
      return false;
    }
    if (this.inviteError) {
      this.sendingInvite = false;
      return false;
    }
    if (this.inviteForm.invalid) {
      this.sendingInvite = false;
      this.segment.trackInAppEvents('Failed Onboarding Invite Submission', { reason: 'invalid email address' });
      return false;
    }

    const bulkInvite: BulkInviteObject = {
      tagIds: [],
      users: invitedEmails,
    };
    const inviteResult = await this.userService.bulkInvite(bulkInvite);
    invitedEmails.forEach((invite, index) => {
      if (inviteResult.data[index][invite.email].status === 'sent') {
        this.inviteSuccess[index] = true;
      }
    });
    if (this.hasInviteSuccess()) {
      await lastValueFrom(this.store.dispatch(new CompleteWelcomeStep('addEmployee')));
      const finalEmails = emails.filter(Boolean);
      const finalAccessLevels = accessLevel.filter(Boolean);
      if (finalEmails.length !== finalAccessLevels.length) {
        const howMany = finalEmails.length - finalAccessLevels.length;
        for (let i = 0; i < howMany; i++) {
          finalAccessLevels.push('user');
        }
      }
      const totalnumberofinvited = finalEmails.length;
      const numberofuserinvited = finalAccessLevels.filter(users => users === 'user').length;
      const numberofadmininvited = finalAccessLevels.filter(admins => admins === 'admin').length;
      const numberofmanagerinvited = finalAccessLevels.filter(managers => managers === 'manager').length;
      await this.segment.trackOnboardingEvent('Invite User during Onboarding', { inviteeEmails: finalEmails, totalnumberofinvited, numberofuserinvited, numberofadmininvited, numberofmanagerinvited });
    }
    this.sendingInvite = false;
    this.onStepperSelectionChange(false);
  };

  private hasInviteSuccess() {
    for (const value of this.inviteSuccess) {
      if (value) {
        return true;
      }
    }
    return false;
  }
  private countInviteSuccess() {
    let successCount = null;
    for (const value of this.inviteSuccess) {
      if (value) {
        successCount++;
      }
    }
    return successCount;
  }

  private async updateCompanySettings(settings) {
    await lastValueFrom(this.companyService.putCompanySettings(this.currentCompany?.id, settings));
  }

  private async trackAccountCreated() {
    const isTrackedAccountCreated = this.currentCompany?.companySettings?.custom['trackedAccountCreated'] || false;
    if (!isTrackedAccountCreated) {
      const geoInfo = await this.segment.detectCountryByIp();
      const contactCountry = geoInfo?.names?.en || '';
      const companyname = this.segment.getCompanyName(this.companyName, this.userEmail) ?? this.currentCompany?.name;
      await this.segment.serverSideCall(
        {
          name: companyname?.trim(),
          onboarding_step: this.pages[this.companyInfo2Step],
        },
        {
          workspace_tracking_type: 'interactive',
          contact_country: contactCountry,
          workspaceName: companyname?.trim(),
        },
        false,
        true,
      );
      this.segment.logPageOrEventCall('Account Created', 'event');
      await this.segment.trackAccountCreated();
      await lastValueFrom(this.store.dispatch(new CompleteWelcomeStep('createAcc')));
      this.onboardingStepTracking(this.pages[this.companyInfo2Step]);
      await this.updateCompanySettings({
        'custom.trackedAccountCreated': true,
        'custom.showSpecialOffer': true,
      });
    }
    this.store.dispatch(new CompanySettingsChanged(null, null, { showSpecialOffer: true, trackedAccountCreated: true }));
    let payrollFeature = false;
    if (!this.currentCompany?.pricingPlan.includes('basic')) {
      payrollFeature = true;
    }
    await this.updateCompanySettings({
      workScheduleFeature: true,
      payrollFeature,
      tasksMode: 'preset',
    });
  }
  private redirectToMainWebapp() {
    const reqHostname = this.segment.getDomain();
    const auth = this.store.selectSnapshot(AuthState);
    const token = auth && auth.token;
    let redirectURI = 'https://2.timedoctor.com/?from2a=true&token=' + token;
    if (reqHostname === 'timedoctortest.com' || reqHostname === 'timedoctorstaging.com') {
      redirectURI = 'https://2.timedoctortest.com/?from2a=true&token=' + token;
    } else if (reqHostname === 'timedoctordev.com') {
      redirectURI = 'http://2.timedoctordev.com:4300/?from2a=true&token=' + token;
    }
    window.location.href = redirectURI;
  }
  completeOnboarding = async () => {
    this.store.dispatch(new PushLoading());
    this.completedWizard = true;
    const inviteCount = this.countInviteSuccess() ?? null;
    const trackingMode = this.trackingMode$.value === 'visible' ? 'interactive' : 'silent';
    try {
      await this.segment.serverSideCall(
        {
          invitePopupSelection: inviteCount,
          onboarding_step: 'Completed Onboarding',
        },
        { workspace_tracking_type: trackingMode },
        true,
      );
      await this.segment.trackCompleteOnBoarding();
      this.onboardingStepTracking('Completed Onboarding');
    } finally {
      await this.updateCompanySettings({ 'custom.completedOnBoarding': true });
      await this.store.dispatch(new CompanySettingsChanged(null, null, { completedOnBoarding: true }));
      await this.store.dispatch(new ResetOnboardingWizardConfig());
      this.router.navigate(['/dashboard']);
      this.dialogRef.close();
      this.store.dispatch(new PopLoading());
    }
  };

  onboardingStepTracking(step) {
    this.segment.trackInAppEvents('Onboarding Step', { onboardingstep: step });
  }

  private sendUEskipTracking() {
    this.segment.serverSideCall(
      { onboarding_step: this.pages[this.sendInvitesStep] },
      {},
    );
    this.onboardingStepTracking(this.pages[this.sendInvitesStep]);
    this.segment.trackInAppEvents('Skipped Tracking Type Screen', { reason: 'Company Ownership GDPR' });
  }

  public onStepperSelectionChange(scroll = true, step = null, wizard?: WizardComponent) {
    const ueMember = this.currentCompany?.companySettings?.custom?.['isUEMember'] || this.isUEMember;
    if (ueMember && step === 'trackingModeStep') {
      this.stepper.setIndex(3);
      this.sendUEskipTracking();
      this.stepper.next();
    } else {
      this.stepper.next();
    }
    if (scroll) {
      const element = document.querySelector('.wizard-inner');
      const elementInvites = document.querySelector('.invites');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      elementInvites.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  showOnboardingDialog = () => {
    this.dialogRef = this.dialog.open(this.onboardingDialog, {
      data: {}, panelClass: 'full-onboarding-modal', disableClose: true, autoFocus: false, closeOnNavigation: false,
    });
  };

  setProgressBar(currentStep: string, wizard?: WizardComponent) {
    setTimeout(() => {
      switch (currentStep) {
        case 'companyInfo2Step':
          this.progressBarCount = 0;
          this.stepNumber = '1 of 5';
          break;
        case 'companyInfoStep':
          this.progressBarCount = 21;
          this.stepNumber = '2 of 5';
          break;
        case 'trackingModeStep':
          this.progressBarCount = 40;
          this.stepNumber = '3 of 5';
          break;
        case 'trackingModeStep2':
          this.progressBarCount = 60;
          this.stepNumber = '4 of 5';
          break;
        case 'sendInvitesStep':
          this.progressBarCount = 80;
          this.stepNumber = '5 of 5';
          break;
      }
    });
  }

  fireEngagementTracking($event: any) {
    this.checkDuplicateEntry();
    this.trackClickEngagement(Number($event));
  }

  trackClickEngagement(index: number) {
    const emails = this.inviteForm.value.emails; //array of emails to get index of selected
    const accessLevel = this.inviteForm.value.access_level; //array of access levels
    const inviteEmail = emails[index];
    const access = accessLevel[index] || 'user';
    if (inviteEmail !== '') {
      if (inviteEmail === this.currentUser.email) { return false; }
    }
    const trackOpts = {
      inviteeusertype: access,
      inviteuseremail: inviteEmail,
    };
    if (this.trackInviteUsers) {
      this.segment.trackInAppEvents('Invite users onboarding email active', trackOpts);
    }
    this.trackInviteUsers = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    if (!this.completedWizard) {
      $event.returnValue = true;
    }
  }
}

@Component({
  selector: 'app-dialog-track-mode-learn-more',
  styleUrls: ['dialog-track-mode-learn-more.scss'],
  templateUrl: 'dialog-track-mode-learn-more.html',
})
export class DialogTrackModeLearnMoreComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogTrackModeLearnMoreComponent>,
    @Inject(MAT_DIALOG_DATA) public articleUrl: string) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
