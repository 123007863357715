<!-- It would be ideal to refactor this to use a single html with content projection -->
<ng-container *ngIf="!withContent">
  <div class="info-box" fxLayout="row" *ngIf="showLink && !company">
    <img class="info-icon" src="/assets/images/onboarding/info-round.svg" alt="">
    <div class="info-mode"> {{ 'onboarding.trackingModeStep.infoTrackingMethod' | translate }} <span
      class="learn-more pointer" (click)="openTrackModeLearnDialog()">
    {{ 'onboarding.trackingModeStep.infoTrackingLink' | translate }}</span>
    </div>
  </div>

  <div class="info-box" fxLayout="row" *ngIf="!showLink && !company">
    <img class="info-icon" src="/assets/images/onboarding/info-round.svg" alt="">
    <div class="info-mode">
      {{ 'onboarding.trackingModeStep.infoOwners' | translate }}
    </div>
  </div>

  <div class="info-box" fxLayout="row" *ngIf="company">
    <img class="info-icon" src="/assets/images/onboarding/info-round.svg" alt="">
    <div class="info-mode">
      {{ 'onboarding.companyInfoStep.companyFooter' | translate }}
    </div>
  </div>
</ng-container>

<div class="info-box" fxLayout="row" *ngIf="withContent">
  <img class="info-icon" src="/assets/images/onboarding/info-round.svg" alt="">
  <div class="info-mode">
    <ng-content></ng-content>
  </div>
</div>
