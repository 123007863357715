import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, LayoutGapStyleBuilder } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UtilModule } from '@shared';
import { OnboardingFooterComponent } from 'src/app/components/onboarding-footer/onboarding-footer.component';
import { OnboardingHeaderComponent } from 'src/app/components/onboarding-header/onboarding-header.component';
import { BackHeaderComponent } from '../components/back-header/back-header.component';
import { CopyToClipboardComponent } from '../components/copy-to-clipboard/copy-to-clipboard.component';
import { DailyTimetubeComponent } from '../components/daily-timetube/daily-timetube.component';
import { EmptyStateComponent } from '../components/empty-state/empty-state.component';
import { EmptyComponent } from '../components/empty/empty.component';
import { ExportDocButtonComponent } from '../components/export-doc-button/component';
import { ExportOptionsButtonComponent } from '../components/export-options-button/export-options-button.component';
import { InfoTooltipComponent } from '../components/info-tooltip/info-tooltip.component';
import { InlineEditComponent } from '../components/inline-edit/inline-edit.component';
import { LogoutComponent } from '../components/logout/component';
import { PercentageBarComponent } from '../components/percentage-bar/percentage-bar.component';
import { RoleSelectorComponent } from '../components/role-selector/role-selector.component';
import { SearchComponent } from '../components/search/search.component';
import { SidepanelComponent } from '../components/sidepanel';
import { StatusAvatarComponent } from '../components/status-avatar/status-avatar.component';
import { ThresholdMenuComponent } from '../components/threshold-menu/threshold-menu.component';
import { TimetubeComponent } from '../components/timetube/timetube.component';
import { TruncatedTextComponent } from '../components/truncated-text/truncated-text.component';
import { UserFilterDropdownComponent } from '../components/user-filter-dropdown/user-filter-dropdown.component';
import { UserComponent } from '../components/user/component';
import { FixedHeaderDirective } from '../directives/cdk-fixed-header.component';
import { ClickTrackingDirective } from '../directives/click-tracking.directive';
import { CustomLayoutGapStyleBuilder } from '../directives/flex-gap-style-builder.directive';
import { FocusInvalidInputDirective } from '../directives/focus-invalid-input.directive';
import { MinValidator } from '../directives/min-max-validators.directive';
import { RxModelDirective } from '../directives/rx-model/rx-model.directive';
import { ScrollIntoViewDirective } from '../directives/scroll-into-view.directive';
import { ScrolledDirective } from '../directives/scrolled.directive';
import { SkipNavigationDirective } from '../directives/skip-navigation.directive';
import { ClickStopPropagationDirective } from '../directives/stop-propagation.directive';
import { SubmitDirective } from '../directives/submit.directive';
import { WatchSizeDirective } from '../directives/watch-size.directive';
import { ClockRangePipe } from '../pipes/clock-range/clock-range.pipe';
import { DurationHumanizePipe } from '../pipes/duration-long/duration-humanize.pipe';
import { ExtendedDatePipe } from '../pipes/extended-date/extended-date.pipe';
import { FirstNamePipe } from '../pipes/first-name';
import { SafePipe } from '../pipes/safe/safe.pipe';
import { TrackingModule } from '../services/tracking/module';
import { CustomBreakPointsProvider, CustomShowHideDirective } from '../util/responsive';
import { MaterialModule } from './material.module';


const modules = [
  CommonModule,
  FlexLayoutModule,
  TranslateModule,
  FormsModule,
  MaterialModule,
  ReactiveFormsModule,
  UtilModule,
  RouterModule,
  TrackingModule,
];

const components = [
  EmptyComponent,
  LogoutComponent,
  SearchComponent,
  InfoTooltipComponent,
  EmptyStateComponent,
  StatusAvatarComponent,
  CopyToClipboardComponent,
  RoleSelectorComponent,
  TimetubeComponent,
  DailyTimetubeComponent,
  UserFilterDropdownComponent,
  TruncatedTextComponent,
  PercentageBarComponent,
  InlineEditComponent,
  BackHeaderComponent,
  ClockRangePipe,
  DurationHumanizePipe,
  SafePipe,
  ExtendedDatePipe,
  FirstNamePipe,
  FixedHeaderDirective,
  SkipNavigationDirective,
  RxModelDirective,
  SubmitDirective,
  CustomShowHideDirective,
  ClickStopPropagationDirective,
  FocusInvalidInputDirective,
  MinValidator,
  MinValidator,
  ScrollIntoViewDirective,
  ScrolledDirective,
  WatchSizeDirective,
  ExportOptionsButtonComponent,
  ExportDocButtonComponent,
  SidepanelComponent,
  UserComponent,
  ThresholdMenuComponent,
  ClickTrackingDirective,
  OnboardingHeaderComponent,
  OnboardingFooterComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    ...modules,
  ],
  exports: [
    ...modules,
    ...components,
  ],
  providers: [
    CustomBreakPointsProvider,
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder },
  ],
})
export class SharedModule { }
