import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { APP_URL } from '@shared';
import { BrandingService } from './app/services/branding.service';
import { environment } from './environments/environment';

const branding = new BrandingService(APP_URL.toString());

Sentry.init({
  dsn: environment.sentryDsn,
  ...environment.sentryRelease && { release: environment.sentryRelease },
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: branding.isDefault ? (environment.production ? 'production' : 'development') : (branding.branding + ''),
  tracesSampleRate: 1.0,
  enabled: !!environment.sentryDsn,

  beforeSend: (event, hint) => {
    // Handled by NgModule's ErrorHandler
    if (hint?.originalException && hint?.originalException instanceof HttpErrorResponse) return null;

    // Don't send sentry errors for admin login
    if (event.tags?.adminLogin) return null;

    // Don't send CORS errors
    if ((event.extra?.['__serialized__'] as any)?.error === 'ProgressEvent') return null;

    return event;
  },

  // Reduce Sentry errors: https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Android read mode errors
    'Cannot read property \'getReadMode',
    // Network errors
    'Failed to fetch',
    'Timeout has occurred',
    // 3rd party
    '$ is not defined',
    'Can\'t find variable: $',
    'https://web.delighted.com',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Ignore Google flakiness
    /googletagmanager/i,
    /\/(gtm|ga|analytics)\.js/i,
    // Firefox extensions
    /^resource:\/\//i,
    // 3rd party
    /intercomcdn/i,
    /delighted/i,
    // Temporarily disable all non-errors
    'Non-Error exception captured',
  ],
});
