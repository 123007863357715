import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/store/auth/auth.state';
import { User, UserRole } from 'src/models';

@UntilDestroy()
@Component({
  selector: 'app-user',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent {
  @Input() user: User;
  @Input() hideAvatar = false;
  @Input() hideLink = false;
  @Input() size = 24;
  @Input() textSizeRatio = 32 / 14;

  @Select(AuthState.role) role$!: Observable<UserRole>;
}
