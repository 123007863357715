import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '@shared';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleSelectorComponent),
      multi: true,
    },
  ],
})
export class RoleSelectorComponent extends ValueAccessorBase<string[]> {
  readonly roles = ['admin_owner', 'manager', 'user', 'guest'];

  isAllSelected() {
    const numSelected = this.value && this.value.length || 0;
    const numOptions = this.roles.length;
    return numSelected >= numOptions;
  }

  masterToggle() {
    const allSelected = this.isAllSelected();
    if (!allSelected) {
      this.value = [...this.roles];
    } else {
      this.value = [];
    }
  }

  setSelected(selected: boolean, item: string) {
    if (!this.value) {
      this.value = [];
    }
    if (!selected && this.value.includes(item)) {
      this.value = this.value.filter(x => x !== item);
    } else if (selected && !this.value.includes(item)) {
      this.value = [...this.value, item];
    }
  }
}
