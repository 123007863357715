import { Injectable } from '@angular/core';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateOperator, Store } from '@ngxs/store';
import { append, iif, patch } from '@ngxs/store/operators';
import { SocketService, sortBy, updateItem } from '@shared';
import { combineLatest, lastValueFrom } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { defualtUserApiLimit } from 'src/app/app/app.constants';
import { CompanyService } from 'src/app/services/company/company.service';
import { GroupService } from 'src/app/services/group/group.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { UserService } from 'src/app/services/user/user.service';
import { setEquals } from 'src/app/util/array-helpers';
import { AuthUser, Project, User, UserGroup, UserRole } from 'src/models';
import { SelectCompany } from '../auth/auth.actions';
import { AuthState } from '../auth/auth.state';
import { AddGroupManager, AddGroupMember, AddManagedUser, EditUserProperties, LoadManagedUsers, LoadProjects, LoadTimezones, LoadUserGroups, ManagedUserSettingsChanged, ManagedUserStatusChanged, RemoveGroupManager, RemoveGroupMember, ResetManagedUsers } from './manager.actions';

enum GetUsersFlags {
  All = 0,
  Loaded = 2 ** 0,
  Guest = 2 ** 1,
  NotGuest = 2 ** 2,
  Interactive = 2 ** 3,
  Silent = 2 ** 4,
  OnReports = 2 ** 5,
  WithScreencasts = 2 ** 6,
  NotSelf = 2 ** 7,
  NotOwner = 2 ** 8,
}

export interface GetUsersChainable {
  (st: ManagerStateModel): User[];
  loaded: GetUsersChainable;
  guest: GetUsersChainable;
  notGuest: GetUsersChainable;
  /** @deprecated For performance issues, this property is deprecated for now */
  interactive: GetUsersChainable;
  /** @deprecated For performance issues, this property is deprecated for now */
  silent: GetUsersChainable;
  onReports: GetUsersChainable;
  withScreencasts: GetUsersChainable;
  notSelf: GetUsersChainable;
  notOwner: GetUsersChainable;
}


/** A selector to select users with filter
 *  @param flags Can be a combination of filters like `GetUsersFlags.Interactive | GetUsersFlags.OnReports`. Defaults to All.
 */
function getUsers(flags?: GetUsersFlags) {
  /* eslint-disable no-bitwise */
  return createSelector([ManagerState], (st: ManagerStateModel) => {
    let users = st.users;
    if (!flags) { return users; }
    if (flags & GetUsersFlags.Loaded) { users = st.usersLoaded && users || null; }
    if (!users) { return users; }

    if (flags & GetUsersFlags.Guest) { users = users.filter(x => x.role === 'guest'); }
    if (flags & GetUsersFlags.NotGuest) { users = users.filter(x => x.role !== 'guest'); }
    if (flags & GetUsersFlags.Interactive) { users = users.filter(x => !ManagerState.isUserSilent(x)); }
    if (flags & GetUsersFlags.Silent) { users = users.filter(x => ManagerState.isUserSilent(x)); }
    if (flags & GetUsersFlags.OnReports) { users = users.filter(x => ManagerState.isUserOnReports(x)); }
    if (flags & GetUsersFlags.WithScreencasts) { users = users.filter(x => ManagerState.isScreencastsEnabled(x)); }
    if (flags & GetUsersFlags.NotSelf) { users = users.filter(x => x.id !== st.self?.id); }
    if (flags & GetUsersFlags.NotOwner) { users = users.filter(x => x.role !== 'owner'); }
    return users;
  });
  /* eslint-enable no-bitwise */
}

function createGetUsersSelector(flags?: GetUsersFlags) {
  flags = flags || GetUsersFlags.All;
  const selector = getUsers(flags);

  const obj = {
    /* eslint-disable no-bitwise */
    get loaded() { return createGetUsersSelector(flags | GetUsersFlags.Loaded); },
    get guest() { return createGetUsersSelector(flags | GetUsersFlags.Guest); },
    get notGuest() { return createGetUsersSelector(flags | GetUsersFlags.NotGuest); },
    get interactive() { return createGetUsersSelector(flags | GetUsersFlags.Interactive); },
    get silent() { return createGetUsersSelector(flags | GetUsersFlags.Silent); },
    get onReports() { return createGetUsersSelector(flags | GetUsersFlags.OnReports); },
    get withScreencasts() { return createGetUsersSelector(flags | GetUsersFlags.WithScreencasts); },
    get notSelf() { return createGetUsersSelector(flags | GetUsersFlags.NotSelf); },
    get notOwner() { return createGetUsersSelector(flags | GetUsersFlags.NotOwner); },
    run(users: User[], self?: User) { return selector({ users, usersLoaded: true, self }); },
    /* eslint-enable no-bitwise */
  };

  const keys = Object.keys(obj);

  for (const key of keys) {
    Object.defineProperty(selector, key, { get: () => obj[key] });
  }

  return selector;
}


export interface ManagerStateModel {
  users?: User[];
  usersLoaded?: boolean;
  self?: User;
  groups?: UserGroup[];
  projects?: Project[];
  timezones?: string[];
}

@State<ManagerStateModel>({
  name: 'manager',
  defaults: {
    users: [],
    groups: [],
  },
})
@Injectable()
export class ManagerState implements NgxsOnInit {
  /** A selector to select users with filter
   *  @param flags Can be a combination of filters like `ManagerState.users.interactive.onReport.notSelf`
   */
  static get users(): GetUsersChainable { return createGetUsersSelector() as any; }

  static isUserOnReports(user: User) {
    return user && user.role !== 'guest' && user.showOnReports;
  }

  static isUserSilent(user: User) {
    return user.role !== 'guest' && user.trackingMode === 'silent' && user.silentInfo && user.silentInfo[0];
  }

  static isScreencastsEnabled(user: User) {
    return user && (user.screenshots > 0 || user.videos !== 'off');
  }

  @Selector()
  static usersLoaded(st: ManagerStateModel) {
    return st.usersLoaded;
  }

  @Selector()
  static self(st: ManagerStateModel) {
    return st.self;
  }

  @Selector()
  static groups(st: ManagerStateModel) {
    return st.groups;
  }

  @Selector([AuthState.user, AuthState.role])
  static managedGroups(st: ManagerStateModel, user: AuthUser, role: UserRole) {
    return role === 'manager' ?
      st.groups?.filter(x => x.managers.includes(user.id)) :
      st.groups;
  }

  @Selector()
  static projects(st: ManagerStateModel) {
    return st.projects;
  }

  @Selector([AuthState.companyTimezone])
  static timezones(st: ManagerStateModel, companyTimezone: string) {
    const tzSet = new Set(st.timezones || []);
    tzSet.add(companyTimezone);

    return Array.from(tzSet).filter(x => x);
  }

  @Selector()
  static nonIntegrationProjects(st: ManagerStateModel) {
    return st.projects && st.projects.filter(x => !(x.integration && x.integration.provider));
  }

  constructor(
    private store: Store,
    private userService: UserService,
    private companyService: CompanyService,
    private groupService: GroupService,
    private projectService: ProjectService,
    private socket: SocketService,
  ) { }

  ngxsOnInit(ctx: StateContext<any>) {
    this.store.select(AuthState.combinedId).pipe(distinctUntilChanged()).subscribe(async (authorized) => {
      await lastValueFrom(ctx.dispatch(new ResetManagedUsers()));
      if (authorized) {
        ctx.dispatch(new LoadUserGroups());
        ctx.dispatch(new LoadTimezones());
      }
    });

    this.socket.resource('users/managed', 'post').subscribe(async (data) => {
      ctx.dispatch(new AddManagedUser(data.payload.userId));
    });

    combineLatest([
      this.store.select(ManagerState.users).pipe(map(x => x?.map(u => u.id)), distinctUntilChanged(setEquals)),
      this.store.select(AuthState.combinedId).pipe(distinctUntilChanged()),
    ]).subscribe(([users, combinedId]) => {
      if (combinedId && users && users.length > 1 && users.length < defualtUserApiLimit) {
        const company = this.store.selectSnapshot(AuthState.company);
        this.socket.onceConnected(combinedId).subscribe(() => {
          this.socket.subscribe(users, company.id);
        });
      }
    });
  }

  @Action(SelectCompany)
  @Action(ResetManagedUsers)
  resetManagedUsers(ctx: StateContext<ManagerStateModel>) {
    ctx.patchState({ users: null, groups: null, projects: null, usersLoaded: null, timezones: null });
  }

  @Action(LoadManagedUsers)
  loadManagedUsers(ctx: StateContext<ManagerStateModel>, { skipIfAlreadyLoaded }: LoadManagedUsers) {
    if (ctx.getState().usersLoaded != null && skipIfAlreadyLoaded) { return; }

    const company = this.store.selectSnapshot(AuthState.company);

    ctx.patchState({ usersLoaded: false });
    const getUser = this.userService.get('me', false, { 'task-project-names': true });
    if (company.role === 'user') {
      return getUser.pipe(tap({
        next: self => ctx.patchState({ users: [self], self }),
        complete: () => ctx.patchState({ usersLoaded: true }),
      }));
    } else {
      const pager = this.userService.users({ self: 'include', detail: 'settings' });
      return combineLatest([getUser, pager.all().accumulated])
        .pipe(tap({
          next: ([self, users]) => ctx.patchState({ users, self }),
          complete: () => ctx.patchState({ usersLoaded: true }),
        }));
    }
  }

  @Action(LoadTimezones, { cancelUncompleted: true })
  async loadTimezones(ctx: StateContext<ManagerStateModel>, { skipIfAlreadyLoaded }: LoadTimezones) {
    if (ctx.getState().timezones != null && skipIfAlreadyLoaded) { return; }

    const company = this.store.selectSnapshot(AuthState.company);

    const timezones = await this.companyService.getTimezones(company.id);
    ctx.patchState({ timezones });
  }

  @Action(LoadUserGroups, { cancelUncompleted: true })
  loadUserGroups(ctx: StateContext<ManagerStateModel>) {
    const company = this.store.selectSnapshot(AuthState.company);

    if (company.role === 'user') {
      ctx.patchState({ groups: [] });
    } else {
      const pager = this.groupService.groups({});
      return pager.all().accumulated.pipe(tap(groups => {
        ctx.patchState({ groups: groups.sort((a, b) => (a.name || '').localeCompare(b.name)) });
      }));
    }
  }

  @Action(LoadProjects, { cancelUncompleted: true })
  loadProjects(ctx: StateContext<ManagerStateModel>, { skipIfAlreadyLoaded }: LoadProjects) {
    if (skipIfAlreadyLoaded && ctx.getState().projects) {
      return;
    }

    const pager = this.projectService.getProjects({ 'show-integration': true, user: 'all-self' });
    return pager.all().accumulated.pipe(tap(projects => {
      ctx.patchState({ projects: projects.sort(sortBy('name')) });
    }));
  }

  @Action(AddManagedUser)
  async addManagedUser(ctx: StateContext<ManagerStateModel>, payload: AddManagedUser) {
    const user = await lastValueFrom(this.userService.get(payload.userId));
    const users = ctx.getState()?.users || [];
    if (!users.find((u) => u.id === user.id)) {
      ctx.setState(patch<ManagerStateModel>({
        users: append([user]),
      }));
    }
  }


  @Action(ManagedUserStatusChanged)
  statusChanged(ctx: StateContext<ManagerStateModel>, payload: ManagedUserStatusChanged) {
    ctx.setState(patch<ManagerStateModel>({
      users: updateItem(x => x.id === payload.data.id, user => ({
        ...user,
        lastSeen: {
          ...user.lastSeen,
          ip: payload.data.remoteIp,
          online: payload.data.mode !== 'offline',
          updatedAt: new Date().toUTCString(),
        },
        lastTrack: {
          ...user.lastTrack,
          ip: payload.data.remoteIp,
          activeAt: new Date().toUTCString(),
          projectId: payload.data.projectId,
          taskId: payload.data.taskId,
          taskName: payload.data.taskName,
          projectName: payload.data.projectName,
          status: payload.data.mode,
          online: payload.data.mode !== 'offline',
          updatedAt: new Date().toUTCString(),
        },
      })),
    }));

  }

  @Action(AddGroupManager)
  async addGroupManager(ctx: StateContext<ManagerStateModel>, { groupId, userId }: AddGroupManager) {
    ctx.setState(patch<ManagerStateModel>({
      groups: updateItem(group => group.id === groupId, group => ({ ...group, managers: [...group.managers, userId] })),
    }));
    await this.groupService.addGroupManager(groupId, userId);
  }

  @Action(RemoveGroupManager)
  async removeGroupManager(ctx: StateContext<ManagerStateModel>, { groupId, userId }: RemoveGroupManager) {
    ctx.setState(patch<ManagerStateModel>({
      groups: updateItem(group => group.id === groupId, group => ({ ...group, managers: group.managers.filter(x => x !== userId) })),
    }));
    await this.groupService.removeGroupManager(groupId, userId);
  }


  @Action(AddGroupMember)
  addGroupMember(ctx: StateContext<ManagerStateModel>, { groupId, userId }: AddGroupMember) {
    ctx.setState(patch<ManagerStateModel>({
      users: updateItem(u => u.id === userId, u => ({ ...u, tagIds: [...u.tagIds, groupId] })),
      groups: updateItem(g => g.id === groupId, g => ({ ...g, users: g.users + 1 })),
    }));
    return this.groupService.addGroupMember(groupId, userId);
  }

  @Action(RemoveGroupMember)
  removeGroupMember(ctx: StateContext<ManagerStateModel>, { groupId, userId }: RemoveGroupMember) {
    ctx.setState(patch<ManagerStateModel>({
      users: updateItem(u => u.id === userId, u => ({ ...u, tagIds: u.tagIds.filter(x => x !== groupId) })),
      groups: updateItem(g => g.id === groupId, g => ({ ...g, users: g.users - 1 })),
    }));
    return this.groupService.removeGroupMember(groupId, userId);
  }

  @Action(ManagedUserSettingsChanged)
  managedUserSettingsChanged(ctx: StateContext<ManagerStateModel>, payload: ManagedUserSettingsChanged) {
    ctx.setState(patch<ManagerStateModel>({
      users: updateItem(x => x.id === payload.userId, x => ({
        ...x, ...payload.changes,
      })),
    }));
  }

  @Action(EditUserProperties)
  EditUserProperties(ctx: StateContext<ManagerStateModel>, { userId, properties }: EditUserProperties) {
    const browserExtensionEnabled = properties['custom.browserExtensionEnabled'];
    const custom = properties.custom || {};
    if ('custom.browserExtensionEnabled' in properties) { custom.browserExtensionEnabled = browserExtensionEnabled; }

    const operator: StateOperator<User> = user => ({
      ...user,
      ...properties,
      custom: { ...user.custom, ...custom },
    });

    ctx.setState(patch<ManagerStateModel>({
      users: iif(users => !!users, updateItem<User>(x => x.id === userId, operator)),
      self: iif(user => user?.id === userId, operator),
    }));

    return this.userService.editProperties(userId, properties);
  }
}
