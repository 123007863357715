import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { LOGIN_NEW_URL } from '@shared';
import { lastValueFrom } from 'rxjs';
import { InvalidateUser, TokenLogin } from 'src/app/store/auth/auth.actions';

export interface TokenLoginSpec {
  token: string;
  company: string;
  adminLogin?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TokenLoginGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(LOGIN_NEW_URL) private loginNewUrl,
  ) { }

  async canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const query = childRoute.queryParams as TokenLoginSpec;
    if (!query || !query.token) {
      return true;
    }

    const adminLogin = query.adminLogin && parseInt(query.adminLogin) === 1;

    try {
      await lastValueFrom(this.store.dispatch(new TokenLogin(query.token, query.company, adminLogin)));
    } catch (err) {
      if (err.error === 'invalidToken' || err.error === 'invalidCredentials' || err.error === 'expiredToken' || err.error === 'denied') {
        await lastValueFrom(this.store.dispatch(new InvalidateUser()));
        return this.router.parseUrl(this.loginNewUrl);
      }
    }

    const urlTree = this.router.parseUrl(state.url);
    const urlWithoutParams = urlTree.root.children?.primary?.segments?.map(it => it.path) || ['/'];

    return this.router.createUrlTree(urlWithoutParams, {
      queryParamsHandling: 'merge',
      fragment: childRoute.fragment,
      queryParams: {
        ...childRoute.queryParams,
        company: null,
        token: null,
        from2a: null,
      },
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }
}
