import { hasSameWithTz } from '@shared';
import { DateTime, Duration, DurationLike, LocaleOptions, ZoneOptions } from 'luxon';
import { TimeService } from 'src/app/services/time';

export type DateRangeType = 'day' | 'week' | 'month' | 'days';

/** Represents a date range selection.
 * The `end` date is exclusive in this class. [start, end)
 * Use `asApiParams` if you need to pass date to API.
 */
export class DateRange {
  static readonly dayFormat = DateTime.DATE_MED;
  static readonly monthFormat = 'LLL y';
  static readonly monthDayFormat = 'LLL d';
  static readonly fileStringFormat = 'yyyy-LL-dd';
  private static readonly serializeSeparator = '_';

  get lastDay() {
    return this.end.minus({ day: 1 });
  }

  get zone() {
    return this.start.zone;
  }

  get timezone() {
    return this.start.zone.name;
  }

  static today(): DateRange {
    return this.byType('day');
  }

  static thisWeek(): DateRange {
    return this.byType('week');
  }

  static thisMonth(): DateRange {
    return this.byType('month');
  }

  static range(): DateRange {
    return this.byType('days');
  }

  static byType(type: DateRangeType, date: DateTime = null): DateRange {
    if (!date) { date = DateTime.utc(); }
    const start = TimeService.startOf(date, type);
    const end = TimeService.endOfExclusive(date, type);
    return new DateRange(start, end, type, date);
  }

  static byDuration(start: DateTime, duration: Duration): DateRange {
    const end = start.plus(duration);

    const types = ['day', 'week', 'month'] as DateRangeType[];
    let detectedType = 'days' as DateRangeType;

    for (const type of types) {
      if (start.equals(TimeService.startOf(start, type))
        && end.equals(TimeService.startOf(end, type))
        && duration.as(type) === 1) {
        detectedType = type;
      }
    }

    return new DateRange(start, end, detectedType, start);
  }

  // Tests the complete equality of two PeriodSelection objects
  static equals(val1: DateRange, val2: DateRange) {
    return val1 === val2 || (
      val1 && val2
      && val1.start.equals(val2.start) && val1.end.equals(val2.end)
      && val1.timezone === val2.timezone && val1.type === val2.type
    );
  }

  // Tests the timestamp equality of two PeriodSelection object intervals
  static same(val1: DateRange, val2: DateRange, ignoreTimezone = false) {
    return val1 && val2 && +val1.start === +val2.start && +val1.end === +val2.end &&
      !!(ignoreTimezone || val1.timezone === val2.timezone);
  }

  static deserialize(val: string) {
    if (!val) { return null; }
    const [st, en] = val.split(DateRange.serializeSeparator);
    return new DateRange(DateTime.fromISO(st), DateTime.fromISO(en), 'days');
  }

  constructor(
    public readonly start: DateTime,
    public readonly end: DateTime,
    public readonly type: DateRangeType,
    public readonly originalSelection?: DateTime,
  ) {
    if (!this.originalSelection) {
      this.originalSelection = this.start;
    }

    if (end < start) {
      this.end = TimeService.endOfExclusive(start, type);
    }
  }

  autoFormat(includeYear = true, includeDayName = false) {
    const lastDay = this.lastDay;

    if (this.type === 'month') {
      // month type selected, example: Mar, 2020
      return this.start.toFormat(DateRange.monthFormat);
    }

    const duration = this.duration().as('days');
    if (this.type === 'day' || duration === 1 || this.start.plus({ day: 1 }).equals(this.end)) {
      const date = includeYear ? this.start.toLocaleString(DateRange.dayFormat) :
        this.start.toFormat(DateRange.monthDayFormat);
      return includeDayName ? `${this.start.toFormat('ccc')}, ${date}` : date;
    }

    // start and end have the same year
    if (hasSameWithTz(this.start, lastDay, 'year')) {
      // start and end have the same month
      if (hasSameWithTz(this.start, lastDay, 'month')) {
        // same day, example: Mar 18, 2020
        if (hasSameWithTz(this.start, lastDay, 'day')) {
          const date = includeYear ? this.start.toLocaleString(DateRange.dayFormat) :
            this.start.toFormat(DateRange.monthDayFormat);
          return includeDayName ? `${this.start.toFormat('ccc')}, ${date}` : date;
        } else {
          // same month and same year, example: Mar 16 - 22, 2020
          const date = `${this.start.toFormat(DateRange.monthDayFormat)} - ${lastDay.day}`;
          return includeYear ? `${date}, ${lastDay.year}` : date;
        }
      } else {
        // not the same month but the same year, example: Feb 24 - Mar 1, 2020
        const date = `${this.start.toFormat(DateRange.monthDayFormat)} - ${lastDay.toFormat(DateRange.monthDayFormat)}`;
        return includeYear ? `${date}, ${lastDay.year}` : date;
      }
    } else {
      // not the same year and default format, example: Dec 30, 2019 - Jan 5, 2020
      return `${this.start.toLocaleString(DateRange.dayFormat)} - ${lastDay.toLocaleString(DateRange.dayFormat)}`;
    }
  }

  zoneFormat() {
    const offsetFormat = this.start.zone.formatOffset(+this.start, 'short');
    const zoneName = this.timezone.split('/').reverse().join(', ').replace(/_/g, ' ');
    const display = `(UTC ${offsetFormat}) ${zoneName}`;
    return display;
  }

  toFormat(format: string, options?: LocaleOptions, fullFormat = '$1 - $2') {
    const startFormat = this.start.toFormat(format, options);
    const endFormat = this.lastDay.toFormat(format, options);
    return fullFormat.replace('$1', startFormat).replace('$2', endFormat);
  }

  fileString(separator = '_') {
    if (hasSameWithTz(this.start, this.lastDay, 'day')) {
      return this.start.toFormat(DateRange.fileStringFormat);
    } else {
      return `${this.start.toFormat(DateRange.fileStringFormat)}${separator}${this.lastDay.toFormat(DateRange.fileStringFormat)}`;
    }
  }

  prev() {
    const dur = { [this.type]: -1 };
    return new DateRange(this.start.plus(dur), this.end.plus(dur), this.type, this.originalSelection.plus(dur));
  }

  next() {
    const dur = { [this.type]: 1 };
    return new DateRange(this.start.plus(dur), this.end.plus(dur), this.type, this.originalSelection.plus(dur));
  }

  switchType(type: DateRangeType): DateRange {
    if (type === 'days') {
      return new DateRange(this.start, this.end, type, this.originalSelection);
    } else {
      return DateRange.byType(type, this.originalSelection);
    }
  }

  contains(d: DateTime) {
    return !!d && d >= this.start && d < this.end;
  }

  intersects(d: DateRange) {
    return !(d.start < this.start && d.end < this.start) && !(d.start > this.end && d.end > this.end);
  }

  setTimezone(tzName: string, options?: ZoneOptions) {
    const opts = { keepLocalTime: true, ...options };
    if (this.type === 'days') {
      const md = this.originalSelection.setZone(tzName, opts);
      return new DateRange(this.start.setZone(tzName, opts), this.end.setZone(tzName, opts), this.type, md);
    } else {
      const md = this.originalSelection.setZone(tzName, options);
      return new DateRange(TimeService.startOf(md, this.type), TimeService.endOfExclusive(md, this.type), this.type, md);
    }
  }

  clamp(minDate?: DateTime, maxDate?: DateTime): DateRange {
    if (!minDate && !maxDate) {
      return this;
    }

    minDate = minDate && TimeService.startOf(minDate.setZone(this.timezone), this.type);
    maxDate = maxDate && TimeService.endOfExclusive(maxDate.setZone(this.timezone), this.type);

    if (!this.contains(minDate) && !this.contains(maxDate)) {
      return this;
    }

    let newStart = this.start;
    let newEnd = this.end;
    let origin = this.originalSelection;

    if (maxDate) {
      newStart = DateTime.min(newStart, maxDate);
      newEnd = DateTime.min(newEnd, maxDate);
      origin = DateTime.min(origin, maxDate);
    }

    if (minDate) {
      newStart = DateTime.max(newStart, minDate);
      newEnd = DateTime.max(newEnd, minDate);
      origin = DateTime.max(origin, minDate);
    }

    return new DateRange(newStart, newEnd, this.type, origin);
  }

  duration() {
    return this.end.diff(this.start);
  }

  plus(duration: DurationLike): DateRange {
    const newStart = this.start.plus(duration);
    const newEnd = this.end.plus(duration);
    const origin = this.originalSelection.plus(duration);

    return new DateRange(newStart, newEnd, this.type, origin);
  }

  asApiParams(withTimezone?: false): { from: string, to: string };
  asApiParams(withTimezone: true): { from: string, to: string, timezone: string };
  asApiParams(withTimezone?: boolean) {
    return {
      from: this.start.toUTC().toISO(),
      to: this.end.toUTC().toISO(),
      ...withTimezone && { timezone: this.timezone },
    };
  }

  divide(duration?: Duration, alignDays = true) {
    if (!duration) { duration = Duration.fromObject({ day: 1 }); }

    const start = alignDays ? this.start.startOf('day') : this.start;
    const end = alignDays ? this.end.startOf('day') : this.end;


    const res: DateRange[] = [];

    let current = start;
    while (current < end) {
      const next = current.plus(duration);

      res.push(new DateRange(current, next, 'days', current));

      current = next;
    }

    return res;
  }

  serialize() {
    return this.start.toUTC().toISO() + DateRange.serializeSeparator + this.end.toUTC().toISO();
  }
}
